<template>
  <page-content>
    <h1 class="page-header">{{ $t('batch-overview.page.header') }}</h1>
    <panel id="batch-overview" :title="$t('overview')">
      <div class="row p-b-15">
        <div class="col-md-12">
          <div class="row filter-button-group">
            <div class="col-md-11">
              <b-button
                :class="getFilterClass('selable-stock')"
                class="btn btn-default me-3 mb-1 btn-salable-filter"
                type="button"
                @click="filterButton('selable-stock')"
              >
                {{ $t('salable') }}
              </b-button>
              <b-button
                :class="getFilterClass('return-stock')"
                class="btn btn-default me-3 mb-1 btn-return-filter"
                type="button"
                @click="filterButton('return-stock')"
              >
                {{ $t('return') }}
              </b-button>
              <b-button
                :class="getFilterClass('defect-stock')"
                class="btn btn-default me-3 mb-1 btn-defect-filter"
                type="button"
                @click="filterButton('defect-stock')"
              >
                {{ $t('defect') }}
              </b-button>
              <b-button
                :class="getFilterClass('expired-stock')"
                class="btn btn-default me-3 mb-1 btn-expired-filter"
                type="button"
                @click="filterButton('expired-stock')"
              >
                {{ $t('expired') }}
              </b-button>
            </div>
            <div class="col-md-1">
              <b-button class="button reset-button" @click="resetFilters">
                {{ $t('reset') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </panel>
    <panel
      id="batch-panel"
      :backButtonVisible="false"
      :exportButtonVisible="true"
      :reloadButtonVisible=true
      :resizeable="true"
      :title="$t('batch')"
      @exportContent="exportXls"
      @reload="load(null)"
    >
      <batch-overview
        ref="batch_overview"
        :data="batch_overview"
        :isLoading="isLoading"
        @filter="filter"
        @reload="load"
        @sort="sort"
        @filter-button="filterButton"/>
    </panel>
  </page-content>
</template>

<script>
import { client } from '@/client/client'
import PageContent from '../../../layout/PageContent'
import BatchOverview from '../components/BatchOverview'
import { debounce } from 'lodash'
import { bus } from '@/main'

export default {
  components: {PageContent, BatchOverview},
  data() {
    const date_from = new Date()
    date_from.setDate(date_from.getDate() - 7)
    return {
      activeRequests: 0,
      REQUEST_DATE_FORMAT: 'YYYY-MM-DD',
      dateFrom: null,
      dateTo: null,
      configs: {
        start: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false
        },
        end: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false
        }
      },
      selectedFilter: {name: this.$t('last30Days'), filter: 'last_30'},
      predefinedFilters: [
        {name: this.$t('today'), filter: 'today'},
        {name: this.$t('yesterday'), filter: 'yesterday'},
        {name: this.$t('lastWeek'), filter: 'last_7'},
        {name: this.$t('last30Days'), filter: 'last_30'},
        {name: this.$t('last6Months'), filter: 'last_6m'},
        {name: this.$t('lastYear'), filter: 'last_year'},
        {name: this.$t('custom'), filter: 'custom'}
      ],
      batch_overview: {},
      isLoading: false,
      request: {
        per_page: 20,
        page: 1,
        date_from: date_from,
        date_to: new Date(),
        order_column: 'sku',
        order_dir: 'DESC',
        filters: {cpy: this.$store.getters.currentCpy},
        predefined: ''
      }
    }
  },
  mounted() {
    this.$store.watch((state, getters) => getters.userId, this.load)
    this.selectedFilter = {
      filter: this.$store.getters.userDefaultDateFilter,
      name: this.$store.getters.userDefaultDateFilterName
    }
    this.request.filters.cpy = this.$store.getters.currentCpy
    bus.$on('cpyChanged', (data) => {
      this.request.filters.cpy = data
      this.load()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
    const modal = this.$refs.modalDetail
    if (modal) {
      modal.$off('ok')
    }
  },
  watch: {
    activeRequests(newValue) {
      this.isLoading = newValue > 0
    }
  },
  methods: {
    async exportXls() {
      this.activeRequests++
      try {
        const response = await client.batch_overview().download_excel({...this.request, per_page: 50000})

        const url = URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url

        const contentDisposition = response.headers['content-disposition']
        link.download = contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '')

        link.click()
        URL.revokeObjectURL(url)

      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    getBatchOverview() {
      this.activeRequests++
      this.request.cpy = this.$store.getters.currentCpy
      client.batch_overview().all(this.request)
        .then(response => {
          this.batch_overview = response.data
        }).finally(() => {
        this.activeRequests--
      })
    },
    load(event) {
      if (event) {
        this.request.per_page = event.currentPerPage
        this.request.page = event.currentPage
      }
      this.applyDateFilter()
    },
    sort(event) {
      this.request.order_column = event[0].field
      if (event[0].type && event[0].type !== 'none') {
        this.request.order_dir = event[0].type
      } else {
        this.request.order_dir = 'desc'
      }
      this.applyDateFilter()
    },
    filter: debounce(function (event) {
      if (event && event.columnFilters) {
        this.request.filters = {...event.columnFilters}
      }
      this.applyDateFilter()
    }, 500),
    filterButton(type) {
      this.request.predefined = type
      this.applyDateFilter()
    },
    resetFilters() {
      this.dateFrom = null
      this.dateTo = null
      this.request.predefined = ''
      this.request.filters = {cpy: this.$store.getters.currentCpy}
      this.request.date_from = null
      this.request.date_to = null
      this.request.filters.cpy = ''
      this.selectedFilter.filter = this.$store.getters.userDefaultDateFilter
      this.selectedFilter.name = this.$store.getters.userDefaultDateFilterName
      this.request.order_column = 'sku'
      this.request.order_dir = 'DESC'
      this.applyDateFilter()
    },
    applyDateFilter() {
      switch (this.selectedFilter.filter) {
        case 'today':
          this.request.date_from = new Date()
          this.request.date_to = new Date()
          break
        case 'yesterday':
          var yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          this.request.date_from = yesterday
          this.request.date_to = yesterday
          break
        case 'last_7':
          var last_7 = new Date()
          last_7.setDate(last_7.getDate() - 7)
          this.request.date_from = last_7
          this.request.date_to = new Date()
          break
        case 'last_30':
          var last_30 = new Date()
          last_30.setDate(last_30.getDate() - 30)
          this.request.date_from = last_30
          this.request.date_to = new Date()
          break
        case 'last_6m':
          var last_6m = new Date()
          last_6m.setMonth(last_6m.getMonth() - 6)
          this.request.date_from = last_6m
          this.request.date_to = new Date()
          break
        case 'last_year':
          var last_year = new Date()
          last_year.setFullYear(last_year.getFullYear() - 1)
          this.request.date_from = last_year
          this.request.date_to = new Date()
          break
        case 'custom':
          this.request.date_from = this.dateFrom.format(this.REQUEST_DATE_FORMAT)
          this.request.date_to = this.dateTo.format(this.REQUEST_DATE_FORMAT)
          break
      }
      this.getBatchOverview()
    },
    onStartChange(e) {
      this.dateFrom = e.date
    },
    onEndChange(e) {
      this.dateTo = e.date
    },
    getFilterClass(type) {
      return this.request.predefined === type ? 'selected-filter-button' : ''
    }
  }
}
</script>

<style scoped>
.reset-button {
  border-radius: 12px;
  background-color: #696cff !important;
  color: white !important;
}

.reset-button:hover {
  background-color: white !important;
  color: #696cff !important;
}

.filter-button-group {
  margin-top: -5px;
  padding-bottom: 10px;
}

.btn-salable-filter {
  background-color: rgb(199, 240, 210);
  color: rgb(0, 0, 0);
}

.btn-return-filter {
  background-color: rgb(228, 240, 195);
  color: rgb(0, 0, 0);
}

.btn-defect-filter {
  background-color: rgb(245, 223, 189);
  color: rgb(0, 0, 0);
}

.btn-expired-filter {
  background-color: rgb(245, 223, 189);
  color: rgb(0, 0, 0);
}

.selected-filter-button {
  opacity: 1;
  border: 3px solid !important;
  font-weight: 1000;
}
</style>
