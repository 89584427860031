import axios from "axios";
import {dashboardClientConfig} from "../config";
import { requestManager } from '@/assets/requestManager.js';

let apiRoot = dashboardClientConfig.apiRoot;
let rootUrl = apiRoot + "/backorders";

export function backorders() {
    return {
        all: (obj) => axios.post(rootUrl, obj),
        comment_get: (obj) => axios.post(rootUrl+ "/comment/get", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        comment_set: (obj) => axios.post(rootUrl+ "/comment/set", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          })
        
    }
}
