<template>
  <div>
    <div class="d-flex justify-content-between align-items-center px-4">
      <h1 class="page-header">
        {{ $t('orderNumber', {order_id: order_id}) }} event log
      </h1>
    </div>

    <div v-if="isLoading" class="vgt-loading vgt-center-align position-static mb-5">
      <slot name="loadingContent">
        <span class="vgt-loading__content">Loading...</span>
      </slot>
    </div>

    <div v-else class="container">
      <div class="row">
        <div class="row" style="padding-left: 34px;">
          <b-list-group>
            <b-list-group-item class="text-secondary">
              Last order event details:
            </b-list-group-item>
            <b-list-group-item>
              <b>{{ $t('id') }}</b>: {{ lastOrderEvent?.orderId }}
            </b-list-group-item>
            <b-list-group-item>
              <b>{{ $t('orders.delivery-detail.orderStatus') }}</b>: {{ lastOrderEvent?.id }}
            </b-list-group-item>
            <b-list-group-item>
              <b>Date</b>: {{ moment(lastOrderEvent?.date).format('YYYY-MM-DD HH:MM') }}
            </b-list-group-item>
          </b-list-group>
        </div>

        <div class="row mt-4" style="padding-left: 30px;">
          <div class="text-dark d-flex justify-content-center mx-2 my-2"><b>Main status</b></div>
          <vue-good-table
            ref="generalOrderEvents"
            :columns="generalColumns"
            :globalSearch="true"
            :isLoading="isLoading"
            :lineNumbers="false"
            :mode="'local'"
            :responsive="true"
            :rows="generalOrderEvents"
            :search-options="{ enabled: false }"
            :selectOptions="tableSelectOptions"
            :sort-options="{ enabled: false }">
          </vue-good-table>
        </div>

        <div class="row my-4" style="padding-left: 30px;">
          <div class="text-dark d-flex justify-content-center mx-2 my-2"><b>Trace log</b></div>
          <vue-good-table
            ref="traceOrderEvents"
            :columns="traceColumns"
            :globalSearch="true"
            :isLoading="isLoading"
            :lineNumbers="false"
            :mode="'local'"
            :responsive="true"
            :rows="traceOrderEvents"
            :search-options="{ enabled: false }"
            :selectOptions="tableSelectOptions"
            :sort-options="{ enabled: false }">
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { client } from '@/client/client'
import moment from 'moment'

export default {
  props: ['order_id', 'cpy', 'delivery_id'],
  data() {
    return {
      lastOrderEvent: null,
      traceOrderEvents: null,
      generalOrderEvents: null,
      generalColumns: [
        {
          label: 'ORDER ID',
          field: 'orderId',
          width: '30%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap'
        },
        {
          label: 'DATE',
          field: 'date',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'yyyy-MM-dd HH:mm',
          width: '40%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap'
        }
        ,
        {
          label: 'STATUS',
          field: 'id',
          width: '30%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap'
        },
      ],
      traceColumns: [
        {
          label: 'ORDER ID',
          field: 'orderId',
          width: '20%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap'
        },
        {
          label: 'DATE',
          field: 'date',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'yyyy-MM-dd HH:mm',
          width: '20%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap'
        }
        ,
        {
          label: 'STATUS',
          field: 'id',
          width: '20%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap'
        },
        {
          label: 'CREATED BY',
          field: 'createdBy',
          width: '20%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap'
        },
        {
          label: 'UPDATED BY',
          field: 'updatedBy',
          width: '20%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap'
        },
      ],
      tableSelectOptions: {
        enabled: false,
        selectOnCheckboxOnly: false,
        selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
      },
      isLoading: false,
      request: {
        cpy: '',
        filters: {
          order_id: '',
        },
      },
    }
  },
  async mounted() {
    await this.getOrderEvents()
  },
  computed: {
    moment() {
      return moment
    }
  },
  methods: {
    formatEventsDate(events) {
      return events.map((item) => {
        return {...item, date: this.moment(item.date).format('YYYY-MM-DD HH:mm:ss')}
      })
    },
    async getOrderEvents() {
      try {
        this.isLoading = true
        this.request.filters.order_id = this.$props.order_id
        this.request.cpy = this.$props.cpy

        const response = await client.order_events().all(this.request)

        this.lastOrderEvent = response.data.lastOrderEvent
        this.traceOrderEvents = this.formatEventsDate(response.data.traceOrderEvents)
        this.generalOrderEvents = this.formatEventsDate(response.data.generalOrderEvents)

        this.isLoading = false

      } catch (error) {
        console.error('Error:', error)
      }
    },
  }
}
</script>
