import axios from "axios";
import {dashboardClientConfig} from "../config";
import { requestManager } from '@/assets/requestManager.js';

let apiRoot = process.env.VUE_APP_SAGE_API_ROOT;
const oauthUsername = process.env.VUE_APP_SAGE_OAUTH_USERNAME;
const oauthPassword = process.env.VUE_APP_SAGE_OAUTH_PASSWORD;

let rootUrl = apiRoot;

const axiosInstance = new axios.create()

const getToken = async () => {
    const form = new FormData()
    form.append('username', oauthUsername)
    form.append('password', oauthPassword)
    form.append('grant_type', 'password')

    const response = await axiosInstance.post(rootUrl + "/oauth/token", form, {
        auth: {
            username: dashboardClientConfig.basicUsername,
            password: dashboardClientConfig.basicPassword,
        },
    })

	

    const { access_token } = response.data
    
    
    return `Bearer ${access_token}`
}

export function create_order() {
    return {
        items_get: async (obj) => axiosInstance.get(rootUrl+ "/dashboard/api/items/get", {
            params: obj,
            cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel });
            }),
            headers: {
                Authorization: await getToken()
            }
        }),
        sale_facility_get: async (obj) => axiosInstance.get(rootUrl+ "/dashboard/api/sale-facility/get", {
            params: obj,
            cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel });
            }),
            headers: {
                Authorization: await getToken()
            }
        }),
        stock_facility_get: async (obj) => axiosInstance.get(rootUrl+ "/dashboard/api/stock-facility/get", {
            params: obj,
            cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel });
            }),
            headers: {
                Authorization: await getToken()
            }
        }),
        shippers_get: async (obj) => axiosInstance.get(rootUrl+ "/dashboard/api/shippers/get", {
            params: obj,
            cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel });
            }),
            headers: {
                Authorization: await getToken()
            }
        }),
        create_order: async (obj) => axiosInstance.post(rootUrl+ "/dashboard/api/order/save", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel });
            }),
            headers: {
                Authorization: await getToken()
            }
        }),
        purchase_order: async (obj) => axiosInstance.post(rootUrl+ "/dashboard/api/purchase/save", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel });
            }),
            headers: {
                Authorization: await getToken()
            }
        }),
    }
}
