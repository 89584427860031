<template>
  <div>
    <div>
      <vue-good-table
        ref="serviceCarrierInfo"
        :columns="columns"
        :globalSearch="true"
        :isLoading="isLoading"
        :lineNumbers="false"
        :mode="'local'"
        :responsive="true"
        :rows="data"
        :search-options="{ enabled: false, placeholder: 'Search' }"
        :selectOptions="{
          enabled: false,
          selectOnCheckboxOnly: false,
          selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
        }"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'details'">
            <a
              class="fa fa-magnifying-glass"
              type="button"
              @click="doDetail(props.row)"
            ></a>
          </span>
          <span v-else> {{ props.formattedRow[props.column.field] }} </span>
        </template>
      </vue-good-table>
    </div>

    <div></div>
  </div>
</template>

<script>
export default {
  name: 'ServiceCarrier',
  props: ['data', 'isLoading'],
  methods: {
    doDetail(event) {
      this.$emit('openModal', event.country)
    },
  },
  data() {
    return {
      columns: [
        {
          label: '',
          field: 'details',
          filterable: false,
          sortOptions: {
            enabled: false,
          },
          width: '1%',
          //thClass: 'icon-magnifier'
        },
        {
          label: 'Country',
          field: 'country',
          width: '10%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
        },
        {
          label: 'Revenue NET',
          field: 'revenueNet',
          width: '15%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
        },
        {
          label: 'Revenue GROSS',
          field: 'revenueGross',
          width: '15%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        },
        {
          label: 'Tax revenue',
          field: 'taxRevenue',
          width: '15%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        },
        {
          label: 'Tax %',
          field: 'tax',
          width: '15%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        },
        {
          label: 'Currency',
          field: 'currency',
          width: '15%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        },
      ],
    }
  },
}
</script>

<style scoped>
.refunded {
  color: #18c471;
}
</style>
