import axios from "axios";
import {dashboardClientConfig} from "../config";
import { requestManager } from '@/assets/requestManager.js';

let apiRoot = dashboardClientConfig.apiRoot;
let rootUrl = apiRoot + "/reports-errors";

export function reports_errors() {
    //console.log('request reports errors');
    return {
        all: (obj) => axios.post(rootUrl, obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
    }
}

