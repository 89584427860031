<template>
  <page-content>
    <h1 class="page-header">{{ $t('service-quality.page.reportsServiceQuality') }}</h1>
    <panel id="service-quality-overview" :title="$t('service-quality.page.serviceQuality')">
      <div class="row p-b-15">
        <div class="col-md-7 p-b-15">
          <div class="row">
            <div class="col-md-3">
              <select
                v-model="selectedFilter"
                class="form-select"
              >
                <option
                  v-for="(filter, index) in predefinedFilters"
                  :key="filter.name + index"
                  :value="filter.filter"
                >
                  {{ filter.name }}
                </option>
              </select>
            </div>
            <div class="col-md-3 padding-left-0" style="position: relative;">
              <date-picker
                ref="dateFrom"
                v-model="dateFrom"
                :config="configs.start"
                :disabled="selectedFilter !== 'custom'"
                :placeholder="$t('createdFrom')"
                v-on:dp-change="onStartChange"/>
            </div>
            <div class="col-md-3 padding-left-0" style="position: relative;">
              <date-picker
                ref="dateTo" v-model="dateTo"
                :config="configs.end"
                :disabled="selectedFilter !== 'custom'"
                :placeholder="$t('createdTo')"
                v-on:dp-change="onEndChange"/>
            </div>
            <div class="col-md-3 padding-left-0">
              <b-button
                class="button btn-success margin-right-5"
                @click="handleFilterButtonClick"
              >
                {{ $t('filter') }}
              </b-button>
              <b-button
                class="button btn-warning"
                @click="resetFilters"
              >
                {{ $t('reset') }}
              </b-button>
            </div>
          </div>
        </div>
        <div v-if="dataLoaded && !isLoading" class="row">
          <div class="col-md-10">
            <apexchart
              :height="lineChart.options.chart.height"
              :options="lineChart.options"
              :series="lineChart.series"
              type="line"/>
          </div>
          <div class="col-md-2" style="padding-top: 0;padding-right:0">
            <div class="widget widget-stats bg-info" style="padding: 1.5rem">
              <div class="stats-content" style="text-align: center">
                <div class="stats-title">{{ $t('service-quality.page.averagePerOrder') }}</div>
                <div v-if="isLoading">
                  <b-spinner small/>
                </div>
                <div v-else>
                  <div class="stats-number">{{ service_quality.avg_dispatch_time_per_order }}</div>
                  <div class="stats-progress progress">
                    <div class="progress-bar" style="width: 100%;"></div>
                  </div>
                  <div class="stats-desc"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isLoading" class="vgt-loading vgt-center-align position-static">
          <slot name="loadingContent">
            <span class="vgt-loading__content">Loading...</span>
          </slot>
        </div>
      </div>
    </panel>
    <notifications group="msg" position="bottom right"/>
  </page-content>
</template>

<script>
import { client } from '@/client/client'
import PageContent from '../../../layout/PageContent'
import AppOptions from '../../../config/AppOptions.vue'
import { bus } from '@/main'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {
  components: {PageContent},
  data() {
    return {
      activeRequests: 0,
      lineChart: {
        options: {
          chart: {
            type: 'line',
            height: 550,
            shadow: {
              enabled: true,
              color: 'rgba(' + AppOptions.color.componentColorRgb + ', .5)',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 1
            },
            toolbar: {
              show: true
            }
          },
          title: {
            text: this.$t('service-quality.page.averageOrderFulfillment'),
            align: 'center',
            style: {
              color: AppOptions.color.componentColor
            }
          },
          colors: [AppOptions.color.blue],
          dataLabels: {
            enabled: true,
            background: {
              borderWidth: 0
            }
          },
          stroke: {
            curve: 'smooth',
            width: 3
          },
          grid: {
            row: {colors: ['rgba(' + AppOptions.color.componentColorRgb + ', .05)', 'transparent'], opacity: 0.5}
          },
          xaxis: {
            categories: [],
          },
          legend: {
            show: true,
            position: 'top',
            offsetY: -10,
            horizontalAlign: 'right',
            floating: true,
            labels: {
              colors: AppOptions.color.componentColor
            }
          }
        },
        series: []
      },
      dataLoaded: false,
      REQUEST_DATE_FORMAT: 'YYYY-MM-DD',
      dateFrom: null,
      dateTo: null,
      configs: {
        start: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false
        },
        end: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false
        }
      },
      selectedFilter: '',
      predefinedFilters: [
        {name: this.$t('today'), filter: 'today'},
        {name: this.$t('yesterday'), filter: 'yesterday'},
        {name: this.$t('lastWeek'), filter: 'last_7'},
        {name: this.$t('last30Days'), filter: 'last_30'},
        {name: this.$t('last6Months'), filter: 'last_6m'},
        {name: this.$t('lastYear'), filter: 'last_year'},
        {name: this.$t('custom'), filter: 'custom'}
      ],
      service_quality: {},
      isLoading: false,
      request: {
        cpy: this.$store.getters.currentCpy,
        per_page: 20,
        page: 1,
        date_from: null,
        date_to: null,
        order_column: 'order_created_at',
        order_dir: 'DESC',
        filters: {
          cpy: this.$store.getters.currentCpy,
        },
        predefined: ''
      }
    }
  },
  async mounted() {
    await this.getDefaultData()
    bus.$on('cpyChanged', (data) => {
      this.request.cpy = data
      this.load()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
  },
  watch: {
    activeRequests(newValue) {
      this.isLoading = newValue > 0
    },
    selectedFilter(newValue) {
      if (newValue !== 'custom') {
        this.applyDateFilter()
      }
    }
  },
  methods: {
    async getDefaultData() {
      this.activeRequests++
      try {
        const response = await client.auth().me()
        this.selectedFilter = response.data.defaultDateFilter

      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    async getServiceQuality() {
      this.activeRequests++
      try {
        const response = await client.service_quality().all(this.request)
        this.service_quality = response.data
        let categories = []
        let seriesData = []
        this.lineChart.series = []
        for (let quality of this.service_quality.data) {
          categories.push(quality.order_created_at)
          seriesData.push(quality.avg_dispatched)
        }
        this.lineChart.options.xaxis.categories = categories
        this.lineChart.series = []
        this.lineChart.series.push({name: 'Avg order fulfillment time in hours', data: seriesData})
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
        this.dataLoaded = true
      }
    },
    load(event) {
      if (event) {
        this.request.per_page = event.currentPerPage
        this.request.page = event.currentPage
      }
      this.getServiceQuality()
    },
    resetFilters() {
      this.dateFrom = null
      this.dateTo = null
      this.request.predefined = ''
      this.request.date_from = null
      this.request.date_to = null
      this.request.filters.cpy = ''
      this.request.order_column = 'order_created_at'
      this.request.order_dir = 'DESC'
      this.request.filters = {cpy: this.$store.getters.currentCpy}
      this.selectedFilter = this.$store.getters.userDefaultDateFilter
    },
    applyDateFilter() {
      switch (this.selectedFilter) {
        case 'today':
          this.request.date_from = new Date()
          this.request.date_to = new Date()
          break
        case 'yesterday':
          var yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          this.request.date_from = yesterday
          this.request.date_to = yesterday
          break
        case 'last_7':
          var last_7 = new Date()
          last_7.setDate(last_7.getDate() - 7)
          this.request.date_from = last_7
          this.request.date_to = new Date()
          break
        case 'last_30':
          var last_30 = new Date()
          last_30.setDate(last_30.getDate() - 30)
          this.request.date_from = last_30
          this.request.date_to = new Date()
          break
        case 'last_6m':
          var last_6m = new Date()
          last_6m.setMonth(last_6m.getMonth() - 6)
          this.request.date_from = last_6m
          this.request.date_to = new Date()
          break
        case 'last_year':
          var last_year = new Date()
          last_year.setFullYear(last_year.getFullYear() - 1)
          this.request.date_from = last_year
          this.request.date_to = new Date()
          break
        case 'custom':
          this.request.date_from = this.dateFrom.format(this.REQUEST_DATE_FORMAT)
          this.request.date_to = this.dateTo.format(this.REQUEST_DATE_FORMAT)
          break
      }
      this.getServiceQuality()
    },
    handleFilterButtonClick() {
      if (this.selectedFilter === 'custom' && (!this.dateFrom || !this.dateTo)) {
        this.$notify({
          group: 'msg',
          type: 'error',
          text: 'Created From and Created To fields must be filled to apply custom date filter',
          duration: 5000
        })
      } else {
        this.applyDateFilter()
      }
    },
    onStartChange(e) {
      this.dateFrom = e.date
    },
    onEndChange(e) {
      this.dateTo = e.date
    }
  }
}
</script>
