<template>
  <div>
    <vue-good-table
      ref="packagingMaterials"
      :columns="columns"
      :isLoading="isLoading"
      :lineNumbers="false"
      :mode="'remote'"
      :paginationOptions="tablePaginationOptions"
      :responsive="true"
      :rows="data.data"
      :search-options="{ enabled: false, placeholder: 'Search' }"
      :selectOptions="tableSelectOptions"
      :sort-options="tableSortOptions"
      :total-rows="data.total"
      @on-page-change="load"
      @on-per-page-change="load"
      @on-column-filter="filterChanged"
      @on-sort-change="onSortChange"
    >
    </vue-good-table>
  </div>
</template>

<script>

export default {
  name: 'ReportsPackaging',
  props: ['data', 'isLoading'],
  data() {
    return {
      columns: [
        {
          label: 'SKU',
          field: 'sku',
          width: '5%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SKU'
          }
        },
        {
          label: 'Delivery Created At',
          field: 'delivery_created_at',
          width: '5%',
          type: 'date',
          dateInputFormat: 'yyyyy-MM-dd HH:mm:ss.S',
          dateOutputFormat: 'yyyy-MM-dd',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'Delivery Created At'
          }
        },
        {
          label: 'Delivery ID',
          field: 'delivery_id',
          width: '10%',
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'Delivery ID'
          }
        },
        {
          label: 'Pck. Desc.',
          field: 'pck_desc',
          width: '20%',
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'Pck. Desc.'
          }
        },
        {
          label: 'Pck. Length',
          field: 'pck_length',
          width: '5%',
          formatFn: this.formatFloat,
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'Pck. Length'
          }
        },
        {
          label: 'Pck. Price',
          field: 'pck_price',
          width: '5%',
          formatFn: this.formatFloat,
          tdClass: 'text-left text-wrap',
          thClass: 'text-left text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'Pck. Price'
          }
        },
        {
          label: 'Pck. Size Type',
          field: 'pck_sizetype',
          width: '5%',
          tdClass: 'text-left text-wrap',
          thClass: 'text-left text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'Pck. Size Type'
          }
        },
        {
          label: 'Pck. Unit',
          field: 'pck_unit',
          width: '5%',
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'Pck. Unit'
          }
        },
        {
          label: 'Pck. Weight',
          field: 'pck_weight',
          width: '5%',
          formatFn: this.formatFloat,
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'Pck. Weight'
          }
        },
        {
          label: 'Pck. Width',
          field: 'pck_width',
          width: '5%',
          formatFn: this.formatFloat,
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'Pck. Width'
          }
        }
      ],
      tablePaginationOptions: {
        enabled: true,
        position: 'bottom',
        perPage: 20,
        perPageDropdown: null,
        perPageDropdownEnabled: true,
        dropdownAllowAll: false,
        mode: 'records',
        infoFn: null,
        jumpFirstOrLast: true
      },
      tableSelectOptions: {
        enabled: false,
        selectOnCheckboxOnly: false,
        selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
      },
      tableSortOptions: {
        enabled: true,
        initialSortBy: {field: 'delivery_created_at', type: 'desc'}
      }
    }
  },
  methods: {
    load(event) {
      this.$emit('reload', event)
    },
    onSortChange(event) {
      this.$emit('sort', event)
    },
    filterChanged(event) {
      this.$emit('filter', event)
    },
    filterButton(type) {
      this.$emit('filter-button', type)
    },
    reset() {
      this.$refs.skuRevenueData.reset()
    },
    formatFloat(value) {
      return parseFloat(value).toFixed(2)
    }
  },
}
</script>

