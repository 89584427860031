import axios from "axios";
import {dashboardClientConfig} from "../config";
import { requestManager } from '@/assets/requestManager.js';

let apiRoot = dashboardClientConfig.apiRoot;
let rootUrl = apiRoot + "/stock-movements";

export function stock_movements() {
    return {
        all: (obj) => axios.post(rootUrl, obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        download_excel: (obj) => axios.post(rootUrl + "/exportToExcel", obj, { responseType: 'blob'}, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel });
            })
        })
    }
}
