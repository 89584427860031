import axios from "axios";
import {dashboardClientConfig} from "../config";
import { requestManager } from '@/assets/requestManager.js';

let apiRoot = process.env.VUE_APP_SAGE_API_ROOT;
const oauthUsername = process.env.VUE_APP_SAGE_OAUTH_USERNAME;
const oauthPassword = process.env.VUE_APP_SAGE_OAUTH_PASSWORD;

let rootUrl = apiRoot;
const axiosInstance = new axios.create()
const getToken = async () => {
    const form = new FormData()
    form.append('username', oauthUsername)
    form.append('password', oauthPassword)
    form.append('grant_type', 'password')

    const response = await axiosInstance.post(rootUrl +"/oauth/token", form, {
        auth: {
          username: dashboardClientConfig.basicUsername,
          password: dashboardClientConfig.basicPassword,
        },
    })

    const { access_token } = response.data
    return `Bearer ${access_token}`
}

export function change_address() {
    return {
        address_get: async (obj) => axiosInstance.get(rootUrl+ "/dashboard/api/address/get", {
            params: obj,
            cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel });
            }),
            headers: {
                Authorization: await getToken()
            }
        }),
        order_get: async (obj) => axiosInstance.get(rootUrl+ "/dashboard/api/order/get", {
            params: obj,
            cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel });
            }),
            headers: {
                Authorization: await getToken()
            }
        }),
        order_cancel: async (obj) => axiosInstance.post(rootUrl+ "/dashboard/api/order/cancel", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel });
            }),
            headers: {
                Authorization: await getToken()
            }
        }),
        address_change: async (obj) => axiosInstance.post(rootUrl+ "/dashboard/api/address/save", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel });
            }),
            headers: {
                Authorization: await getToken()
            }
        }),
    }
}
