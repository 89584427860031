import axios from 'axios'
import { dashboardClientConfig } from '../config'

let apiRoot = dashboardClientConfig.apiRoot

export function profile() {
  const rootUrl = apiRoot + '/profile'
  return {
    change_password: (oldPassword, newPassword) => axios.post(rootUrl + '/changePassword', {oldPassword, newPassword}),
    change_language: (data) => axios.post(rootUrl + '/changeLanguage', data),
    change_preferred_view: (data) => axios.post(rootUrl + '/changePreferredView', data),
    change_default_date_filter: (data) => axios.post(rootUrl + '/changeDefaultDateFilter', data),
    change_default_mandant: (data) => axios.post(rootUrl + '/changeDefaultMandant', data),
  }
}
