<template>
  <div>
    <b-form-textarea
      id="textarea"
      v-model="skuRevenueData.text"
      :disabled="isLoading"
      :placeholder="$t('commentOnOrder')"
      invalidFeedback="Please enter a message"
      max-rows="6"
      required
      rows="3"
    />
  </div>
</template>

<script>
import { client } from '@/client/client'
import { bus } from '@/main'

export default {
  name: 'order-comment',
  props: ['gridData', 'selectedRow'],
  data() {
    const date_from = new Date()
    date_from.setDate(date_from.getDate() - 7)
    return {
      skuRevenueData: {},
      isLoading: false,
      request: {
        per_page: 20,
        page: 1,
        date_from: date_from,
        date_to: new Date(),
        order_column: 'entrytime',
        order_dir: 'DESC',
        filters: {cpy: this.$store.getters.currentCpy},
        cpy: this.$store.getters.currentCpy,
        predefined: ''
      }
    }
  },
  created() {
    bus.$on('saveCommentEvent', async () => {
      try {
        this.isLoading = true

        if (this.skuRevenueData.text == null) {
          this.skuRevenueData.text = ''
        }

        this.request.cpy = this.selectedRow.cpy
        this.request.refId = this.selectedRow.cpy + '_' + this.selectedRow.order_id
        this.request.viewname = 'order_delivery'
        this.request.text = this.skuRevenueData.text

        const response = await client.orders().comment_set(this.request)

        this.skuRevenueData = response.data
        bus.$emit('onSelectedRowChange', this.skuRevenueData.text)
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.isLoading = false
      }
    })
  },
  mounted() {
    this.load()
    this.$store.watch((state, getters) => getters.userId, this.load)
    this.request.filters.cpy = this.$store.getters.currentCpy
  },
  beforeDestroy() {
    bus.$off('saveCommentEvent')
  },
  methods: {
    async getErrorReport() {
      try {
        this.isLoading = true

        this.request.cpy = this.selectedRow.cpy
        this.request.refId = this.selectedRow.cpy + '_' + this.selectedRow.order_id
        this.request.viewname = 'order_delivery'

        const response = await client.orders().comment_get(this.request)
        this.skuRevenueData = response.data
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.isLoading = false
      }
    },
    load(event) {
      if (event) {
        this.request.per_page = event.currentPerPage
        this.request.page = event.currentPage
      }
      this.getErrorReport()
    },
  }
}
</script>


<style scoped>
.refunded {
  color: #18c471;
}
</style>
