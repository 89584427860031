import axios from "axios";
import {dashboardClientConfig} from "../config";
import { requestManager } from '@/assets/requestManager.js';

let apiRoot = dashboardClientConfig.apiRoot;
let rootUrl = apiRoot + "/order-delivery-detail";

export function order_delivery_detail() {
    return {
        all: (obj) => axios.post(rootUrl, obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          })
    }
}

