<template>
  <div>
    <vue-good-table
      ref="returns"
      :columns="columns"
      :isLoading="isLoading"
      :lineNumbers="false"
      :mode="'remote'"
      :paginationOptions="{
          enabled: true,
          position: 'bottom',
          perPage: 20,
          perPageDropdown: null,
          perPageDropdownEnabled: true,
          dropdownAllowAll: false,
          mode: 'records',
          infoFn: null,
          jumpFirstOrLast : true
        }"
      :responsive="true"
      :rows="data.data"
      :search-options="{ enabled: false, placeholder: 'Search' }"
      :selectOptions="{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				}"
      :sort-options="{
          enabled: true,
          initialSortBy: {field: 'return_created_at', type: 'desc'}
        }"
      :total-rows="data.total"
      @on-page-change="load"
      @on-per-page-change="load"
      @on-column-filter="filterChanged"
      @on-sort-change="onSortChange"
    >
      <template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'refunds'">
<!--            <a href="javascript://" title="Already refunded" class="refunded"><i class="fa fa-check-circle"/></a>-->
            <input :id="props.row.return_id" v-model="props.row.refunds" :checked="isRefundsChecked(props.row.refunds)"
                   class="form-check-input" type="checkbox"
                   @change="updateRefunds(props.row, $event.target.checked)">
          </span>
        <span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { client } from '@/client/client'

export default {
  name: 'Returns',
  components: {},
  props: ['data', 'isLoading'],
  methods: {
    async updateRefunds(row, isChecked) {
      try {
        await client.returns().refunds({
          return_id: row.return_id,
          refunds: isChecked ? 1 : 0
        })
      } catch (error) {
        console.error('Error:', error)
      }
    },
    isRefundsChecked(refundsValue) {
      return refundsValue === 1
    },
    load: function (event) {
      this.$emit('reload', event)
    },
    onSortChange: function (event) {
      this.$emit('sort', event)
    },
    filterChanged: function (event) {
      this.$emit('filter', event)
    },
    filterButton(type) {
      this.$emit('filter-button', type)
    },
    reset() {
      this.$refs.returns.reset()
    }
  },
  data() {
    return {
      columns: [
        {
          label: 'REFUNDS',
          field: 'refunds',
          width: '1%',
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap',
          sortable: false
        },
        {
          label: 'ID',
          field: 'return_id',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ID'
          }
        },
        {
          label: 'ORDER ID',
          field: 'order_id',
          width: '15%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ORDER ID'
          }
        },
        {
          label: 'SKU',
          field: 'sku',
          width: '10%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SKU'
          }
        },
        {
          label: 'SKU NAME',
          field: 'item_description',
          width: '10%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SKU NAME'
          }
        }, {
          label: 'CUSTOMER NAME',
          field: 'customer_name',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'CUSTOMER NAME'
          }
        },
        {
          label: 'EMAIL',
          field: 'email',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'EMAIL'
          }
        },
        {
          label: 'RETURN INFO',
          field: 'return_reason_desc',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'RETURN INFO'
          }
        }, {
          label: 'RETURNED AT',
          field: 'return_created_at',
          type: 'date',
          dateInputFormat: 'yyyyy-MM-dd',
          dateOutputFormat: 'dd.MM.yyyy',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'RETURNED AT'
          }
        }, {
          label: 'RETURNED AMOUNT',
          field: 'price_per_item',
          width: '10%',
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap'
        },
        {
          label: 'RETURNED QTY',
          field: 'return_qty',
          width: '5%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: 'RETURNED QTY'
          }
        }, {
          label: 'CURRENCY',
          field: 'currency',
          width: '10%',
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'CURRENCY'
          }
        }
        // , {
        //   label: 'REFUNDED',
        //   field: 'refunded',
        //   width: '10%',
        //   tdClass: 'text-center valign-middle text-nowrap',
        //   thClass: 'text-center text-nowrap'
        // }
      ]
    }
  },
}
</script>

<style scoped>
.refunded {
  color: #18c471;
}
</style>
