<template>
  <page-content>
    <h1 class="page-header">{{ $t('service-carrier.page.reportsServiceCarrier') }}</h1>
    <panel id="service-carrier-overview" :title="$t('reports-revenue.page.serviceCarrier')">
      <div class="row p-b-15">
        <div class="col-md-12 p-b-15">
          <div class="row">
            <div class="col-md-2">
              <select
                v-model="selectedDataFilter"
                class="form-select" @change="changeDataFilter"
              >
                <option
                  v-for="filter in predefinedDataFilters"
                  :key="filter.name"
                  :value="filter"
                >
                  {{ filter.name }}
                </option>
              </select>
            </div>
            <div class="col-md-2">
              <select
                v-model="selectedFilter"
                class="form-select"
              >
                <option
                  v-for="(filter, index) in predefinedFilters"
                  :key="filter.name + index"
                  :value="filter.filter"
                >
                  {{ filter.name }}
                </option>
              </select>
            </div>
            <div class="col-md-2">
              <select v-model="selectedAggregation" class="form-select">
                <option :value="null">No aggregation</option>
                <option value="month">{{ $t('byMonth') }}</option>
                <option value="week">{{ $t('byWeek') }}</option>
              </select>
            </div>
            <div class="col-md-2 padding-left-0" style="position: relative;">
              <date-picker
                ref="dateFrom" v-model="dateFrom"
                :config="configs.start"
                :disabled="selectedFilter !== 'custom'"
                :placeholder="$t('createdFrom')"
                v-on:dp-change="onStartChange"/>
            </div>
            <div class="col-md-2 padding-left-0" style="position: relative;">
              <date-picker
                ref="dateTo" v-model="dateTo"
                :config="configs.end"
                :disabled="selectedFilter !== 'custom'"
                :placeholder="$t('createdTo')"
                v-on:dp-change="onEndChange"/>
            </div>
            <div class="col-md-2 padding-left-0" style="position: relative;">
              <b-button
                class="button btn-success margin-right-5"
                @click="handleFilterButtonClick"
              >
                {{ $t('filter') }}
              </b-button>
              <b-button
                class="button btn-warning"
                @click="resetFilters"
              >
                {{ $t('reset') }}
              </b-button>
            </div>
          </div>
        </div>
        <div v-if="dataLoaded && !isLoading" class="row">
          <div class="col-md-10">
            <div v-if="!service_carrier.data.length" class="d-flex justify-content-center">
              No data available
            </div>
            <apexchart
              ref="chart"
              :height="lineChart.options.chart.height"
              :options="lineChart.options"
              :series="lineChart.series"
              type="line"
              width="100%"
              @legendClick="updateSeriesVisibility"/>
          </div>
          <div class="col-md-2" style="padding-top: 0;padding-right:0">
            <div class="widget widget-stats bg-info" style="padding: 1.0rem">
              <div class="stats-content" style="text-align: center">
                <div class="stats-title">{{ $t('service-carrier.page.theAverageTimeDays') }}</div>
                <div
                  v-for="info in service_carrier.info"
                  :key="info.shipper + info.country"
                  class="p-0"
                  style="text-align: center"
                >
                  <div class="stats-title">
                    {{ info.shipper + '-' + info.country }}
                  </div>
                  <div class="stats-number text-success">
                    {{ info.orders }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isLoading" class="vgt-loading vgt-center-align position-static">
          <slot name="loadingContent">
            <span class="vgt-loading__content">Loading...</span>
          </slot>
        </div>
      </div>
    </panel>
    <panel
      id="service-carrier-info-panel"
      :title="$t('service-carrier.page.serviceCarrierInfo')"
      @reload="load(null)"
    >
      <service-carrier
        ref="serviceCarrier"
        :data="service_carrier.info"
        :isLoading="isLoading"
        @reload="load"/>
    </panel>
    <notifications group="msg" position="bottom right"/>
  </page-content>
</template>

<script>
import { client } from '@/client/client'
import PageContent from '../../../layout/PageContent'
import AppOptions from '../../../config/AppOptions.vue'
import ServiceCarrier from '@/modules/service-carrier/components/ServiceCarrier'
import { bus } from '@/main'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {
  components: {PageContent, ServiceCarrier},
  data() {
    return {
      activeRequests: 0,
      lineChart: {
        options: {
          chart: {
            type: 'line',
            height: 550,
            shadow: {
              enabled: true,
              color: 'rgba(' + AppOptions.color.componentColorRgb + ', .5)',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 1
            },
            toolbar: {
              show: true
            }
          },
          title: {
            text: 'Service Carrier Quality',
            offsetY: -30,
            align: 'center',
            style: {
              color: AppOptions.color.componentColor
            }
          },
          colors: [AppOptions.color.blue, AppOptions.color.indigo, AppOptions.color.red,
            AppOptions.color.green, AppOptions.color.lime, AppOptions.color.orange, AppOptions.color.pink, AppOptions.color.success],
          dataLabels: {
            enabled: true,
            background: {
              borderWidth: 0
            }
          },
          stroke: {
            curve: 'smooth',
            width: 3
          },
          grid: {
            row: {colors: ['rgba(' + AppOptions.color.componentColorRgb + ', .05)', 'transparent'], opacity: 0.5}
          },
          xaxis: {
            type: 'date',
            tickPlacement: 'between',
            categories: [],
            axisBorder: {
              show: true,
              color: AppOptions.color.gray300,
              height: 1,
              width: '100%',
              offsetX: 0,
              offsetY: -1
            },
            axisTicks: {
              show: true,
              borderType: 'solid',
              color: AppOptions.color.gray300,
              height: 6,
              offsetX: 0,
              offsetY: 0
            },
            dataMissing: {
              interpolate: 'zero'
            }
          },
          legend: {
            show: true,
            position: 'top',
            offsetY: -30,
            horizontalAlign: 'left',
            floating: true,
            labels: {
              colors: AppOptions.color.componentColor
            },
            onItemClick: {
              toggleDataSeries: false
            },
          }
        },
        series: []
      },
      dataLoaded: false,
      REQUEST_DATE_FORMAT: 'YYYY-MM-DD',
      dateFrom: null,
      dateTo: null,
      configs: {
        start: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false
        },
        end: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false
        }
      },
      selectedFilter: '',
      predefinedFilters: [
        {name: this.$t('today'), filter: 'today'},
        {name: this.$t('yesterday'), filter: 'yesterday'},
        {name: this.$t('lastWeek'), filter: 'last_7'},
        {name: this.$t('last30Days'), filter: 'last_30'},
        {name: this.$t('last6Months'), filter: 'last_6m'},
        {name: this.$t('lastYear'), filter: 'last_year'},
        {name: this.$t('custom'), filter: 'custom'}
      ],
      selectedDataFilter: {
        name: this.$t('service-carrier.filters.dailyStatistic'),
        filter: 'daily-frommh-allday-endtoend'
      },
      predefinedDataFilters: [
        {name: this.$t('service-carrier.filters.dailyStatistic'), filter: 'daily-frommh-allday-endtoend'},
        {name: this.$t('service-carrier.filters.weeklyStatistic'), filter: 'weekly-frommh-allday-endtoend'}
      ],
      service_carrier: {},
      isLoading: false,
      request: {
        cpy: this.$store.getters.currentCpy,
        per_page: 20,
        page: 1,
        date_from: null,
        date_to: null,
        order_column: 'day',
        order_dir: 'DESC',
        filters: {
          cpy: this.$store.getters.currentCpy,
        },
      },
      seriesData: [],
      selectedAggregation: null,
    }
  },
  async mounted() {
    await this.getDefaultData()
    bus.$on('cpyChanged', (data) => {
      this.request.cpy = data
      this.load()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
  },
  watch: {
    activeRequests(newValue) {
      this.isLoading = newValue > 0
    },
    selectedAggregation() {
      this.getServiceCarrier()
    },
    selectedFilter(newValue) {
      if (newValue !== 'custom') {
        this.applyDateFilter()
      }
    }
  },
  methods: {
    async getDefaultData() {
      this.activeRequests++
      try {
        const response = await client.auth().me()
        this.selectedFilter = response.data.defaultDateFilter

      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    updateSeriesVisibility(chart, seriesIndex, config) {
      config.config.series.forEach((serie) => {
        chart.hideSeries(serie.name)
      })
      chart.showSeries(config.config.series[seriesIndex].name)
    },
    aggregateByMonth() {
      this.lineChart.series = []
      let categories = []
      for (const carrierData in this.seriesData) {
        let seriesData = []
        let currentMonthTotal = 0
        let currentMonth

        if (this.seriesData[carrierData][0].week) {
          currentMonth = this.weekToMonth(this.seriesData[carrierData][0].week)
        } else {
          currentMonth = this.seriesData[carrierData][0].day.substring(0, 7)
        }

        for (let data of this.seriesData[carrierData]) {
          let month
          if (data.day) {
            month = data.day.substring(0, 7)
          } else {
            month = this.weekToMonth(data.week)
          }
          if (currentMonth !== month || data === this.seriesData[carrierData][this.seriesData[carrierData].length - 1]) {
            categories.push(currentMonth)
            currentMonthTotal += data.total

            currentMonthTotal = Math.round(currentMonthTotal * 100) / 100
            seriesData.push(currentMonthTotal)

            currentMonth = month
            currentMonthTotal = 0
          } else {
            currentMonthTotal += data.total
          }
        }
        if (carrierData && carrierData.length !== 0) {
          this.lineChart.series.push({name: carrierData, data: seriesData})
        }

        this.lineChart.options.xaxis.categories = categories
          .filter((value, index) => categories.indexOf(value) === index)
          .sort((a, b) => new Date(a) - new Date(b))
        this.lineChart.options.xaxis.labels = {shows: true, rotate: -45, rotateAlways: true}
      }
    },
    aggregateByWeek() {
      this.lineChart.series = []
      let categories = []

      for (const carrierData in this.seriesData) {
        let seriesData = []
        let currentWeekTotal = 0
        let currentWeek

        if (this.seriesData[carrierData][0].week) {
          currentWeek = this.weekToDateRange(this.seriesData[carrierData][0].week)
        } else {
          currentWeek = this.getWeek(this.seriesData[carrierData][0].day)
        }

        for (let data of this.seriesData[carrierData]) {
          let week
          if (data.day) {
            week = this.getWeek(data.day)
          } else {
            week = this.weekToDateRange(data.week)
          }

          if (currentWeek !== week || data === this.seriesData[carrierData][this.seriesData[carrierData].length - 1]) {
            categories.push(currentWeek)
            currentWeekTotal += data.total

            currentWeekTotal = Math.round(currentWeekTotal * 100) / 100
            seriesData.push(currentWeekTotal)

            currentWeek = week
            currentWeekTotal = 0
          } else {
            currentWeekTotal += data.total
          }
        }

        if (carrierData && carrierData.length !== 0) {
          this.lineChart.series.push({name: carrierData, data: seriesData})
        }
      }

      this.lineChart.options.xaxis.categories = categories
        .filter((value, index) => categories.indexOf(value) === index)
        .sort((a, b) => new Date(a) - new Date(b))
      this.lineChart.options.xaxis.labels = {shows: true, rotate: -45, rotateAlways: true}
    },
    aggregateDefault() {
      this.lineChart.series = []
      let categories = []

      for (const carrierData in this.seriesData) {
        let seriesData = []

        for (let data of this.seriesData[carrierData]) {
          data.day ? categories.push(data.day) : categories.push(data.week)
          seriesData.push(data.total)
        }
        if (carrierData && carrierData.length !== 0) {
          this.lineChart.series.push({name: carrierData, data: seriesData})
        }
      }

      this.lineChart.options.xaxis.categories = categories
        .filter((value, index) => categories.indexOf(value) === index)
        .sort((a, b) => new Date(a) - new Date(b))
      this.lineChart.options.xaxis.labels = {shows: true, rotate: -45, rotateAlways: true}
    },
    getWeek(dateString) {
      const date = new Date(dateString)
      const startDateOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay())
      const endDateOfWeek = new Date(startDateOfWeek)
      endDateOfWeek.setDate(endDateOfWeek.getDate() + 6)
      return `${startDateOfWeek.toISOString().substring(0, 10)} - ${endDateOfWeek.toISOString().substring(0, 10)}`
    },
    weekToMonth(dateString) {
      let [year, week] = dateString.split('-').map(Number)
      let date = new Date(year, 0, 1)
      let days = (week - 1) * 7
      date.setDate(date.getDate() + days)
      let month = date.getMonth() + 1
      month = month < 10 ? '0' + month : month.toString()
      return `${year}-${month}`
    },
    weekToDateRange(dateString) {
      let [year, week] = dateString.split('-').map(Number)
      let date = new Date(year, 0, 1)
      let days = (week - 1) * 7
      date.setDate(date.getDate() + days)
      let startDate = new Date(date)
      let endDate = new Date(date)
      endDate.setDate(endDate.getDate() + 6)
      let startDateFormat = `${year}-${('0' + (startDate.getMonth() + 1)).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`
      let endDateFormat = `${year}-${('0' + (endDate.getMonth() + 1)).slice(-2)}-${('0' + endDate.getDate()).slice(-2)}`

      return `${startDateFormat} - ${endDateFormat}`
    },
    async getServiceCarrier() {
      this.activeRequests++
      try {
        this.request.cpy = this.$store.getters.currentCpy
        this.request.filters.cpy = this.$store.getters.currentCpy
        const response = await client.service_carrier().all(this.request)
        this.service_carrier = response.data

        this.seriesData = this.groupSeriesData(this.service_carrier.data, 'shipped_by')
        this.lineChart.options.xaxis.categories = []

        this.aggregateDataBasedOnSelection()
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
        this.dataLoaded = true
      }
    },
    groupSeriesData(data, key) {
      return data.reduce((group, item) => {
        const groupKey = item[key]
        group[groupKey] = group[groupKey] ?? []
        group[groupKey].push(item)
        return group
      }, {})
    },
    aggregateDataBasedOnSelection() {
      if (this.selectedAggregation === 'month') {
        this.aggregateByMonth()
      } else if (this.selectedAggregation === 'week') {
        this.aggregateByWeek()
      } else {
        this.aggregateDefault()
      }
    },
    load(event) {
      if (event) {
        this.request.per_page = event.currentPerPage
        this.request.page = event.currentPage
      }
      this.getServiceCarrier()
    },
    resetFilters() {
      this.dateFrom = null
      this.dateTo = null
      this.request.predefined = 'daily-frommh-allday-endtoend'
      this.request.date_from = null
      this.request.date_to = null
      this.selectedDataFilter = {
        name: this.$t('service-carrier.filters.dailyStatistic'),
        filter: 'daily-frommh-allday-endtoend'
      }
      this.request.order_column = 'day'
      this.request.order_dir = 'DESC'
      this.request.filters = {cpy: this.$store.getters.currentCpy}
      this.selectedFilter = this.$store.getters.userDefaultDateFilter
    },
    applyDateFilter() {
      switch (this.selectedFilter) {
        case 'today':
          this.request.date_from = new Date()
          this.request.date_to = new Date()
          break
        case 'yesterday':
          var yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          this.request.date_from = yesterday
          this.request.date_to = yesterday
          break
        case 'last_7':
          var last_7 = new Date()
          last_7.setDate(last_7.getDate() - 7)
          this.request.date_from = last_7
          this.request.date_to = new Date()
          break
        case 'last_30':
          var last_30 = new Date()
          last_30.setDate(last_30.getDate() - 30)
          this.request.date_from = last_30
          this.request.date_to = new Date()
          break
        case 'last_6m':
          var last_6m = new Date()
          last_6m.setMonth(last_6m.getMonth() - 6)
          this.request.date_from = last_6m
          this.request.date_to = new Date()
          break
        case 'last_year':
          var last_year = new Date()
          last_year.setFullYear(last_year.getFullYear() - 1)
          this.request.date_from = last_year
          this.request.date_to = new Date()
          break
        case 'custom':
          this.request.date_from = this.dateFrom.format(this.REQUEST_DATE_FORMAT)
          this.request.date_to = this.dateTo.format(this.REQUEST_DATE_FORMAT)
          break
      }
      this.request.predefined = this.selectedDataFilter.filter
      this.getServiceCarrier()
    },
    handleFilterButtonClick() {
      if (this.selectedFilter === 'custom' && (!this.dateFrom || !this.dateTo)) {
        this.$notify({
          group: 'msg',
          type: 'error',
          text: 'Created From and Created To fields must be filled to apply custom date filter',
          duration: 5000
        })
      } else {
        this.applyDateFilter()
      }
    },
    changeDataFilter() {
      if (this.selectedFilter !== 'custom') {
        this.dateTo = null
        this.dateFrom = null
        this.applyDateFilter()
      }
    },
    onStartChange(e) {
      this.dateFrom = e.date
    },
    onEndChange(e) {
      this.dateTo = e.date
    }
  }
}
</script>

<style scoped>
::v-deep .apexcharts-legend-series.apexcharts-no-click {
  cursor: pointer;
}
</style>
