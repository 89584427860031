<template>
  <div class="login login-v1 login-custom" style="background-image: none">
    <div class="login-container">
      <div class="login-header">
        <div class="brand">
          <img alt="" src="../../../assets/img/logo/logo.png"/>
        </div>
        <div class="icon">
          <slot name="icon">
            <i class="fa fa-lock"/>
          </slot>
        </div>
      </div>
      <div class="login-body">
        <div class="login-content fs-13px">
          <form class="margin-bottom-0" method="POST" @submit="login">
            <div v-if="loginError" class="alert alert-danger">
              {{ loginError }}
            </div>
            <div class="form-floating mb-20px">
              <input v-model="username" :placeholder="$t('login.usernamePlaceholder')"
                     class="form-control fs-13px h-45px"
                     required type="text"/>
              <label class="d-flex align-items-center py-0" htmlFor="username">{{ $t('username') }}</label>
            </div>
            <div class="form-floating mb-20px">
              <input v-model="password" :placeholder="$t('login.passwordPlaceholder')"
                     class="form-control fs-13px h-45px"
                     required type="password"/>
              <label class="d-flex align-items-center py-0" htmlFor="password">{{ $t('password') }}</label>
            </div>
            <div class="login-buttons">
              <b-btn :disabled="loading" class="btn h-45px btn-success d-block w-100 btn-lg" type="submit">
                <span v-if="loading">
                  <b-spinner small></b-spinner>
                  <span class="sr-only">Loading...</span>
                </span>
                <span v-else>
                  {{ $t('login') }}
                </span>
              </b-btn>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppOptions from '../../../config/AppOptions'
import { client } from '@/client/client'

export default {
  created() {
    AppOptions.appEmpty = true
    AppOptions.appWithFooter = false
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false
    AppOptions.appWithFooter = true
    next()
  },
  data: function () {
    return {
      username: '',
      password: '',
      loginError: '',
      loading: false,
    }
  },
  methods: {
    async login(e) {
      e.preventDefault()

      try {
        this.loading = true

        const response = await client.auth().login(this.username, this.password)
        localStorage.setItem('token', response.data.token)
        refreshToken(response.data.expiration)

        const userResponse = await client.auth().me()
        const user = userResponse.data
        this.$store.commit('currentUser', user)

        if (user && user.defaultMandant && user.defaultMandant.cpy) {
          this.$store.commit('currentCpy', user.defaultMandant.cpy)
          // localStorage.setItem('storedSelectedCpy', user.defaultMandant.cpy)
        } else if (user && user.cpyList && this.$store.getters.cpy.length > 0 && this.$store.getters.cpy[0] && user.cpyList.includes(this.$store.getters.cpy[0])) {
          this.$store.commit('currentCpy', user.cpyList[0])
          // localStorage.setItem('storedSelectedCpy', user.cpyList[0])
        }

        this.loading = false
        this.$root.$emit('login')
        await this.$router.push({path: user.preferredView || '/dashboard'})
      } catch (error) {
        this.loading = false
        if (error.response && error.response.status === 403) {
          this.loginError = this.$t('loginFailed')
        }
      }
    }
  }
}

function refreshToken(expiration) {
  if (expiration) {
    setTimeout(doRefresh, expiration * 0.9)
  }

  function doRefresh() {
    client.auth().refresh()
      .then(response => {
        localStorage.setItem('token', response.data.token)
        refreshToken(response.data.expiration)
      })
  }
}
</script>

<style scoped>
.brand img {
  width: 240px;
}

.login-custom {
  background-color: white;
  background-image: none;
}
</style>
