<template>
  <div>
    <div>
      <vue-good-table
        ref="serviceCarrierInfo"
        :columns="columns"
        :globalSearch="true"
        :isLoading="isLoading"
        :lineNumbers="false"
        :mode="'local'"
        :responsive="true"
        :rows="data"
        :search-options="{ enabled: false, placeholder: 'Search' }"
        :selectOptions="{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				}"
        :sort-options="{
          enabled: false
        }">

      </vue-good-table>
    </div>
    <div></div>
  </div>
</template>


<script>
export default {
  name: 'ServiceCarrier',
  props: ['data', 'isLoading'],
  data() {
    return {
      columns: [
        {
          label: 'Country',
          field: 'country',
          width: '10%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap'
        },
        {
          label: 'Revenue NET',
          field: 'revenueNet',
          width: '5%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
        },
        {
          label: 'Revenue GROSS',
          field: 'revenueGross',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }
        ,
        {
          label: 'Tax revenue',
          field: 'taxRevenue',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }
        ,
        {
          label: 'Tax %',
          field: 'tax',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        },
        {
          label: 'Currency',
          field: 'currency',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        },
        {
          label: 'Invoicenr',
          field: 'invoicenr',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }
      ]
    }
  },
}
</script>

<style scoped>
.refunded {
  color: #18c471;
}
</style>
