import axios from "axios";
import {dashboardClientConfig} from "../config";


let apiRoot = dashboardClientConfig.apiRoot;

export function roles() {
    const rootUrl = apiRoot + "/access/role";
    return {
        all: () => axios.get(rootUrl),
        permissions: () => axios.get(rootUrl + "/permissions"),
        create: (role) => axios.post(rootUrl, role)
    }
}

export function role(roleId) {
    const rootUrl = apiRoot + "/access/role/" + roleId;
    return {
        get: () => axios.get(rootUrl),
        update: (role) => axios.put(rootUrl, role),
        delete: () => axios.delete(rootUrl)
    }
}
