<template>
  <div class="row">
    <div class="table-responsive">
      <table class="table table-striped mb-0 align-middle">
        <thead>
        <th
          v-if="hasAnyPermission(['CAN_CREATE_ROLE', 'CAN_UPDATE_ROLE'])"
          class="icon-btn-col"
        >
          <icon-btn
            icon="fa-plus-square color-green"
            tooltip="Create"
            @clicked="openModal()"/>
        </th>
        <th>{{ $t('name') }}</th>
        <th>{{ $t('description') }}</th>
        <th>{{ $t('permissions') }}</th>
        <th v-if="hasPermission('CAN_DELETE_ROLE')"></th>
        </thead>
        <tbody>
        <tr v-for="role in data" :key="role.id">
          <td
            v-if="hasAnyPermission(['CAN_CREATE_ROLE', 'CAN_UPDATE_ROLE'])"
            class="icon-btn-col"
          >
            <template v-if="hasPermission('CAN_UPDATE_ROLE')">
              <icon-btn
                icon="fa-pencil-alt color-green"
                tooltip="Edit"
                @clicked="openModal(role)"/>
            </template>
          </td>
          <td>{{ role.name }}</td>
          <td>{{ role.description }}</td>
          <td>
            {{ role.permissions ? role.permissions.length : 0 }}
          </td>
          <td
            v-if="hasPermission('CAN_DELETE_ROLE')"
            class="icon-btn-col"
          >
            <icon-btn
              icon="fa-trash-alt color-red"
              tooltip="Delete"
              @clicked="deleteRole(role)"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <b-modal
      :id="modalId"
      cancel-variant="default"
      no-close-on-backdrop
      size="xl"
    >
      <template slot="modal-header">
        <h4 class="modal-title">{{ role.id ? 'Edit Role' : 'Create Role' }}</h4>
        <a class="btn-close" @click="$bvModal.hide(modalId)"></a>
      </template>
      <form @submit.prevent="saveRole(role)">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <fieldset>
                  <div class="mb-3">
                    <label
                      class="form-label"
                      for="nameInput"
                    >
                      {{ $t('roles.name') }}
                    </label>
                    <input
                      id="nameInput"
                      v-model="role.name"
                      :placeholder="$t('roles.enterRoleName')"
                      autofocus
                      class="form-control"
                      required
                      type="text"/>
                  </div>
                  <div class="mb-3">
                    <label
                      class="form-label"
                      for="descriptionInput"
                    >
                      {{ $t('roles.name.description') }}
                    </label>
                    <input
                      id="descriptionInput"
                      v-model="role.description"
                      :placeholder="$t('roles.enterRoleDescription')"
                      class="form-control" type="text"/>
                  </div>
                </fieldset>
              </div>
              <div v-if="permissions" class="col-md-6">
                <fieldset>
                  <legend class="mb-1 h5">{{ $t('roles.permissions') }}</legend>
                  <div>
                    <div class="form-check mb-4">
                      <input
                        id="select_all"
                        :checked="isSelectedAll"
                        class="form-check-input"
                        type="checkbox"
                        @click="selectAll($event.target.checked)"/>
                      <label class="form-check-label"
                             for="select_all">
                        {{ isSelectedAll ? 'Remove all' : 'Select all' }}
                      </label>
                    </div>
                    <div
                      v-for="permission in permissions"
                      :key="permission"
                      class="form-check"
                    >
                      <input
                        :id="'permission-' + permission"
                        :checked="isSelected(permission)"
                        class="form-check-input"
                        type="checkbox"
                        @change="updateSelected(permission)"/>
                      <label :for="'permission-' + permission" class="form-check-label">
                        {{ permission }}
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        <b-button
          ref="submit"
          style="display: none;"
          type="submit"
        >
          {{ $t('submit') }}
        </b-button>
      </form>

      <template v-slot:modal-footer>
        <b-button variant="default" @click="closeModal">
          {{ $t('close') }}
        </b-button>
        <b-button :disabled="!selected.length" variant="success" @click="submitForm()">
          {{ $t('save') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import IconBtn from '../../../components/buttons/IconButton'
import confirmationBox from '@/components/confirmationBox'

export default {
  name: 'Roles',
  components: {IconBtn},
  props: {
    data: {
      type: Array,
      default: () => []
    },
    permissions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      modalId: 'roles-modal',
      role: {
        permissions: []
      },
      selected: []
    }
  },
  computed: {
    isSelectedAll() {
      return this.selected.length === this.permissions.length && this.permissions.length > 0
    }
  },
  methods: {
    saveRole(role) {
      let event = role.id ? 'updated' : 'created'
      role.permissions = this.selected
      this.$emit(event, role)
    },
    deleteRole(role) {
      confirmationBox(this).open('Confirm Removal', 'Do you want to remove role \'' + role.name + '\'?',
        () => {
          this.$emit('deleted', role.id)
        })
    },
    openModal(role) {
      if (role) {
        this.role = role
        this.selected = role.permissions
      } else {
        this.role = {
          name: 'New Role',
          description: '',
          permissions: []
        }
        this.selected = []
      }
      this.$bvModal.show(this.modalId)
    },
    submitForm() {
      this.$refs.submit.click()
    },
    closeModal() {
      this.$bvModal.hide(this.modalId)
    },
    isSelected(permission) {
      return this.selected.includes(permission)
    },
    updateSelected(selectedPermission) {
      if (this.selected.includes(selectedPermission)) {
        this.selected = this.selected.filter(permission => permission !== selectedPermission)
      } else {
        this.selected.push(selectedPermission)
      }
    },
    selectAll(selected) {
      if (selected) {
        this.selected = [...this.permissions]
      } else {
        this.selected = []
      }
    }
  }
}
</script>
