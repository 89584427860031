import axios from "axios";
import {dashboardClientConfig} from "../config";
import { requestManager } from '@/assets/requestManager.js';

let apiRoot = dashboardClientConfig.apiRoot;
let processFlowRootUrl = apiRoot + "/process-flow";
let processFlowBestsellerRootUrl = apiRoot + "/process-flow-bestseller";
let processFlowSalesByCountryRootUrl = apiRoot + "/process-flow-sales-by-country";
let processFlowOutStockRootUrl = apiRoot + "/process-flow-out-of-stock"; 

export function process_flow() {
    return {
        all: (obj) => axios.post(processFlowRootUrl, obj, {
          cancelToken: new axios.CancelToken(function executor(cancel) {
            requestManager.addRequest({ cancel }); 
          })
        }),
        pfbestsellers: (obj) => axios.post(processFlowBestsellerRootUrl, obj, {
          cancelToken: new axios.CancelToken(function executor(cancel) {
            requestManager.addRequest({ cancel }); 
          })
        }),
        pfsalesByCountry: (obj) => axios.post(processFlowSalesByCountryRootUrl, obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        pfoutOfStock: (obj) => axios.post(processFlowOutStockRootUrl, obj, {
          cancelToken: new axios.CancelToken(function executor(cancel) {
            requestManager.addRequest({ cancel }); 
          })
        }),
    }
}
