<template>
  <div>
    <vue-good-table
        ref="serviceCarrierInfo"
        :columns="columns"
        :rows="data"
        :isLoading="isLoading"
        :responsive="true"
        :mode="'local'"
        :lineNumbers="false"
        :search-options="{ enabled: false, placeholder: 'Search' }"
        :sort-options="{
          enabled: false,
          initialSortBy: {field: 'shipper', type: 'ASC'}
        }"
        :selectOptions="{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				}">
    </vue-good-table>
  </div>
</template>

<script>

export default {
  name: 'ServiceCarrier',
  components: {},
  props: ['data', 'isLoading'],
  methods: {
  },
  data() {
    return {
      columns: [
      {
        label: 'CARRIER',
        field: 'shipper',
        width: '10%',
        thClass: 'text-center text-nowrap',
        tdClass: 'text-center text-nowrap',
        filterOptions: {
          enabled: true,
          placeholder: "CARRIER"
        }
      }, {
          label: 'COUNTRY',
          field: 'country',
          width: '5%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
        }, {
          label: 'ORDERS',
          field: 'orders',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: 'RETURNS',
          field: 'returns',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: '1',
          field: 'd_0_1',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
      }, {
          label: '2',
          field: 'd_1_2',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: '3',
          field: 'd_2_3',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: '4',
          field: 'd_3_4',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: '5',
          field: 'd_4_5',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: '6',
          field: 'd_5_6',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: '7',
          field: 'd_6_7',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: 'more',
          field: 'd_more',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        },
        {
          label: '1 %',
          field: 'd_0_1_pr',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: '2 %',
          field: 'd_1_2_pr',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: '3 %',
          field: 'd_2_3_pr',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: '4 %',
          field: 'd_3_4_pr',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: '5 %',
          field: 'd_4_5_pr',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: '6 %',
          field: 'd_5_6_pr',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: '7 %',
          field: 'd_6_7_pr',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        }, {
          label: 'more %',
          field: 'd_more_pr',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
        },
      ]
    }
  },
}
</script>

<style scoped>
  .refunded {
    color: #18c471;
  }
</style>
