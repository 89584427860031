<template>
  <page-content>
    <div id="return-widget"></div>
  </page-content>
</template>

<script>
import PageContent from '@/layout/PageContent.vue'

export default {
  components: {PageContent},
  data() {
    return {
      script: null,
    }
  },
  mounted() {
    const widget = document.querySelector('.widget-container')
    if (widget) {
      widget.style.display = 'block'
    } else {
      this.loadScript()
    }
  },
  methods: {
    loadScript() {
      this.script = document.createElement('script')
      this.script.src = 'https://dashboard.mhdirekt.com/scripts/return-label-widget@v1.min.js?api_key=Kg8Zbtm1uZ1w5b-bEkaQYFWzICQ&lang=en&mode=test'
      this.script.async = true
      const widgetContainer = document.querySelector('#return-widget')

      widgetContainer.appendChild(this.script)
    }
  }
}
</script>
