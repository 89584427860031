<template>
  <b-card :title="$t('changeDefaultDateFilter')">
    <b-form @submit.prevent="submitDefaultDateFilter">
      <b-form-group>
        <b-form-select
          v-model="selectedDefaultDateFilter"
          :options="predefinedFilters"
          class="form-select mt-2"
        />
      </b-form-group>
      <b-button
        :disabled="loading"
        class="mt-2"
        type="submit"
        variant="primary"
      >
        <b-spinner v-if="loading" small/>
        {{ loading ? $t('loading') : $t('changeDate') }}
      </b-button>
    </b-form>
  </b-card>
</template>


<script>
import { client } from '@/client/client'

export default {
  name: 'ChangeDateFilterForm',
  props: ['defaultFilter'],
  data() {
    return {
      loading: false,
      selectedDefaultDateFilter: '',
      predefinedFilters: [
        {text: this.$t('today'), value: 'today'},
        {text: this.$t('yesterday'), value: 'yesterday'},
        {text: this.$t('lastWeek'), value: 'last_7'},
        {text: this.$t('last30Days'), value: 'last_30'},
        {text: this.$t('last6Months'), value: 'last_6m'},
        {text: this.$t('lastYear'), value: 'last_year'},
      ],
    }
  },
  mounted() {
    this.selectedDefaultDateFilter = this.defaultFilter
  },
  watch: {
    defaultFilter(newValue) {
      this.selectedDefaultDateFilter = newValue
    }
  },
  methods: {
    async submitDefaultDateFilter() {
      this.loading = true
      try {
        await client.profile().change_default_date_filter({
          defaultDateFilter: this.selectedDefaultDateFilter,
          userId: this.$store.getters.user.id
        })

        const response = await client.auth().me()
        const user = response.data
        this.$store.commit('currentUser', user)

        this.$swal.fire({
          icon: 'success',
          title: 'Default date filter changed',
          text: 'Your date filter has been changed successfully',
          showConfirmButton: false,
          timer: 1500
        })
      } catch (error) {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message,
          showConfirmButton: false,
          timer: 1500
        })
      } finally {
        this.loading = false
      }
    },
  }
}
</script>


