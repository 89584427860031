var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.appOptions.appEmpty)?_c('div',{staticClass:"app",class:{
		'app-header-fixed': _vm.appOptions.appHeaderFixed && !_vm.appOptions.appHeaderNone,
		'app-sidebar-fixed': _vm.appOptions.appSidebarFixed,
		'app-sidebar-minified': _vm.appOptions.appSidebarMinified,
		'app-content-full-height': _vm.appOptions.appContentFullHeight,
		'app-without-sidebar': _vm.appOptions.appSidebarNone,
		'app-with-end-sidebar': _vm.appOptions.appSidebarEnd,
		'app-with-two-sidebar': _vm.appOptions.appSidebarTwo,
		'app-with-wide-sidebar': _vm.appOptions.appSidebarWide,
		'app-with-light-sidebar': _vm.appOptions.appSidebarLight,
		'app-sidebar-mobile-toggled': _vm.appOptions.appSidebarMobileToggled,
		'app-sidebar-end-toggled': _vm.appOptions.appSidebarEndToggled,
		'app-sidebar-end-collapsed': !_vm.appOptions.appSidebarEndToggled,
		'app-sidebar-end-mobile-toggled': _vm.appOptions.appSidebarEndMobileToggled,
		'app-without-header': _vm.appOptions.appHeaderNone,
		'app-with-top-menu': _vm.appOptions.appTopMenu,
		'app-gradient-enabled': _vm.appOptions.appGradientEnabled,
		'has-scroll': _vm.appOptions.appBodyScrollTop
	}},[_c('Header'),(_vm.appOptions.appTopMenu)?_c('TopMenu'):_vm._e(),(!_vm.appOptions.appSidebarNone)?_c('Sidebar'):_vm._e(),(_vm.appOptions.appSidebarTwo)?_c('SidebarRight'):_vm._e(),_c('div',{staticClass:"app-content",class:_vm.appOptions.appContentClass,attrs:{"id":"content"}},[_c('router-view'),_c('vue-ins-progress-bar'),_c('vue-confirm-dialog',{staticClass:"dashboard-confirmation-box"})],1),(_vm.appOptions.appWithFooter)?_c('Footer'):_vm._e()],1):_c('div',{staticClass:"h-100"},[_c('router-view'),_c('vue-ins-progress-bar')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }