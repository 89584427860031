<template>
  <div>
    <vue-good-table
        ref="skuRevenue"
        :columns="columns"
        :rows="data.data"
        :total-rows="data.total"
        :isLoading="isLoading"
        :responsive="true"
        @on-page-change="load"
        @on-per-page-change="load"
        @on-column-filter="filterChanged"
        @on-sort-change="onSortChange"
        :mode="'remote'"
        :lineNumbers="false"
        :search-options="{ enabled: false, placeholder: 'Search' }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'sku', type: 'desc'}
        }"
        :selectOptions="{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				}"
        :paginationOptions="{
          enabled: true,
          position: 'bottom',
          perPage: 20,
          perPageDropdown: null,
          perPageDropdownEnabled: true,
          dropdownAllowAll: false,
          mode: 'records', // or pages
          infoFn: null,
          jumpFirstOrLast : true
        }"
    >
    </vue-good-table>
  </div>
</template>

<script>

export default {
  name: 'SkuRevenue',
  components: {},
  props: ['data', 'isLoading'],
  methods: {
    load: function(event) {
      this.$emit('reload', event);
    },
    onSortChange: function (event) {
      this.$emit('sort', event);
    },
    filterChanged: function (event) {
      this.$emit('filter', event);
    },
    filterButton(type) {
      this.$emit('filter-button', type);
    },
    reset() {
      this.$refs.skuRevenueData.reset();
    }
  },
  data() {
    return {
      columns: [
      {
        label: 'SKU',
        field: 'sku',
        width: '10%',
        thClass: 'text-center text-nowrap',
        tdClass: 'text-center text-nowrap',
        filterOptions: {
          enabled: true,
          placeholder: "SKU"
        }
      },
      {
          label: 'DESCRIPTION',
          field: 'skuDesc',
          width: '10%',
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: "DESCRIPTION" 
          }
        }
      , {
        label: 'DATE',
        field: 'dateMonth',
        width: '5%',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
        filterOptions: {
            enabled: true,
            placeholder: "DATE" 
          }        
      },
      {
          label: 'CUSTOMER NAME',
          field: 'customerName',
          width: '10%',
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: "CUSTOMER NAME"
          }
        },
        {
          label: 'ORDER NUMBER',
          field: 'orderNumber',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: "ORDER NUMBER"
          }
        }         
      , {
        label: 'NUM SALES',
        field: 'numSales',
        width: '5%',
        tdClass: 'text-center valign-middle text-nowrap',
        thClass: 'text-center text-nowrap'
      }, {
          label: 'REVENUE',
          field: 'revenue',
          width: '5%',
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap'
        }
      ]
    }
  },
}
</script>

<style scoped>
  .refunded {
    color: #18c471;
  }
</style>
