<template>
  <div>
    <b-modal
      ref="changeAddressModal"
      :title="$t('changeAddress')"
      centered
      class="modal"
      hide-footer
      hide-header
      size="xl"
    >
      <change-address-form
        v-if="orderAddress"
        :address="orderAddress"
        @updateAddress="hideChangeAddressModal"
      />
    </b-modal>

    <b-modal
      id="hold-release-modal"
      hide-footer
      hide-header-close
      title="Hold/Release Order"
      @hide="closeHoldModal"
    >
      <b-alert
        :show="holdReleaseMessage"
        :variant="holdReleaseStatus"
      >
        {{ holdReleaseMessage }}
      </b-alert>
      <b-form>
        <b-form-group label="Notes">
          <b-form-textarea
            id="textarea"
            v-model="holdReleaseNote"
            max-rows="6"
            placeholder="Enter something..."
            rows="3"
          />
        </b-form-group>
        <div class="d-flex mt-2 justify-content-end gap-2">
          <b-btn
            variant="primary"
            @click="closeHoldModal"
          >
            Cancel
          </b-btn>
          <b-btn
            variant="danger"
            @click="submitHoldRelease(holdReleaseRow.is_blocked ? 'release' : 'hold', holdReleaseRow)"
          >
            Submit
          </b-btn>
        </div>
      </b-form>
    </b-modal>

    <div class="row filter-button-group">
      <div class="col-md-6">
        <b-button
          :class="getFilterClass('not-shipped-yet')"
          class="btn btn-default me-1 mb-1 btn-not-shipped-yet-filter"
          style="font-size: 0.7rem;"
          type="button"
          @click="filterButton('not-shipped-yet')"
        >
          {{ $t('orders.notShippedYet') }}
        </b-button>
        <b-button
          :class="getFilterClass('no-progress-in-tracking')"
          class="btn btn-default me-1 mb-1 btn-no-progress-in-tracking-filter"
          style="font-size: 0.7rem;"
          type="button"
          @click="filterButton('no-progress-in-tracking')"
        >
          {{ $t('orders.noProgressInTracking') }}
        </b-button>
        <b-button
          :class="getFilterClass('backorders')"
          class="btn btn-default me-1 mb-1 btn-backorders-filter"
          style="font-size: 0.7rem;"
          type="button"
          @click="filterButton('backorders')"
        >
          {{ $t('backorders') }}
        </b-button>
        <b-button
          :class="getFilterClass('delivery-issues')"
          class="btn btn-default me-1 mb-1 btn-clearstation-filter"
          style="font-size: 0.7rem;"
          type="button"
          @click="filterButton('delivery-issues')"
        >
          {{ $t('issues') }}
        </b-button>
        <b-button
          :class="getFilterClass('canceled')"
          class="btn btn-default me-1 mb-1 btn-backorders-filter"
          style="font-size: 0.7rem;"
          type="button"
          @click="filterButton('canceled')"
        >
          {{ $t('orders.Cancelled') }}
        </b-button>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <select
              v-model="selectedFilter"
              class="form-select"
              @change="changeFilter('change')"
            >
              <option
                v-for="(filter, index) in predefinedFilters"
                :key="filter.name + index"
                :value="filter.filter"
              >
                {{ filter.name }}
              </option>
            </select>
          </div>
          <div class="col-md-3 padding-left-0 margin-left-0">
            <date-picker
              ref="dateFrom"
              v-model="dateFrom"
              :config="configs.start"
              :disabled="selectedFilter !== 'custom'"
              :placeholder="$t('createdFrom')"
              @dp-change="onStartChange"/>
          </div>
          <div class="col-md-3 padding-left-0 margin-left-0">
            <date-picker
              ref="dateTo"
              v-model="dateTo"
              :config="configs.end"
              :disabled="selectedFilter !== 'custom'"
              :placeholder="$t('createdTo')"
              @dp-change="onEndChange"/>
          </div>
          <div class="col-md-3 padding-left-0">
            <b-button
              class="button btn-success margin-right-5" @click="handleFilterButtonClick">
              {{ $t('filter') }}
            </b-button>
            <b-button class="button btn-warning" @click="resetFilters">
              {{ $t('reset') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <vue-good-table
      ref="orders"
      :columns="columns"
      :isLoading="isLoading"
      :lineNumbers="false"
      :mode="'remote'"
      :paginationOptions="tablePaginationOptions"
      :responsive="true"
      :row-style-class="rowStyleClassFn"
      :rows="data.data"
      :search-options="{ enabled: false, placeholder: 'Search' }"
      :selectOptions="tableSelectOptions"
      :sort-options="tableSortOptions"
      :total-rows="data.total"
      @on-page-change="load"
      @on-per-page-change="load"
      @on-column-filter="filterChanged"
      @on-sort-change="onSortChange">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'tracking_id'">
          <a :href="props.row.tracking_url" target="_blank"
          >{{ props.formattedRow[props.column.field] }}</a>
        </span>
        <span v-if="props.column.field === 'more'">
          <b-dropdown
            dropup
            no-caret
            offset="150"
            toggle-class="btn-icon"
          >
            <template #button-content>
              <i class="fa fa-ellipsis-h"/>
            </template>
            <b-dropdown-item @click.prevent="doDetial(props.row)">
              <div class="d-flex align-items-center" style="gap: 4px">
                <span
                  style="display: flex; min-width: 15px; max-width: 15px; font-size: 12px;"
                >
                  <i class="fa fa-magnifying-glass"/>
                </span>
                <span>{{ $t('details') }}</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item v-if="$store.getters.userPermissions.includes('CAN_READ_EVENT_LOGS')"
                             @click.prevent="openLogs(props.row)">
              <div class="d-flex align-items-center" style="gap: 4px">
                <span
                  style="display: flex; min-width: 15px; max-width: 15px; font-size: 12px;"
                >
                  <i class="fa-solid fa-bars"></i>
                </span>
                <span>{{ $t('eventLog') }}</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="props.row.tracking_id !== '' && props.row.tracking_id !== ' '"
              @click.prevent="openChangeAddressModal(props.row)"
            >
              <div class="d-flex align-items-center" style="gap: 4px">
                <span
                  style="display: flex; min-width: 15px; max-width: 15px; font-size: 12px;"
                >
                  <i class="fa fa-pencil"/>
                </span>
                <span>{{ $t('changeAddress') }}</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="doNote(props.row)">
              <div class="d-flex align-items-center" style="gap: 4px">
                <span
                  style="display: flex; min-width: 15px; max-width: 15px; font-size: 12px;"
                >
                  <i class="fa fa-book"/>
                </span>
                <span>{{ $t('note') }}</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="showHoldModal(props.row)">
              <div class="d-flex align-items-center" style="gap: 4px">
                <span
                  style="display: flex; min-width: 15px; max-width: 15px; font-size: 12px;"
                >
                  <i :class="props.row.is_blocked ? 'fa fa-resume' : 'fa fa-pause'"/>
                </span>
                <span>{{ props.row.is_blocked ? 'Resume' : 'Hold' }}</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </span>
        <span v-if="props.column.field !== 'tracking_id'">
						{{ props.formattedRow[props.column.field] }}
					</span>
      </template>
      <div v-if="!isLoading" slot="emptystate">
        <span
          v-if="selectedFilter === 'last_year' || selectedFilter === 'custom'">
          No result found in {{ selectedFilterName }}. Please check selected filters.
        </span>
        <span v-else>
          No result found in {{ selectedFilterName }}.
          <a href="" @click.prevent="changeFilter('extend')"
          >Would you like to change date filter to last year?</a>
        </span>
      </div>
    </vue-good-table>

    <b-modal
      id="modal-detail"
      ref="modal_detail"
      :hide-header="true"
      :title="$t('detail')"
      :visible="modalDetailVisible"
      centered
      ok-only
      size="xl"
      @close="okDetail"
      @hidden="okDetail"
      @ok="okDetail"
    >
      <div class="d-flex justify-content-end">
        <b-button class="button close-button" @click="modalDetailVisible = false">
          <span aria-hidden="true">&times;</span>
        </b-button>
      </div>

      <order-delivery-details-content
        :cpy="currentCpy"
        :delivery_id="deliveryId"
        :order_id="orderId"
      />
    </b-modal>

    <b-modal
      id="modal-event-logs"
      ref="modalEventLogs"
      :visible="modalEventLogs"
      centered
      hide-footer
      hide-header
      ok-only
      size="xl"
      title="Event logs"
      @close="modalEventLogs = false"
    >
      <div class="d-flex justify-content-end">
        <b-button class="button close-button" @click="modalEventLogs = false">
          <span aria-hidden="true">&times;</span>
        </b-button>
      </div>

      <order-event-logs
        :cpy="currentCpy"
        :delivery_id="deliveryId"
        :order_id="orderId"
      />
    </b-modal>

    <b-modal
      id="modal-detail-comment"
      ref="modal_comment_on_order"
      :title="$t('commentOnOrder')"
      :visible="modalCommentVisible"
      centered
      hide-header
      no-close-on-backdrop
      ok-title="Save"
      size="xl"
      @cancel="modalCommentVisible = false"
      @ok="saveComment"
    >
      <panel id="comment" :title="$t('commentOnOrder')" resizeable="true">
        <OrderComment :gridData="data.data" :selectedRow="selectedRow"/>
      </panel>
    </b-modal>
    <notifications group="msg" position="bottom right"/>
  </div>
</template>

<script>
import { bus } from '@/main'
import { client } from '@/client/client'
import ChangeAddressForm from '@/modules/support/components/ChangeAddressForm.vue'
import OrderComment from '../../../modules/orders/components/OrderComment'
import OrderDeliveryDetailsContent from './OrderDeliveryDetailsContent.vue'
import OrderEventLogs from '@/modules/orders/components/OrderEventLogs.vue'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)
export default {
  name: 'Orders',
  components: {OrderEventLogs, ChangeAddressForm, OrderComment, OrderDeliveryDetailsContent},
  props: ['data', 'isLoading', 'selectedDateFilter'],
  data() {
    return {
      holdReleaseNote: '',
      holdReleaseMessage: null,
      holdReleaseStatus: 'success',
      holdReleaseRow: null,
      selectedFilter: '',
      predefinedFilters: [
        {name: this.$t('today'), filter: 'today'},
        {name: this.$t('yesterday'), filter: 'yesterday'},
        {name: this.$t('lastWeek'), filter: 'last_7'},
        {name: this.$t('last30Days'), filter: 'last_30'},
        {name: this.$t('last6Months'), filter: 'last_6m'},
        {name: this.$t('lastYear'), filter: 'last_year'},
        {name: this.$t('custom'), filter: 'custom'}
      ],
      selectedRow: {},
      modalCommentVisible: false,
      modalDetailVisible: false,
      modalEventLogs: false,
      orderId: null,
      currentCpy: null,
      deliveryId: null,
      orderAddress: null,
      REQUEST_DATE_FORMAT: 'YYYY-MM-DD',
      filter: '',
      dateFrom: null,
      dateTo: null,
      configs: {
        start: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false
        },
        end: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false
        }
      },
      columns: [
        {
          label: '',
          field: 'more',
          filterable: false,
          sortable: false,
          width: '1%',
        },
        {
          label: 'ORDER ID',
          field: 'order_id',
          width: '15%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ORDER ID'
          }
        },
        {
          label: 'DELIVERY ID',
          field: 'delivery_id',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'DELIVERY ID'
          }
        },
        {
          label: 'NAME',
          field: 'customer_name',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'NAME'
          }
        },
        {
          label: 'EMAIL',
          field: 'email',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'EMAIL'
          }
        },
        {
          label: 'SALES FACILITY',
          field: 'full_sale_fcy_name',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SALES FACILITY'
          }
        },
        {
          label: 'STOCK FACILITY',
          field: 'full_stock_fcy_name',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            // filterDropdownItems: this.stofcy,
            placeholder: 'STOCK FACILITY'
          }
        },
        {
          label: 'CREATED',
          field: 'order_created_at',
          type: 'date',
          dateInputFormat: 'yyyyy-MM-dd HH:mm:ss.S',
          dateOutputFormat: 'yyyy-MM-dd',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap'
        },
        {
          label: 'ORDER STATUS',
          field: 'order_status',
          width: '2%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ORDER STATUS'
          }
        },
        {
          label: 'SHIPPED',
          field: 'ship_date',
          // type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss.S',
          dateOutputFormat: 'dd.MM.yyyy',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap'
        },
        {
          label: 'COUNTRY',
          field: 'country',
          width: '10%',
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'COUNTRY'
          }
        },
        {
          label: 'CARRIER',
          field: 'carrier',
          width: '10%',
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'CARRIER'
          }
        },
        {
          label: 'TRACKING',
          field: 'tracking_id',
          link: 'tracking_url',
          width: '10%',
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'TRACKING'
          }
        },
        {
          label: 'NOTE',
          field: 'comment_on_order',
          link: 'comment_on_order',
          width: '10%',
          tdClass: 'text-left valign-middle text-wrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'NOTE'
          }
        },
        {
          label: 'SHIPPING NOTE',
          field: 'note_on_delivery',
          width: '10%',
          tdClass: 'text-left valign-middle text-wrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SHIPPING NOTE'
          }
        },
        {
          label: 'CUSTOMER COMMENTS',
          field: 'comment_on_order',
          width: '10%',
          tdClass: 'text-left valign-middle text-wrap',
          thClass: 'text-center text-wrap',
          filterOptions: {
            enabled: true,
            placeholder: 'CUSTOMER COMMENTS'
          }
        }
      ],
      tablePaginationOptions: {
        enabled: true,
        position: 'bottom',
        perPage: 20,
        perPageDropdown: null,
        perPageDropdownEnabled: true,
        dropdownAllowAll: false,
        mode: 'records',
        infoFn: null,
        jumpFirstOrLast: true
      },
      tableSelectOptions: {
        enabled: false,
        selectOnCheckboxOnly: false,
        selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
      },
      tableSortOptions: {
        enabled: true,
        initialSortBy: {field: 'order_created_at', type: 'desc'}
      }
    }
  },
  created() {
    if (this.$route.query.filter) {
      this.filter = this.$route.query.filter
    }

    bus.$on('onSelectedRowChange', (data) => {
      this.selectedRow.comment_on_order = data
    })
  },
  async mounted() {
    await this.getDefaultData()
    bus.$on('cpyChanged', (data) => {
      if (data && this.$refs.orders) {
        this.$refs.orders.reset()
      }
    })
  },
  beforeDestroy() {
    bus.$off('onSelectedRowChange')
    bus.$off('cpyChanged')
  },
  watch: {
    selectedFilter(newValue) {
      if (newValue !== 'custom') {
        this.applyDateFilter()
      }
    }
  },
  computed: {
    selectedFilterName() {
      const filter = this.predefinedFilters.find(predefinedFilter => predefinedFilter.filter === this.selectedFilter)
      return filter ? filter.name : ''
    }
  },
  methods: {
    async getDefaultData() {
      this.activeRequests++
      try {
        const response = await client.auth().me()
        this.selectedFilter = response.data.defaultDateFilter

      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    rowStyleClassFn(row) {
      return row.order_status ? 'trdanger' : ''
    },
    saveComment() {
      bus.$emit('saveCommentEvent')
      this.modalCommentVisible = false
    },
    doNote(row) {
      this.selectedRow = row
      this.modalCommentVisible = true
    },
    doDetial(event) {
      this.orderId = event.order_id
      this.currentCpy = this.$store.getters.currentCpy
      this.deliveryId = event.delivery_id
      this.modalDetailVisible = true
    },
    okDetail() {
      this.orderId = null
      this.currentCpy = null
      this.deliveryId = null
      this.modalDetailVisible = false
    },
    openLogs(event) {
      this.orderId = event.order_id
      this.currentCpy = this.$store.getters.currentCpy
      this.deliveryId = event.delivery_id
      this.modalEventLogs = true
    },
    load(event) {
      this.$emit('reload', event)
    },
    filterChanged(event) {
      this.$emit('filter', event)
    },
    onSortChange(event) {
      if (event) {
        this.$emit('sort', event)
      }
    },
    resetFilters() {
      if (this.$route.query.filter) {
        this.$router.push({path: '/orders/deliveries', query: {}})
      }
      this.dateFrom = null
      this.dateTo = null
      this.filter = ''
      this.selectedFilter = this.$store.getters.userDefaultDateFilter
      this.$emit('filter-button')
    },
    filterButton(type) {
      this.filter = type
      this.$emit('filter-button', type)
      if (type === 'reset') {
        this.$refs.orders.reset()
        var last_30 = new Date()
        last_30.setDate(last_30.getDate() - 30)
        this.dateFrom = last_30
        this.dateTo = null

        this.$emit('date-filter', {
          date_from: last_30,
          date_to: new Date()
        })
      }
    },
    getFilterClass(type) {
      return this.filter === type ? 'selected-filter-button' : ''
    },
    changeFilter(type) {
      if (type === 'extend') {
        this.selectedFilter = 'last_year'
        this.$emit('extend-filter', {
          selectedDateFilter: this.selectedFilter
        })
      }
    },
    applyDateFilter() {
      switch (this.selectedFilter) {
        case 'today':
          this.$emit('date-filter', {
            date_from: new Date(),
            date_to: new Date()
          })
          break
        case 'yesterday':
          var yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          this.$emit('date-filter', {
            date_from: yesterday,
            date_to: yesterday
          })
          break
        case 'last_7':
          var last_7 = new Date()
          last_7.setDate(last_7.getDate() - 7)
          this.$emit('date-filter', {
            date_from: last_7,
            date_to: new Date()
          })
          break
        case 'last_30':
          var last_30 = new Date()
          last_30.setDate(last_30.getDate() - 30)
          this.$emit('date-filter', {
            date_from: last_30,
            date_to: new Date()
          })
          break
        case 'last_6m':
          var last_6m = new Date()
          last_6m.setMonth(last_6m.getMonth() - 6)
          this.$emit('date-filter', {
            date_from: last_6m,
            date_to: new Date()
          })
          break
        case 'last_year':
          var last_year = new Date()
          last_year.setFullYear(last_year.getFullYear() - 1)
          this.$emit('date-filter', {
            date_from: last_year,
            date_to: new Date()
          })
          break
        case 'custom':
          this.$emit('date-filter', {
            date_from: this.dateFrom.format(this.REQUEST_DATE_FORMAT),
            date_to: this.dateTo.format(this.REQUEST_DATE_FORMAT)
          })
          break
      }
    },
    onStartChange(e) {
      this.dateFrom = e.date
    },
    onEndChange(e) {
      this.dateTo = e.date
    },
    async openChangeAddressModal(row) {
      const address = await client.change_address().address_get({
        id: row.delivery_id ? row.delivery_id : row.order_id,
        cpy: this.$store.getters.currentCpy
      })

      this.orderAddress = address.data
      this.$refs.changeAddressModal.show()
    },
    async hideChangeAddressModal() {
      this.$refs.changeAddressModal.hide()
      this.orderAddress = null
      this.load()
      this.$notify({
        group: 'msg',
        type: 'success',
        text: 'Your order address updated successfully!',
        duration: 5000
      })
    },
    showHoldModal(row) {
      this.holdReleaseRow = row
      this.$bvModal.show('hold-release-modal')
    },
    closeHoldModal() {
      this.holdReleaseRow = null
      this.holdReleaseNote = ''
      this.holdReleaseStatus = 'success'
      this.holdReleaseMessage = null
      this.$bvModal.hide('hold-release-modal')
    },
    submitHoldRelease(action, row) {
      this.$bvModal.msgBoxConfirm('Are you sure?')
        .then(async () => {
          const obj = {
            'orderNr': row.order_id,
            'notes': this.holdReleaseNote,
            'cpy': this.$store.getters.currentCpy,
            'state': action === 'hold' ? '1' : '2'
          }
          try {
            const response = await client.orders_sage().holdRelease(obj)

            if (response.data.status === '1') {
              this.holdReleaseMessage = response.data.message
              this.holdReleaseStatus = 'success'
              this.holdReleaseNote = ''
            } else {
              this.holdReleaseMessage = response.data.message
              this.holdReleaseStatus = 'danger'
            }
          } catch (error) {
            console.error('Error:', error)
            this.holdReleaseStatus = 'danger'
            this.holdReleaseMessage = 'Failed to hold/release order'
          }
        })
    },
    handleFilterButtonClick() {
      if (this.selectedFilter === 'custom' && (!this.dateFrom || !this.dateTo)) {
        this.$notify({
          group: 'msg',
          type: 'error',
          text: 'Created From and Created To fields must be filled to apply custom date filter',
          duration: 5000
        })
      } else {
        this.applyDateFilter()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.filter-button-group {
  margin-top: -5px;
  padding-bottom: 10px;
}

.btn-no-label-filter {
  background-color: rgb(199, 240, 210);
  color: rgb(0, 0, 0);
}

.btn-not-printed-filter {
  background-color: rgb(228, 240, 195);
  color: rgb(0, 0, 0);
}

.btn-not-shipped-yet-filter {
  background-color: rgb(245, 223, 189);
  color: rgb(0, 0, 0);
}

.btn-no-progress-in-tracking-filter {
  background-color: rgba(243, 217, 73, 0.4);
  color: rgb(0, 0, 0);
}

.btn-backorders-filter {
  background-color: rgba(255, 130, 73, 0.4);
  color: rgb(0, 0, 0);
}

.btn-clearstation-filter {
  background-color: rgba(255, 130, 73, 0.4);
  color: rgb(0, 0, 0);
}

.selected-filter-button {
  opacity: 1;
  border: 3px solid !important;
  font-weight: 1000;
}

::v-deep {
  table.vgt-table td {
    vertical-align: middle;
  }
}

.close-button {
  padding: 2px 7px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
</style>
