import axios from 'axios'
import { dashboardClientConfig } from '../config'
import { requestManager } from '@/assets/requestManager.js'

let apiRoot = dashboardClientConfig.apiRoot
let rootUrl = apiRoot + '/orders-events'

export function order_events() {
  return {
    all: (obj) => axios.post(rootUrl, obj, {
      cancelToken: new axios.CancelToken(function executor(cancel) {
        requestManager.addRequest({cancel})
      })
    })
  }
}

