<template>
  <page-content>
    <panel
      id="dashboards"
      :backButtonVisible="false"
      :lastUpdate="lastUpdate"
      :reloadButtonVisible="true"
      :title="$t('dashboard')"
      @reload="load(null)"
    >
      <div id="dashboard-orders-chart" class="row" style="margin-top: 15px">
        <div class="widget widget-stats">
          <div class="row">
            <div id="dashboard-bestsellers" class="col-lg-12 col-md-12 col-sm-12 mb-12">
              <div>
                <div>
                  <div class="row">
                    <div class="col-xl-5 col-md-5 mb-3">
                      <b>{{ $t('overview') }}</b>
                      <span class="ms-2">
                        <i v-b-popover.hover="$t('totalOverview')" class="fa fa-info-circle"/>
                      </span>
                    </div>
                    <div id="dropdown-bestsellers-div" class="col-xl-2 col-md-2">
                      <select v-model="total_orders_filter" class="form-select mb-3">
                        <option
                          v-for="filter in overview_filters"
                          :key="filter.filter"
                          :value="filter.filter"
                        >
                          {{ filter.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-xl-2 col-md-2">
                      <div class="padding-left-0">
                        <date-picker
                          v-model="dateFrom"
                          :config="configs.start"
                          :disabled="total_orders_filter !== 'custom'"
                          :placeholder="$t('createdFrom')"
                          v-on:dp-change="onStartChange"/>
                      </div>
                    </div>
                    <div class="col-xl-2 col-md-2">
                      <div class="padding-left-0">
                        <date-picker
                          v-model="dateTo"
                          :config="configs.end"
                          :disabled="total_orders_filter !== 'custom'"
                          :placeholder="$t('createdTo')"
                          v-on:dp-change="onEndChange"/>
                      </div>
                    </div>
                    <div class="col-xl-1 col-md-1">
                      <b-btn size="lg" @click.prevent="handleFilterButtonClick">Filter</b-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="dashboard-orders-qty" class="row" style="margin-top: 15px">
            <div class="col-sm-12 px-2 dashboard-filter-oder-flow container-fluid">
              <div class="row">
                <div class="col-sm-3 px-2 order-flow-block container-fluid">
                  <div class="dashboard-orders-provided dashboard-orders-label">
                    <div class="dashboard-filter-block">
                      <div class="dashboard-filter-oder-flow-label">
                        <span class="dashboard-filter-oder-flow-title">
                          {{ $t('process-flow.page.ordersProvided') }}
                        </span>
                        <span class="dashboard-filter-oder-flow-value">
                        <span v-if="!flow || loading">
                          <b-spinner/>
                        </span>
                        <span v-else>
                          {{ flow.orders_provided }}
                        </span>
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3 px-2 order-flow-block container-fluid">
                  <div class="dashboard-orders-imported dashboard-orders-label">
                    <div class="dashboard-filter-block">
                      <router-link class="block-link" to="/orders/deliveries"></router-link>
                      <div class="dashboard-filter-oder-flow-label">
                        <span class="dashboard-filter-oder-flow-title">
                          {{ $t('process-flow.page.ordersImported') }}
                        </span>
                        <span class="dashboard-filter-oder-flow-value">
                        <span v-if="!flow || loading">
                          <b-spinner/>
                        </span>
                        <span v-else>
                          {{ flow.orders_imported }}
                        </span>
                      </span>
                      </div>
                    </div>
                  </div>
                  <div class="dashboard-orders-detail">
                    <div class="dashboard-orders-wrapper">
                      <router-link class="block-link" to="/orders/backorders"></router-link>
                      <div class="dashboard-orders-detail-title">
                        {{ $t('backorders') }}
                      </div>
                      <div class="dashboard-orders-detail-info-wrapper">
                        <div class="dashboard-orders-detail-info">
                          <span class="dashboard-orders-detail-info-title">
                            {{ $t('new') }}
                          </span>
                          <span class="dashboard-orders-detail-info-value">
                        <span v-if="!flow || loading">
                          <b-spinner small/>
                        </span>
                        <span v-else>
                          {{ flow.backorders }}
                        </span>
                      </span>
                        </div>
                        <div class="dashboard-orders-detail-info">
                          <span class="dashboard-orders-detail-info-title">
                            {{ $t('total') }}
                          </span>
                          <span class="dashboard-orders-detail-info-value">
                        <span v-if="!flow || loading">
                          <b-spinner small/>
                        </span>
                        <span v-else>
                          {{ flow.backorders_total }}
                        </span>
                      </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-sm-3 px-2 order-flow-block container-fluid"
                  style="cursor: hand;"
                  @click="navigateWithFilter({ path: '/orders/deliveries'})"
                >
                  <div class="dashboard-deliveries dashboard-orders-label">
                    <div class="dashboard-filter-block">
                      <div class="dashboard-filter-oder-flow-label">
                        <span class="dashboard-filter-oder-flow-title">
                          {{ $t('process-flow.page.ordersProcessing') }}
                        </span>
                        <span class="dashboard-filter-oder-flow-value">
                      <span v-if="!flow || loading">
                          <b-spinner/>
                        </span>
                        <span v-else>
                          {{ flow.open_deliveries }}
                        </span>
                    </span>
                      </div>
                    </div>
                  </div>
                  <div class="dashboard-orders-detail dashboard-orders-detail-no-label-no-delivery">
                    <div
                      class="dashboard-orders-wrapper col-sm"
                      style="cursor: pointer;"
                      @click="navigateWithFilter({ path: '/orders/deliveries', query: { filter: 'delivery-issues' } })"
                    >
                      <div class="dashboard-orders-detail-title">{{ $t('issues') }}</div>
                      <div class="dashboard-orders-detail-info-wrapper">
                        <div class="dashboard-orders-detail-info">
                          <span class="dashboard-orders-detail-info-title">
                            {{ $t('process-flow.page.new') }}
                          </span>
                          <span class="dashboard-orders-detail-info-value">
                        <span v-if="!flow || loading">
                          <b-spinner small/>
                        </span>
                        <span v-else>
                          {{ flow.issues_today }}
                        </span>
                      </span>
                        </div>
                        <div class="dashboard-orders-detail-info">
                          <span class="dashboard-orders-detail-info-title">
                            {{ $t('process-flow.page.total') }}
                          </span>
                          <span class="dashboard-orders-detail-info-value">
                          <span v-if="!flow || loading">
                            <b-spinner small/>
                          </span>
                        <span v-else>
                          {{ flow.issues_total }}
                        </span>
                      </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3 px-2 order-flow-block container-fluid">
                  <div class="dashboard-deliveries-with-shipping-label dashboard-orders-label">
                    <div class="dashboard-filter-block">
                      <div class="dashboard-filter-oder-flow-label">
                        <span class="dashboard-filter-oder-flow-title">
                          {{ $t('process-flow.page.ordersDispatched') }}
                        </span>
                        <span class="dashboard-filter-oder-flow-value">
                        <span v-if="!flow || loading">
                          <b-spinner/>
                        </span>
                        <span v-else>
                          {{ flow.shipped }}
                        </span>
                    </span>
                      </div>
                    </div>
                  </div>
                  <div class="dashboard-orders-detail">
                    <div class="dashboard-orders-wrapper">
                      <div class="dashboard-orders-detail-title">
                        {{ $t('process-flow.page.openOrders') }}
                      </div>
                      <div class="dashboard-orders-detail-info-wrapper">
                        <div class="dashboard-orders-detail-info">
                          <span class="dashboard-orders-detail-info-title">
                            {{ $t('process-flow.page.total') }}
                          </span>
                          <span class="dashboard-orders-detail-info-value">
                        <span v-if="!flow || loading">
                          <b-spinner small/>
                        </span>
                        <span v-else>
                          {{ flow.openShipmentsInWarehouse }}
                        </span>
                      </span>
                        </div>
                      </div>
                      <div
                        style="cursor: pointer;"
                        @click="navigateWithFilter({ path: '/orders/deliveries', query: { filter: 'no-progress-in-tracking' } })"
                      >
                        <div class="dashboard-orders-detail-title">
                          {{ $t('process-flow.page.noProgressInTracking') }}
                        </div>
                        <div class="dashboard-orders-detail-info-wrapper">
                          <div class="dashboard-orders-detail-info">
                            <span class="dashboard-orders-detail-info-title">
                              {{ $t('process-flow.page.total') }}
                            </span>
                            <span class="dashboard-orders-detail-info-value">
                              <span v-if="!flow || loading">
                                <b-spinner small/>
                              </span>
                              <span v-else>
                                {{ flow.missing_trackings }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div id="dashboard-bestsellers" class="col-lg-12 col-md-12 col-sm-12 mb-12">
              <div class="widget widget-stats">
                <div>
                  <div class="row">
                    <div class="col-xl-10 col-md-10 mb-3">
                      <b>{{ $t('process-flowv2.performanceOverview') }}</b>
                      <span class="ms-2">
                        <i v-b-popover.hover="$t('popover.performanceOverview')" class="fa fa-info-circle"/>
                      </span>
                    </div>
                    <div id="dropdown-bestsellers-div" class="col-xl-2 col-md-2">
                      <select
                        v-model="performance_overview_filter"
                        class="form-select form-select-lg mb-3"
                      >
                        <option
                          v-for="filter in filters"
                          :key="filter.filter"
                          :value="filter.filter"
                        >
                          {{ filter.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <b-overlay :show="!isChartDataReady">
                    <apexchart
                      :height="areaChart.height"
                      :options="areaChart.options"
                      :series="chartSeries"/>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="dashboard-sales" class="row">
        <div id="dashboard-bestsellers" class="col-lg-4 col-md-4 col-sm-4 mb-4">
          <div class="widget widget-stats">
            <div>
              <div class="row">
                <div class="col-xl-7 col-md-7 mb-3">
                  <b>{{ $t('process-flowv2.topProductsByUnitsSold') }}</b>
                  <span class="ms-2 ">
                    <i v-b-popover.hover="$t('popover.productsMostUnits')" class="fa fa-info-circle"/>
                  </span>
                </div>
                <div id="dropdown-bestsellers-div" class="col-xl-5 col-md-5">
                  <select
                    v-model="bestsellers_filter"
                    class="form-select form-select-lg mb-3"
                  >
                    <option
                      v-for="filter in filters"
                      v-bind:key="filter.filter"
                      :value="filter.filter">
                      {{ filter.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="!bestsellers" class="d-flex justify-content-center">
                <b-spinner/>
              </div>
              <div
                v-for="product in bestsellers"
                v-else
                :key="product.sku"
                class="d-flex align-items-center mb-15px"
              >
                <div class="text-truncate">
                  <div><b>{{ product.sku }}</b></div>
                  <div>{{ product.description }}</div>
                </div>
                <div class="ms-auto text-center">
                  <div>{{ product.ordered }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="dashboard-sales-by-country" class="col-lg-4 col-md-4 col-sm-4 mb-4">
          <div class="widget widget-stats">
            <div>
              <div class="row">
                <div class="col-xl-7 col-md-7 mb-3">
                  <b>{{ $t('process-flowv2.salesByCountries') }}</b>
                  <span class="ms-2 ">
                    <i v-b-popover.hover="$t('popover.salesByCountries')" class="fa fa-info-circle"/>
                  </span>
                </div>
                <div id="dropdown-bestsellers-div" class="col-xl-5 col-md-5">
                  <select
                    v-model="sales_by_country_filter"
                    class="form-select form-select-lg mb-3"
                  >
                    <option
                      v-for="filter in filters"
                      v-bind:key="filter.filter"
                      :value="filter.filter">
                      {{ filter.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="!sales_by_country" class="d-flex justify-content-center">
                <b-spinner/>
              </div>
              <div v-for="product in sales_by_country"
                   v-else
                   :key="product.country_code"
                   class="d-flex align-items-center mb-15px"
              >
                <div class="widget-img rounded-2 me-10px bg-white p-3px">
                  <div
                    id="ad"
                    :class="getFlag(product.country_code)"
                    :title="$t('process-flowv2.ad')"
                  ></div>
                </div>
                <div class="text-truncate">
                  <div>{{ product.counts }}</div>
                  <div>{{ product.country_name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="dashboard-out-of-stock" class="col-lg-4 col-md-4 col-sm-4 mb-4">
          <div class="widget widget-stats">
            <div>
              <div class="row">
                <div class="col-xl-7 col-md-7 mb-3">
                  <b>{{ $t('process-flowv2.outOfStock') }}</b>
                  <span class="ms-2 ">
                    <i v-b-popover.hover="$t('popover.outOfStock')" class="fa fa-info-circle"/>
                  </span>
                </div>
                <div id="dropdown-bestsellers-div" class="col-xl-5 col-md-5">
                  <select
                    v-model="out_of_stock_filter"
                    class="form-select form-select-lg mb-3"
                  >
                    <option
                      v-for="filter in filters"
                      :key="filter.filter"
                      :value="filter.filter"
                    >
                      {{ filter.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="!out_of_stock" class="d-flex justify-content-center">
                <b-spinner/>
              </div>
              <div
                v-for="product in out_of_stock"
                :key="product.sku"
                class="d-flex align-items-center mb-15px"
              >
                <div class="text-truncate">
                  <div><b>{{ product.sku }}</b></div>
                  <div>{{ product.description }}</div>
                </div>
                <div class="ms-auto text-center color-red">
                  <div>{{ product.available_qty }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <notifications group="msg" position="bottom right"/>
    </panel>
  </page-content>
</template>

<script>
import { client } from '@/client/client'
import PageContent from '../../../layout/PageContent'
import { bus } from '@/main'
import AppOptions from '../../../config/AppOptions'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {
  components: {PageContent},
  mounted() {
    this.getDefaultData()

    bus.$on('cpyChanged', (data) => {
      this.request.cpy = data
      this.request.cpy = this.$store.getters.currentCpy
      this.load()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
  },
  data() {
    const date_from = new Date()
    date_from.setDate(date_from.getDate() - 1)
    return {
      loading: false,
      configs: {
        start: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false
        },
        end: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false
        }
      },
      lastUpdate: {},
      flow: null,
      total_orders: {},
      total_orders_filter: '',
      performance_overview: [],
      performance_overview_filter: '',
      bestsellers: null,
      bestsellers_filter: '',
      sales_by_country: null,
      sales_by_country_filter: '',
      out_of_stock: null,
      out_of_stock_filter: '',
      filters: [
        {name: this.$t('today'), filter: 'today'},
        {name: this.$t('yesterday'), filter: 'yesterday'},
        {name: this.$t('lastWeek'), filter: 'last_7'},
        {name: this.$t('last30Days'), filter: 'last_30'},
        {name: this.$t('last6Months'), filter: 'last_6m'},
        {name: this.$t('lastYear'), filter: 'last_year'},
      ],
      overview_filters: [
        {name: this.$t('today'), filter: 'today'},
        {name: this.$t('yesterday'), filter: 'yesterday'},
        {name: this.$t('lastWeek'), filter: 'last_7'},
        {name: this.$t('last30Days'), filter: 'last_30'},
        {name: this.$t('last6Months'), filter: 'last_6m'},
        {name: this.$t('lastYear'), filter: 'last_year'},
        {name: this.$t('custom'), filter: 'custom'}
      ],
      dateFrom: null,
      dateTo: null,
      REQUEST_DATE_FORMAT: 'YYYY-MM-DD',
      request: {
        date_from: date_from,
        date_to: new Date(),
        cpy: this.$store.getters.currentCpy
      },
      isChartDataReady: false,
      categories: [],
      orderSeriesData: [],
      deliveriesSeriesData: [],
      returnsSeriesData: [],
    }
  },
  computed: {
    chartSeries() {
      return [
        {name: 'Orders', data: this.orderSeriesData},
        {name: 'Deliveries', data: this.deliveriesSeriesData},
        {name: 'Returns', data: this.returnsSeriesData}
      ]
    },
    areaChart() {
      return {
        height: 350,
        options: {
          chart: {
            type: 'area',
            toolbar: {
              show: false
            }
          },
          dataLabels: {enabled: true},
          stroke: {curve: 'smooth', width: 3},
          colors: [AppOptions.color.green, AppOptions.color.success, AppOptions.color.red],
          xaxis: {
            labels: {show: true, rotate: -45, rotateAlways: true},
            type: 'category',
            categories: this.categories,
            axisBorder: {
              show: true,
              color: AppOptions.color.gray300,
              height: 1,
              width: '100%',
              offsetX: 0,
              offsetY: -1
            },
            axisTicks: {
              show: true,
              borderType: 'solid',
              color: AppOptions.color.gray300,
              height: 6,
              offsetX: 0,
              offsetY: 0
            }
          },
          tooltip: {
            x: {format: 'dd/MM/yyyy'}
          },
          legend: {
            show: true,
            position: 'top',
            offsetY: 0,
            horizontalAlign: 'left',
            floating: true,
            labels: {
              colors: AppOptions.color.componentColor
            }
          }
        }
      }
    }
  },
  watch: {
    total_orders_filter(newValue) {
      if (newValue !== 'custom') {
        this.getProcessFlowData()
      }
    },
    bestsellers_filter(newValue) {
      if (newValue !== 'custom') {
        this.getBestSellers()
      }
    },
    out_of_stock_filter(newValue) {
      if (newValue !== 'custom') {
        this.getOutOfStock()
      }
    },
    sales_by_country_filter(newValue) {
      if (newValue !== 'custom') {
        this.getSalesByCountry()
      }
    },
    performance_overview_filter(newValue) {
      if (newValue !== 'custom') {
        this.getPerformanceOverview()
      }
    },
  },
  methods: {
    handleFilterButtonClick() {
      if (this.total_orders_filter === 'custom' && (!this.dateFrom || !this.dateTo)) {
        this.$notify({
          group: 'msg',
          type: 'error',
          text: 'Created From and Created To fields must be filled to apply custom date filter',
          duration: 5000
        })
      } else {
        this.getProcessFlowData()
      }
    },
    async getDefaultData() {
      this.activeRequests++
      try {
        const response = await client.auth().me()
        const filterValue = response.data.defaultDateFilter
        this.total_orders_filter = filterValue
        this.bestsellers_filter = filterValue
        this.out_of_stock_filter = filterValue
        this.sales_by_country_filter = filterValue
        this.performance_overview_filter = filterValue

      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    navigateWithFilter(n) {
      this.$router.push(n)
    },
    onStartChange(e) {
      this.dateFrom = e.date
    },
    onEndChange(e) {
      this.dateTo = e.date
    },
    async getProcessFlow() {
      await this.getProcessFlowData()
      await this.getPerformanceOverview()
      await this.getBestSellers()
      await this.getSalesByCountry()
      await this.getOutOfStock()
      this.loading = false
    },
    async getProcessFlowData() {
      try {
        this.loading = true
        this.updateRequestDateRange(this.request, this.total_orders_filter)

        const response = await client.process_flow().all(this.request)
        this.flow = response.data
        this.lastUpdate = response.data.lastUpdate
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.loading = false
      }
    },
    async getPerformanceOverview() {
      this.updateRequestDateRange(this.request, this.performance_overview_filter)
      this.isChartDataReady = false
      client.orders().orders_chart(this.request)
        .then(response => {
          this.performance_overview = response.data
          if (this.performance_overview.length > 0) {
            let categories = []
            let orderSeriesData = []
            let deliveriesSeriesData = []
            let returnsSeriesData = []
            this.performance_overview.forEach((performanceData) => {
              categories.push(performanceData.date)
              orderSeriesData.push(performanceData.orders_provided)
              deliveriesSeriesData.push(performanceData.deliveries)
              returnsSeriesData.push(performanceData.returns)
            })

            this.categories = categories
            this.orderSeriesData = orderSeriesData
            this.deliveriesSeriesData = deliveriesSeriesData
            this.returnsSeriesData = returnsSeriesData
          }
        }).finally(() => {
        this.isChartDataReady = true
      })
    },
    async getBestSellers() {
      this.updateRequestDateRange(this.request, this.bestsellers_filter)
      client.process_flow().pfbestsellers(this.request)
        .then(response => {
          this.bestsellers = response.data
        }).finally(() => {
      })
    },
    async getSalesByCountry() {
      this.updateRequestDateRange(this.request, this.sales_by_country_filter)
      client.process_flow().pfsalesByCountry(this.request)
        .then(response => {
          this.sales_by_country = response.data
        }).finally(() => {
      })
    },
    async getOutOfStock() {
      this.updateRequestDateRange(this.request, this.out_of_stock_filter)
      client.process_flow().pfoutOfStock(this.request)
        .then(response => {
          this.out_of_stock = response.data
        }).finally(() => {
      })
    },
    load() {
      if (!this.$store.getters.user) {
        return
      }
      this.$set(this, 'loading', true)
      this.resetData()
      this.getProcessFlow()
    },
    updateRequestDateRange(request, filter) {
      switch (filter) {
        case 'today':
          request.date_from = new Date()
          request.date_to = new Date()
          break
        case 'yesterday':
          var yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          request.date_from = yesterday
          request.date_to = yesterday
          break
        case 'last_7':
          var last_7 = new Date()
          last_7.setDate(last_7.getDate() - 7)
          request.date_from = last_7
          request.date_to = new Date()
          break
        case 'last_30':
          var last_30 = new Date()
          last_30.setDate(last_30.getDate() - 30)
          request.date_from = last_30
          request.date_to = new Date()
          break
        case 'last_6m':
          var last_6m = new Date()
          last_6m.setMonth(last_6m.getMonth() - 6)
          request.date_from = last_6m
          request.date_to = new Date()
          break
        case 'last_year':
          var last_year = new Date()
          last_year.setFullYear(last_year.getFullYear() - 1)
          request.date_from = last_year
          request.date_to = new Date()
          break
        case 'custom':
          request.date_from = this.dateFrom.format(this.REQUEST_DATE_FORMAT)
          request.date_to = this.dateTo.format(this.REQUEST_DATE_FORMAT)
          break
      }
    },
    getFlag(country_code) {
      return 'flag-icon flag-icon-' + country_code.toLowerCase() + ' h1 rounded mb-0'
    },
    resetData() {
      this.flow = null
      this.bestsellers = null
      this.sales_by_country = null
      this.out_of_stock = null
      this.dateFrom = null
      this.dateTo = null
    }
  }
}
</script>
