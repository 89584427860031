<template>
  <page-content>
    <h1 class="page-header">{{ $t('reports-revenue.page.reportsRevenue') }}</h1>
    <panel
      id="revenue-overview"
      :title="$t('reports-revenue.page.serviceCarrier')"
    >
      <div class="row p-b-15">
        <div class="col-md-11 p-b-15">
          <div class="row">
            <div class="col-md-2">
              <select
                v-model="selectedFilter"
                class="form-select"
              >
                <option
                  v-for="(filter, index) in predefinedFilters"
                  :key="filter.name + index"
                  :value="filter.filter"
                >
                  {{ filter.name }}
                </option>
              </select>
            </div>
            <div class="col-md-2 padding-left-0" style="position: relative">
              <date-picker
                ref="dateFrom"
                v-model="dateFrom"
                :config="configs.start"
                :disabled="selectedFilter !== 'custom'"
                :placeholder="$t('createdFrom')"
                v-on:dp-change="onStartChange"/>
            </div>
            <div class="col-md-2 padding-left-0" style="position: relative">
              <date-picker
                ref="dateTo"
                v-model="dateTo"
                :config="configs.end"
                :disabled="selectedFilter !== 'custom'"
                :placeholder="$t('createdTo')"
                v-on:dp-change="onEndChange"/>
            </div>
            <div class="col-md-2 padding-left-0">
              <b-button
                class="button btn-success margin-right-5"
                @click="handleFilterButtonClick"
              >
                {{ $t('filter') }}
              </b-button>
              <b-button
                class="button btn-warning"
                @click="resetFilters"
              >
                {{ $t('reset') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </panel>
    <panel
      id="invoice-revenue-info-panel"
      :backButtonVisible="false"
      :dataToExport="invoice_revenue.info"
      :downloadData="downloadData"
      :title="$t('reports-revenue.page.invoiceRevenue')"
      @reload="load(null)"
    >
      <reports-revenue
        ref="bestsellers"
        :data="invoice_revenue.info"
        :dateFrom="dateFrom"
        :isLoading="isLoading"
        :request="request"
        @openModal="openModal($event)"
        @reload="load"
      />
    </panel>
    <b-modal
      ref="detailsModal"
      v-model="detailsModal"
      centered
      hide-footer
      hide-header
      size="xl"
      title="Revenue Details"
    >
      <div class="d-flex justify-content-end align-items-center">
        <b-button
          class="close"
          style="padding: 0.1rem 0.4rem; !important;"
          @click="detailsModal = false"
        >
          <span aria-hidden="true">&times;</span>
        </b-button>
      </div>
      <h1 class="page-header">
        {{ $t('reports-revenue-detail.page.reportsRevenueDetail', {country: country}) }}
      </h1>
      <reports-revenue-detail
        ref="bestsellers"
        :data="invoice_revenue_details.detail"
        :isLoading="isLoading"
        style="min-height: 300px; !important;"
      />
    </b-modal>
    <notifications group="msg" position="bottom right"/>
  </page-content>
</template>

<script>
import { client } from '@/client/client'
import PageContent from '../../../layout/PageContent'
import AppOptions from '../../../config/AppOptions.vue'
import ReportsRevenue from '@/modules/reports-revenue/components/ReportsRevenue'
import { bus } from '@/main'
import ReportsRevenueDetail from '@/modules/reports-revenue/components/ReportsRevenueDetail.vue'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {
  components: {ReportsRevenueDetail, PageContent, ReportsRevenue},
  data() {
    return {
      activeRequests: 0,
      detailsModal: false,
      invoice_revenue_details: {},
      country: '',
      lineChart: {
        options: {
          chart: {
            type: 'line',
            height: 550,
            shadow: {
              enabled: true,
              color: 'rgba(' + AppOptions.color.componentColorRgb + ', .5)',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 1,
            },
            toolbar: {
              show: true,
            },
          },
          title: {
            text: 'Service Carrier Quality',
            align: 'center',
            style: {
              color: AppOptions.color.componentColor,
            },
          },
          colors: [
            AppOptions.color.blue,
            AppOptions.color.indigo,
            AppOptions.color.red,
            AppOptions.color.green,
            AppOptions.color.lime,
            AppOptions.color.orange,
            AppOptions.color.pink,
            AppOptions.color.success,
          ],
          dataLabels: {
            enabled: true,
            background: {
              borderWidth: 0,
            },
          },
          stroke: {
            curve: 'smooth',
            width: 3,
          },
          grid: {
            row: {
              colors: [
                'rgba(' + AppOptions.color.componentColorRgb + ', .05)',
                'transparent',
              ],
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: [],
          },
          legend: {
            show: true,
            position: 'top',
            offsetY: -10,
            horizontalAlign: 'right',
            floating: true,
            labels: {
              colors: AppOptions.color.componentColor,
            },
          },
        },
        series: [],
      },
      dataLoaded: false,
      REQUEST_DATE_FORMAT: 'YYYY-MM-DD',
      dateFrom: null,
      dateTo: null,
      configs: {
        start: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
        },
        end: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
        },
      },
      selectedFilter: '',
      predefinedFilters: [
        {name: this.$t('today'), filter: 'today'},
        {name: this.$t('yesterday'), filter: 'yesterday'},
        {name: this.$t('lastWeek'), filter: 'last_7'},
        {name: this.$t('last30Days'), filter: 'last_30'},
        {name: this.$t('last6Months'), filter: 'last_6m'},
        {name: this.$t('lastYear'), filter: 'last_year'},
        {name: this.$t('custom'), filter: 'custom'},
      ],
      selectedDataFilter: {
        name: 'Shipped from MH to delivered scan - with sunday',
        filter: 'daily-frommh-allday-endtoend',
      },
      invoice_revenue: {},
      invoice_revenuex: {},
      isLoading: false,
      request: {
        cpy: this.$store.getters.currentCpy,
        per_page: 20,
        page: 1,
        date_from: null,
        date_to: null,
        order_column: 'day',
        order_dir: 'DESC',
        filters: {
          cpy: this.$store.getters.currentCpy
        },
      },
    }
  },
  async mounted() {
    await this.getDefaultData()
    bus.$on('cpyChanged', (data) => {
      this.request.cpy = data
      this.load()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
    const modal = this.$refs.detailsModal
    if (modal) {
      modal.$off('ok')
    }
  },
  watch: {
    activeRequests(newValue) {
      this.isLoading = newValue > 0
    },
    selectedFilter(newValue) {
      if (newValue !== 'custom') {
        this.applyDateFilter()
      }
    }
  },
  methods: {
    async getDefaultData() {
      this.activeRequests++
      try {
        const response = await client.auth().me()
        this.selectedFilter = response.data.defaultDateFilter

      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    async openModal(event) {
      this.country = event
      await this.getReportRevenueDetail()
      this.detailsModal = true
    },
    async getReportRevenueDetail() {
      try {
        this.activeRequests++

        const response = await client.reports_revenue_detail().all({
          per_page: 20,
          page: 1,
          date_from: this.request.date_from,
          date_to: this.request.date_to,
          order_column: 'invoicenr',
          order_dir: 'DESC',
          filters: {country: this.country},
          cpy: this.$store.getters.currentCpy,
        })

        this.invoice_revenue_details = response.data
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    async downloadData() {
      if (!this.isLoading) {
        await this.download()
        return this.invoice_revenuex.data
      }
    },
    async download() {
      this.activeRequests++
      await client
        .orders()
        .all({
          per_page: 5000,
          page: 1,
          date_from: this.request.date_from,
          date_to: this.request.date_to,
          order_column: this.request.order_column,
          order_dir: this.request.order_dir,
          filters: this.request.filters,
          predefined: this.request.predefined,
        })
        .then((response) => {
          this.invoice_revenuex = response.data
        })
        .finally(() => {
          this.activeRequests--
        })
    },
    async getRevenueReport() {
      try {
        this.activeRequests++
        const response = await client.reports_revenue().all(this.request)
        this.invoice_revenue = response.data
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
        this.dataLoaded = true
      }
    },
    load(event) {
      if (event) {
        this.request.per_page = event.currentPerPage
        this.request.page = event.currentPage
      }
      this.getRevenueReport()
    },
    resetFilters() {
      this.dateFrom = null
      this.dateTo = null
      this.request.predefined = 'daily-frommh-allday-dispdepo'
      this.request.date_from = null
      this.request.date_to = null
      this.selectedDataFilter = {
        name: 'Shipped from MH to delivered scan - with sunday',
        filter: 'daily-frommh-allday-endtoend',
      }
      this.request.order_column = 'day'
      this.request.order_dir = 'DESC'
      this.request.filters = {cpy: this.$store.getters.currentCpy}
      this.selectedFilter = this.$store.getters.userDefaultDateFilter
    },
    applyDateFilter() {
      switch (this.selectedFilter) {
        case 'today':
          this.request.date_from = new Date()
          this.request.date_to = new Date()
          break
        case 'yesterday':
          var yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          this.request.date_from = yesterday
          this.request.date_to = yesterday
          break
        case 'last_7':
          var last_7 = new Date()
          last_7.setDate(last_7.getDate() - 7)
          this.request.date_from = last_7
          this.request.date_to = new Date()
          break
        case 'last_30':
          var last_30 = new Date()
          last_30.setDate(last_30.getDate() - 30)
          this.request.date_from = last_30
          this.request.date_to = new Date()
          break
        case 'last_6m':
          var last_6m = new Date()
          last_6m.setMonth(last_6m.getMonth() - 6)
          this.request.date_from = last_6m
          this.request.date_to = new Date()
          break
        case 'last_year':
          var last_year = new Date()
          last_year.setFullYear(last_year.getFullYear() - 1)
          this.request.date_from = last_year
          this.request.date_to = new Date()
          break
        case 'custom':
          this.request.date_from = this.dateFrom.format(
            this.REQUEST_DATE_FORMAT
          )
          this.request.date_to = this.dateTo.format(this.REQUEST_DATE_FORMAT)
          break
      }
      this.request.predefined = this.selectedDataFilter.filter
      this.getRevenueReport()
    },
    handleFilterButtonClick() {
      if (this.selectedFilter === 'custom' && (!this.dateFrom || !this.dateTo)) {
        this.$notify({
          group: 'msg',
          type: 'error',
          text: 'Created From and Created To fields must be filled to apply custom date filter',
          duration: 5000
        })
      } else {
        this.applyDateFilter()
      }
    },
    onStartChange(e) {
      this.dateFrom = e.date
    },
    onEndChange(e) {
      this.dateTo = e.date
    },
  },
}
</script>
