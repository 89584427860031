<template>
  <page-content>
    <h1 class="page-header">{{ $t('reports-timeofgoodsreceipt.page.reportsServiceQualityReturns') }}</h1>
    <panel
      id="service-quality-returns"
      :title="$t('reports-timeofgoodsreceipt.page.serviceQualityReturns')"
    >
      <div class="row p-b-15">
        <div class="col-md-7 p-b-15">
          <div class="row">
            <div class="col-md-3">
              <select
                v-model="selectedFilter"
                class="form-select"
              >
                <option
                  v-for="(filter, index) in predefinedFilters"
                  :key="filter.name + index"
                  :value="filter.filter"
                >
                  {{ filter.name }}
                </option>
              </select>
            </div>
            <div class="col-md-3 padding-left-0" style="position: relative;">
              <date-picker
                ref="dateFrom"
                v-model="dateFrom"
                :config="configs.start"
                :disabled="selectedFilter !== 'custom'"
                :placeholder="$t('createdFrom')"
                v-on:dp-change="onStartChange"/>
            </div>
            <div class="col-md-3 padding-left-0" style="position: relative;">
              <date-picker
                ref="dateTo"
                v-model="dateTo"
                :config="configs.end"
                :disabled="selectedFilter !== 'custom'"
                :placeholder="$t('createdTo')"
                v-on:dp-change="onEndChange"/>
            </div>
            <div class="col-md-3 padding-left-0">
              <b-button
                class="button btn-success margin-right-5"
                @click="handleFilterButtonClick"
              >
                {{ $t('filter') }}
              </b-button>
              <b-button
                class="button btn-warning"
                @click="resetFilters"
              >
                {{ $t('reset') }}
              </b-button>
            </div>
          </div>
        </div>
        <div v-if="dataLoaded && !isLoading" class="row">
          <div class="col-md-10">
            <apexchart
              ref="realtimeChart"
              :height="lineChart.options.chart.height"
              :options="lineChart.options"
              :series="lineChart.series"
              type="line"/>
          </div>
        </div>
        <div v-if="isLoading" class="vgt-loading vgt-center-align position-static">
          <slot name="loadingContent">
            <span class="vgt-loading__content">Loading...</span>
          </slot>
        </div>
      </div>
    </panel>
    <notifications group="msg" position="bottom right"/>
  </page-content>
</template>

<script>
import { client } from '@/client/client'
import PageContent from '../../../layout/PageContent'
import AppOptions from '../../../config/AppOptions.vue'
import { bus } from '@/main'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {
  components: {PageContent},
  data() {
    return {
      activeRequests: 0,
      lineChart: {
        options: {
          chart: {
            type: 'line',
            height: 550,
            shadow: {
              enabled: true,
              color: 'rgba(' + AppOptions.color.componentColorRgb + ', .5)',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 1
            },
            toolbar: {
              show: true
            }
          },
          title: {
            text: this.$t('service-quality.page.timesOfGoods'),
            align: 'center',
            style: {
              color: AppOptions.color.componentColor
            }
          },
          colors: [AppOptions.color.blue],
          dataLabels: {
            enabled: true,
            background: {
              borderWidth: 0
            }
          },
          stroke: {
            curve: 'smooth',
            width: 3
          },
          grid: {
            row: {colors: ['rgba(' + AppOptions.color.componentColorRgb + ', .05)', 'transparent'], opacity: 0.5}
          },
          xaxis: {
            categories: [],
          },
          legend: {
            show: true,
            position: 'top',
            offsetY: -10,
            horizontalAlign: 'right',
            floating: true,
            labels: {
              colors: AppOptions.color.componentColor
            }
          }
        },
        series: []
      },
      dataLoaded: false,
      REQUEST_DATE_FORMAT: 'YYYY-MM-DD',
      dateFrom: null,
      dateTo: null,
      configs: {
        start: {
          format: 'YYYY.MM.DD',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false
        },
        end: {
          format: 'YYYY.MM.DD',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false
        }
      },
      selectedFilter: '',
      predefinedFilters: [
        {name: this.$t('today'), filter: 'today'},
        {name: this.$t('yesterday'), filter: 'yesterday'},
        {name: this.$t('lastWeek'), filter: 'last_7'},
        {name: this.$t('last30Days'), filter: 'last_30'},
        {name: this.$t('last6Months'), filter: 'last_6m'},
        {name: this.$t('lastYear'), filter: 'last_year'},
        {name: this.$t('custom'), filter: 'custom'}
      ],
      returns_quality: {},
      isLoading: false,
      request: {
        cpy: this.$store.getters.currentCpy,
        date_from: null,
        date_to: null,
        filters: {
          cpy: this.$store.getters.currentCpy,
        },
        predefined: ''
      }
    }
  },
  async mounted() {
    await this.getDefaultData()
    bus.$on('cpyChanged', (data) => {
      this.request.cpy = data
      this.load()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
  },
  watch: {
    activeRequests(newValue) {
      this.isLoading = newValue > 0
    },
    selectedFilter(newValue) {
      if (newValue !== 'custom') {
        this.applyDateFilter()
      }
    }
  },
  methods: {
    async getDefaultData() {
      this.activeRequests++
      try {
        const response = await client.auth().me()
        this.selectedFilter = response.data.defaultDateFilter

      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    async getReportsTimeofgoodsreceipt() {
      this.activeRequests++
      try {
        const response = await client.reports_timeofgoodsreceipt().all(this.request)
        this.isLoading = true

        this.returns_quality = response.data
        let categories = []
        let seriesData = []
        this.lineChart.series = []
        for (let q of this.returns_quality.data) {
          categories.push(q.created_at)
          seriesData.push(q.avg_time_to_first_booking_in_hours)

          //this.updateSeriesLine();
        }
        //seriesData = [3, 1, 1, 2, 3, 1, 2, 1, 2, 6, 6, 3];
        //categories = ['202201', '202202', '202203', '202204', '202205', '202206', '202207', '202208', '202209', '202210', '202211', '202212'];
        this.lineChart.options.xaxis.categories = categories
        // this.lineChart.series = [];
        //this.series = seriesData;
        this.lineChart.series.push({name: this.$t('service-quality.page.returnsInHour'), data: seriesData})
        window.dispatchEvent(new Event('resize'))
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
        this.dataLoaded = true
      }
    },
    load(event) {
      if (event) {
        this.request.per_page = event.currentPerPage
        this.request.page = event.currentPage
      }
      this.getReportsTimeofgoodsreceipt()
    },
    resetFilters() {
      this.dateFrom = null
      this.dateTo = null
      this.request.predefined = ''
      this.request.date_from = null
      this.request.date_to = null
      this.request.filters = {cpy: this.$store.getters.currentCpy}
      this.selectedFilter = this.$store.getters.userDefaultDateFilter
    },
    applyDateFilter() {
      this.request.predefined = this.selectedFilter
      this.request.filters.cpy = this.$store.getters.currentCpy
      this.request.cpy = this.$store.getters.currentCpy
      switch (this.selectedFilter) {
        case 'today':
          this.request.date_from = new Date()
          this.request.date_to = new Date()
          break
        case 'yesterday':
          var yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          this.request.date_from = yesterday
          this.request.date_to = yesterday
          break
        case 'last_7':
          var last_7 = new Date()
          last_7.setDate(last_7.getDate() - 7)
          this.request.date_from = last_7
          this.request.date_to = new Date()
          break
        case 'last_30':
          var last_30 = new Date()
          last_30.setDate(last_30.getDate() - 30)
          this.request.date_from = last_30
          this.request.date_to = new Date()
          break
        case 'last_6m':
          var last_6m = new Date()
          last_6m.setMonth(last_6m.getMonth() - 6)
          this.request.date_from = last_6m
          this.request.date_to = new Date()
          break
        case 'last_year':
          var last_year = new Date()
          last_year.setFullYear(last_year.getFullYear() - 1)
          this.request.date_from = last_year
          this.request.date_to = new Date()
          break
        case 'custom':
          this.request.date_from = this.dateFrom.format(this.REQUEST_DATE_FORMAT)
          this.request.date_to = this.dateTo.format(this.REQUEST_DATE_FORMAT)
          break
      }
      this.getReportsTimeofgoodsreceipt()
    },
    handleFilterButtonClick() {
      if (this.selectedFilter === 'custom' && (!this.dateFrom || !this.dateTo)) {
        this.$notify({
          group: 'msg',
          type: 'error',
          text: 'Created From and Created To fields must be filled to apply custom date filter',
          duration: 5000
        })
      } else {
        this.applyDateFilter()
      }
    },
    onStartChange(e) {
      this.dateFrom = e.date
    },
    onEndChange(e) {
      this.dateTo = e.date
    }
  }
}
</script>
