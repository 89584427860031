<template>
  <b-card :title="$t('changePassword')">
    <b-form @submit.prevent="submitChangePassword">
      <b-form-group>
        <b-form-input
          v-model="$v.changePassword.oldPassword.$model"
          :placeholder="$t('oldPassword')"
          :state="validateState('changePassword.oldPassword')"
          class="mt-2"
          type="password"
        />
        <b-form-input
          v-model="$v.changePassword.newPassword.$model"
          :placeholder="$t('newPassword')"
          :state="validateState('changePassword.newPassword')"
          class="mt-2"
          type="password"/>
        <b-button
          :disabled="loading"
          class="mt-2"
          type="submit"
          variant="primary"
        >
          <b-spinner v-if="loading" small/>
          {{ loading ? 'Loading...' : $t('changePassword') }}
        </b-button>
      </b-form-group>
    </b-form>
  </b-card>
</template>

<script>
import { client } from '@/client/client'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ChangePasswordForm',
  data() {
    return {
      loading: false,
      changePassword: {
        oldPassword: '',
        newPassword: '',
      }
    }
  },
  validations: {
    changePassword: {
      oldPassword: {
        required
      },
      newPassword: {
        required
      }
    }
  },
  methods: {
    getDescendantProp(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj)
    },
    validateState(name) {
      const {$dirty, $error} = this.getDescendantProp(this.$v, name)
      return $dirty ? !$error : null
    },
    async submitChangePassword() {
      this.loading = true
      try {
        await client.profile().change_password(this.changePassword.oldPassword, this.changePassword.newPassword)

        this.$swal.fire({
          icon: 'success',
          title: 'Password changed',
          text: 'Your password has been changed successfully',
          showConfirmButton: false,
          timer: 1500
        })

        this.$v.changePassword.$reset()
      } catch (error) {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message,
          showConfirmButton: false,
          timer: 1500
        })
      } finally {
        this.loading = false
      }
    },
  }
}
</script>



