<template>
  <div>
    <div class="d-flex justify-content-between align-items-center px-2">
      <h1 class="page-header">
        {{ $t('orderNumber', {order_id: order_id}) }}{{ delivery_id ? ':' : '' }}
        <span v-if="delivery_id" style="font-weight: 400 !important;">{{ delivery_id }}</span>
      </h1>
      <a
        v-if="invoice_revenue.data"
        v-b-tooltip.hover href="#"
        title="Order cancellation"
        @click="onCancel"
      >
        <i class="fa fa-cancel"/>
        <span class="px-1">Cancel Order</span>
      </a>
    </div>
    <cancel-order-form
      v-if="order_cancel_order"
      :cpy="$props.cpy"
      :order="order_cancel_order"
      :show-details="false"
      class="mb-4 mx-2"/>

    <order-delivery-details
      :data="invoice_revenue.data"
      :isLoading="isLoading"
      @reload="getOrderDetails"
    />
  </div>
</template>

<script>
import { client } from '@/client/client'
import OrderDeliveryDetails from '@/modules/orders/components/OrderDeliveryDetails.vue'
import CancelOrderForm from '@/modules/support/components/CancelOrderForm.vue'

export default {
  props: ['order_id', 'cpy', 'delivery_id'],
  components: {CancelOrderForm, OrderDeliveryDetails},
  async mounted() {
    await this.getOrderDetails()
  },
  data() {
    return {
      dataLoaded: false,
      invoice_revenue: {},
      isLoading: false,
      request: {
        per_page: 20,
        page: 1,
        order_column: 'invoicenr',
        order_dir: 'DESC',
        filters: {
          cpy: this.$props.cpy,
          order_id: this.order_id,
          delivery_id: this.delivery_id
        },
        cpy: this.$store.getters.currentCpy,
      },
      order_cancel_order: null,
    }
  },
  methods: {
    async getOrderDetails() {
      try {
        this.isLoading = true
        this.title = 'Loading...'

        const response = await client.order_delivery_detail().all(this.request)
        this.invoice_revenue = response.data
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.isLoading = false
        this.dataLoaded = true
        this.title = ''
      }
    },
    async onCancel() {
      const result = await this.$swal.fire({
        title: 'Are you sure you want to cancel the order?',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      })

      if (result.isConfirmed) {
        try {
          this.order_cancel_order = await client.change_address().order_get({
            id: this.invoice_revenue.data[0].order_id,
            cpy: this.$props.cpy
          })
        } catch (error) {
          console.error('Error:', error)
        }
      }
    },
  }
}
</script>
