var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{ref:"returns",attrs:{"columns":_vm.columns,"isLoading":_vm.isLoading,"lineNumbers":false,"mode":'remote',"paginationOptions":{
          enabled: true,
          position: 'bottom',
          perPage: 20,
          perPageDropdown: null,
          perPageDropdownEnabled: true,
          dropdownAllowAll: false,
          mode: 'records',
          infoFn: null,
          jumpFirstOrLast : true
        },"responsive":true,"rows":_vm.data.data,"search-options":{ enabled: false, placeholder: 'Search' },"selectOptions":{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				},"sort-options":{
          enabled: true,
          initialSortBy: {field: 'return_created_at', type: 'desc'}
        },"total-rows":_vm.data.total},on:{"on-page-change":_vm.load,"on-per-page-change":_vm.load,"on-column-filter":_vm.filterChanged,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'refunds')?_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(props.row.refunds),expression:"props.row.refunds"}],staticClass:"form-check-input",attrs:{"id":props.row.return_id,"type":"checkbox"},domProps:{"checked":_vm.isRefundsChecked(props.row.refunds),"checked":Array.isArray(props.row.refunds)?_vm._i(props.row.refunds,null)>-1:(props.row.refunds)},on:{"change":[function($event){var $$a=props.row.refunds,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(props.row, "refunds", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(props.row, "refunds", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(props.row, "refunds", $$c)}},function($event){return _vm.updateRefunds(props.row, $event.target.checked)}]}})]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }