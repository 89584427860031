<template>
  <div>
    <vue-good-table
        ref="labelErrros"
        :columns="columns"
        :rows="data.data"
        :total-rows="data.total"
        :isLoading="isLoading"
        :responsive="true"
        @on-page-change="load"
        @on-per-page-change="load"
        @on-column-filter="filterChanged"
        @on-sort-change="onSortChange"
        :mode="'remote'"
        :lineNumbers="false"
        :search-options="{ enabled: false, placeholder: 'Search' }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'entrytime', type: 'desc'}
        }"
        :selectOptions="{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				}"
        :paginationOptions="{
          enabled: true,
          position: 'bottom',
          perPage: 20,
          perPageDropdown: null,
          perPageDropdownEnabled: true,
          dropdownAllowAll: false,
          mode: 'records', // or pages
          infoFn: null,
          jumpFirstOrLast : true
        }"
    >
    </vue-good-table>
  </div>
</template>

<script>

export default {
  name: 'SkuRevenue',
  components: {},
  props: ['data', 'isLoading'],
  methods: {
    load: function(event) {
      this.$emit('reload', event);
      //console.log(event);
    },
    onSortChange: function (event) {
      this.$emit('sort', event);
    },
    filterChanged: function (event) {
      this.$emit('filter', event);
    },
    filterButton(type) {
      this.$emit('filter-button', type);
    },
    reset() {
      this.$refs.skuRevenueData.reset();
    }
  },
  data() {
    return {
      columns: [
      {
        label: 'CPY',
        field: 'cpy',
        width: '5%',
        thClass: 'text-center text-nowrap',
        tdClass: 'text-center text-nowrap',
        filterOptions: {
          enabled: true,
          placeholder: "CPY"
        }
      },
      {
        label: 'Order id',
        field: 'orderid',
        width: '15%',
        thClass: 'text-center text-nowrap',
        tdClass: 'text-center text-nowrap',
        filterOptions: {
          enabled: true,
          placeholder: "Order id"
        }
      },
      {
          label: 'REFID',
          field: 'refid',
          width: '10%',
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: "REFID" 
          }
        },{
          label: 'SEVERITY',
          field: 'severity',
          width: '10%',
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: "SEVERITY" 
          }
        },{
          label: 'TOPIC',
          field: 'topic',
          width: '10%',
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: "TOPIC" 
          }
        }
      , {
        label: 'ERRORTEXT',
        field: 'message',
        width: '80%',
        tdClass: 'text-left text-wrap',
        thClass: 'text-left text-nowrap',
        filterOptions: {
            enabled: true,
            placeholder: "ERRORTEXT" 
          }        
      },
      {
        label: 'SOURCE',
        field: 'source',
        width: '10%',
        tdClass: 'text-left text-wrap',
        thClass: 'text-left text-nowrap',
        filterOptions: {
            enabled: true,
            placeholder: "SOURCE" 
          }        
      },
      {
          label: 'ENTRYTIME',
          field: 'entrytime',
          width: '10%',
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: "ENTRYTIME"
          }
        }
      ]
    }
  },
}
</script>

<style scoped>
  .refunded {
    color: #18c471;
  }
</style>
