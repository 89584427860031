<template>
  <div>
    <vue-good-table
      ref="stock"
      :columns="columns"
      :isLoading="isLoading"
      :lineNumbers="false"
      :mode="'remote'"
      :paginationOptions="tablePaginationOptions"
      :responsive="true"
      :rows="data.data"
      :search-options="{ enabled: false, placeholder: 'Search' }"
      :selectOptions="tableSelectOptions"
      :sort-options="tableSortOptions"
      :total-rows="data.total"
      @on-page-change="load"
      @on-per-page-change="load"
      @on-column-filter="filterChanged"
      @on-sort-change="onSortChange"
    >
    </vue-good-table>
  </div>
</template>

<script>

export default {
  name: 'Stock',
  props: ['data', 'isLoading'],
  data() {
    return {
      columns: [
        {
          label: 'SKU',
          field: 'sku',
          width: '15%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SKU'
          }
        }, {
          label: 'ITEM',
          field: 'item_description',
          width: '20%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ITEM',
          }
        }, {
          label: 'STOFCY',
          field: 'sstofcy',
          width: '15%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'STOFCY'
          }
        }, {
          label: 'CPY',
          field: 'cpy',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: 'CPY'
          }
        }, {
          label: 'COMPANY',
          field: 'company',
          width: '15%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'COMPANY'
          }
        }, {
          label: 'QTY',
          field: 'qty',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'QTY'
          }
        }, {
          label: 'DATE',
          field: 'date',
          type: 'date',
          dateInputFormat: 'yyyyy-MM-dd HH:mm:ss.S',
          dateOutputFormat: 'dd.MM.yyyy',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap'
        }
      ],
      tablePaginationOptions: {
        enabled: true,
        position: 'bottom',
        perPage: 20,
        perPageDropdown: null,
        perPageDropdownEnabled: true,
        dropdownAllowAll: false,
        mode: 'records',
        infoFn: null,
        jumpFirstOrLast: true
      },
      tableSelectOptions: {
        enabled: false,
        selectOnCheckboxOnly: false,
        selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
      },
      tableSortOptions: {
        enabled: true,
        initialSortBy: {field: 'sku', type: 'desc'}
      },
    }
  },
  methods: {
    load(event) {
      this.$emit('reload', event)
    },
    onSortChange(event) {
      this.$emit('sort', event)
    },
    filterChanged(event) {
      this.$emit('filter', event)
    },
    reset() {
      this.$refs.stock.reset()
    }
  },
}
</script>


