import axios from "axios";
import {dashboardClientConfig} from "../config";
import { requestManager } from '@/assets/requestManager.js';
let apiRoot = dashboardClientConfig.apiRoot;
let rootUrl = apiRoot + "/dashboard-service";

export function dashboard_service() {
    return {
        get_comment: (obj) => axios.post(rootUrl+ "/comment/get", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          })
        /*all: (obj) => axios.post(ordersRootUrl, obj),
        total_orders: (obj) => axios.post(ordersRootUrl + "/total", obj),
        shipped_orders: (obj) => axios.post(ordersRootUrl + "/shipped", obj),
        total_backorders: (obj) => axios.post(ordersRootUrl + "/back", obj),
        total_returns: (obj) => axios.post(ordersRootUrl + "/returns", obj),
        orders_chart: (obj) => axios.post(ordersRootUrl + "/chart", obj),*/
    }
}
 