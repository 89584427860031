<template>
  <page-content>
    <h1 class="page-header">{{ $t('orderNumber') }}</h1>
    <panel
      id="order-details-panel"
      :backButtonVisible="true"
      :resizeable="true"
      @reload="load(null)"
    >
      <order-delivery-details
        ref="orderDetails"
        :data="invoice_revenue.data"
        :isLoading="isLoading"
        @reload="load"/>
    </panel>
  </page-content>
</template>
<script>
import { client } from '@/client/client'
import PageContent from '../../../layout/PageContent'
import OrderDeliveryDetails from '@/modules/orders/components/OrderDeliveryDetails.vue'
import { bus } from '@/main'

export default {
  props: ['data'],
  components: {PageContent, OrderDeliveryDetails},
  data() {
    return {
      showHoldReleaseModal: true,
      dataLoaded: false,
      invoice_revenue: {},
      isLoading: false,
      title: '',
      request: {
        per_page: 20,
        page: 1,
        date_from: null,
        date_to: null,
        order_column: 'invoicenr',
        order_dir: 'DESC',
        filters: {
          cpy: this.$store.getters.currentCpy,
          order_id: null,
          delivery_id: null,
        },
        cpy: this.$store.getters.currentCpy,
      }
    }
  },
  created() {
    this.request.date_from = this.$route.query.dateFrom
    this.request.date_to = this.$route.query.dateTo
    this.request.filters.delivery_id = this.$route.query.delivery_id
    this.request.filters.order_id = this.$route.query.order_id
  },
  mounted() {
    this.load()
    this.$store.watch((state, getters) => getters.userId, this.load)
    this.request.cpy = this.$store.getters.currentCpy

    bus.$on('cpyChanged', (data) => {
      this.request.cpy = data
      this.load()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
  },
  methods: {
    getOrderDetail() {
      this.isLoading = true
      this.title = 'Loading...'
      client.order_delivery_detail().all(this.request)
        .then(response => {
          this.isLoading = true
          this.invoice_revenue = response.data
        }).finally(() => {
        this.isLoading = false
        this.dataLoaded = true
        this.title = 'Done'
      })
    },
    load(event) {
      if (event) {
        this.request.per_page = event.currentPerPage
        this.request.page = event.currentPage
      }
      this.getOrderDetail()
    },
  }
}
</script>
