<template>
  <page-content v-if="hasPermission('CAN_READ_ROLE')">
    <h1 class="page-header">{{ $t('roles.accessRoles') }}</h1>
    <panel
      id="roles-panel"
      :resizeable="true"
      :title="$t('roles')"
      @reload="load"
    >
      <div v-if="loading" class="vgt-loading vgt-center-align position-static">
        <slot name="loadingContent">
          <span class="vgt-loading__content">Loading...</span>
        </slot>
      </div>
      <roles
        v-else
        ref="roles"
        :data="roles"
        :permissions="permissions"
        @created="createRole"
        @deleted="deleteRole"
        @updated="updateRole"/>
    </panel>
    <notifications group="msg" position="bottom right"/>
  </page-content>
</template>

<script>
import PageContent from '../../../layout/PageContent'
import { client } from '@/client/client'
import Roles from '../components/Roles'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {
  components: {PageContent, Roles},
  data() {
    return {
      loading: false,
      roles: [],
      permissions: []
    }
  },
  async mounted() {
    await this.load()
    this.$store.watch((state, getters) => getters.userId, this.load)
  },
  methods: {
    async load() {
      try {
        this.loading = true
        const rolesResponse = await client.roles().all()
        this.roles = rolesResponse.data

        const permissionsResponse = await client.roles().permissions()
        this.permissions = permissionsResponse.data
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.loading = false
      }
    },
    async createRole(role) {
      try {
        await client.roles().create(role)
        this.$notify({
          group: 'msg',
          type: 'success',
          text: 'New role created successfully',
          duration: 5000
        })
        this.onSuccess()
      } catch (error) {
        console.error('Error:', error)
      }
    },
    async updateRole(role) {
      try {
        await client.role(role.id).update(role)
        this.$notify({
          group: 'msg',
          type: 'success',
          text: 'Role updated successfully',
          duration: 5000
        })
        this.onSuccess()
      } catch (error) {
        console.error('Error:', error)
      }
    },
    async deleteRole(roleId) {
      try {
        await client.role(roleId).delete()
        this.$notify({
          group: 'msg',
          type: 'success',
          text: 'Role deleted successfully',
          duration: 5000
        })
        this.onSuccess()
      } catch (error) {
        console.error('Error:', error)
      }
    },
    onSuccess() {
      this.$refs.roles.closeModal()
      this.load()
    }
  }
}
</script>
