<template>
  <!-- begin #footer -->
  <div id="footer" class="footer"
       v-bind:style="{'margin-left': appOptions.appSidebarMinified ? '60px' : '260px'}">
    {{ $t('footerCopyright', {year: year}) }}
  </div>
  <!-- end #footer -->
</template>

<style>
.footer {
  position: fixed;
  bottom: 0;
  right: 5px;
  margin-top: 50px;
  margin-right: 15px;
  width: calc(100% - 300px);
  padding: 0.75rem 0.9375rem;
  background-color: white;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  border-radius: 0.375rem;
  color: black;
  font-size: 0.9rem;
}

</style>

<script>
import AppOptions from '../../config/AppOptions.vue'

export default {
  name: 'Footer',
  data() {
    return {
      appOptions: AppOptions,
      year: new Date().getFullYear()
    }
  }
}
</script>
