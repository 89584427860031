import axios from "axios";
import {dashboardClientConfig} from "../config";

let apiRoot = dashboardClientConfig.apiRoot;
let rootUrl = apiRoot + "/packaging-materials";
import { requestManager } from '@/assets/requestManager.js';

export function reports_packaging() {
    return {
        all: (obj) => axios.post(rootUrl, obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        chart: (obj) => axios.post(rootUrl + '-chart', obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel });
            })
        })
    }
}

