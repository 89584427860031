<script>

import i18n from '../../i18n'

const sidebarMenu = [
  {
    path: '/dashboard',
    icon: 'fa fa-chalkboard-user',
    title: i18n.t('sidebar.menu.dashboard'),
    label: '',
    permissions: ['CAN_READ_PROCESS_FLOW']
  },
  {
    path: '/orders',
    icon: 'fa fa-cart-shopping',
    title: i18n.t('sidebar.menu.orders'),
    label: '',
    permissions: ['CAN_READ_ORDERS', 'CAN_READ_RETURNS', 'CAN_READ_BACKORDERS'],
    children: [
      {path: '/orders/deliveries', title: i18n.t('sidebar.menu.orders'), permissions: ['CAN_READ_ORDERS']},
      {path: '/orders/returns', title: i18n.t('sidebar.menu.returns'), permissions: ['CAN_READ_RETURNS']},
      {path: '/orders/backorders', title: i18n.t('sidebar.menu.backorders'), permissions: ['CAN_READ_BACKORDERS']},
      {
        path: '/orders/order-cancel',
        title: i18n.t('sidebar.menu.cancelOrder'),
        permissions: ['CAN_READ_CARRIER_QUALITY']
      },
      {path: '/orders/order-create', title: i18n.t('sidebar.menu.createOrder'), permissions: ['CAN_READ_ORDERS']},
      {
        path: '/orders/address-change',
        title: i18n.t('sidebar.menu.addressChange'),
        permissions: ['CAN_READ_CARRIER_QUALITY']
      },
      {path: '/orders/order-inbound', title: i18n.t('inboundOrder'), permissions: ['CAN_READ_ORDERS']},
    ]
  },
  {
    path: '/products',
    icon: 'fa fa-store',
    title: i18n.t('sidebar.menu.products'),
    label: '',
    permissions: ['CAN_READ_INVENTORY_ITEMS', 'CAN_READ_BESTSELLERS',
      'CAN_READ_INCOMING_GOODS', 'CAN_READ_STOCK_MOVEMENTS', 'CAN_READ_BATCH_OVERVIEW'],
    children: [
      {path: '/products/inventory', title: i18n.t('sidebar.menu.inventory'), permissions: ['CAN_READ_INVENTORY_ITEMS']},
      {path: '/products/bestseller', title: i18n.t('sidebar.menu.bestseller'), permissions: ['CAN_READ_BESTSELLERS']},
      {path: '/products/goods', title: i18n.t('sidebar.menu.goods'), permissions: ['CAN_READ_INCOMING_GOODS']},
      {path: '/products/stock', title: i18n.t('sidebar.menu.stock'), permissions: ['CAN_READ_STOCK_MOVEMENTS']},
      {
        path: '/products/batch-overview',
        title: i18n.t('sidebar.menu.batch-overview'),
        permissions: ['CAN_READ_BATCH_OVERVIEW']
      }
    ]
  },
  {
    path: '/invoices',
    icon: 'fa fas fa-book',
    title: i18n.t('sidebar.menu.invoices'),
    label: '',
    permissions: ['CAN_READ_ORDERS', 'CAN_READ_RETURNS', 'CAN_READ_BACKORDERS'],
    children: [
      {path: '/invoices/fulfillments', title: i18n.t('sidebar.menu.fulfillments'), permissions: ['CAN_READ_ORDERS']}
    ]
  },
  {
    path: '/reports',
    icon: 'fa fa-list',
    title: i18n.t('sidebar.menu.reports'),
    label: '',
    permissions: ['CAN_READ_SERVICE_QUALITY', 'CAN_READ_CARRIER_QUALITY'],
    children: [
      {
        path: '/reports/services',
        icon: 'fab fa-servicestack',
        title: i18n.t('sidebar.menu.service'),
        permissions: ['CAN_READ_SERVICE_QUALITY'],
        children: [
          {
            path: '/reports/service-quality',
            title: i18n.t('sidebar.menu.service-quality'),
            permissions: ['CAN_READ_SERVICE_QUALITY']
          },
          {
            path: '/reports/service-carrier',
            title: i18n.t('sidebar.menu.service-carrier'),
            permissions: ['CAN_READ_CARRIER_QUALITY']
          },
          {
            path: '/reports/reports-timeofgoodsreceipt',
            title: i18n.t('sidebar.menu.timeofgoodsreceipt'),
            permissions: ['CAN_READ_CARRIER_QUALITY']
          }]
      },
      {
        path: '/reports/business',
        icon: 'fas fa-business-time',
        title: i18n.t('sidebar.menu.business'),
        permissions: ['CAN_READ_SERVICE_QUALITY'],
        children: [
          {
            path: '/reports/reports-returns',
            title: i18n.t('sidebar.menu.returns'),
            permissions: ['CAN_READ_CARRIER_QUALITY']
          },
          {
            path: '/reports/reports-revenue',
            title: i18n.t('sidebar.menu.revenue'),
            permissions: ['CAN_READ_CARRIER_QUALITY']
          },
          {
            path: '/reports/reports-skurevenue',
            title: i18n.t('sidebar.menu.sku-revenue'),
            permissions: ['CAN_READ_CARRIER_QUALITY']
          },
          {
            path: '/reports/reports-packaging',
            title: i18n.t('packagingMaterials'),
            permissions: ['CAN_READ_INVENTORY_ITEMS']
          }]
      },
      {
        path: '/reports/error',
        icon: 'fa fa-warning',
        title: i18n.t('sidebar.menu.error'),
        permissions: ['CAN_READ_SERVICE_QUALITY'],
        children: [
          {
            path: '/reports/reports-labelerrors',
            title: i18n.t('sidebar.menu.label-errors'),
            permissions: ['CAN_READ_CARRIER_QUALITY']
          },
          {
            path: '/reports/reports-errors',
            title: i18n.t('sidebar.menu.errors'),
            permissions: ['CAN_READ_CARRIER_QUALITY']
          }]
      }
    ]
  },
  {
    path: '/access', icon: 'fa fa-lock', title: 'Access', label: '', permissions: ['CAN_READ_ROLE', 'CAN_READ_USER'],
    children: [
      {path: '/access/users', title: i18n.t('sidebar.menu.users'), permissions: ['CAN_READ_ROLE']},
      {path: '/access/roles', title: i18n.t('sidebar.menu.roles'), permissions: ['CAN_READ_USER']},
    ]
  },
  {
    path: '/widget/return-widget',
    icon: 'fa fa-brands fa-shopify',
    title: 'Return Widget',
    permissions: ['CAN_READ_RETURN_WIDGET']
  },
]

export default sidebarMenu
</script>
