import axios from "axios";
import {dashboardClientConfig} from "../config";

let apiRoot = dashboardClientConfig.apiRoot;
let rootUrl = apiRoot + "/reports-skurevenue";
import { requestManager } from '@/assets/requestManager.js';

export function reports_skurevenue() {
    return {
        all: (obj) => axios.post(rootUrl, obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        download_excel: (obj) => axios.post(rootUrl + "/exportToExcel", obj, { responseType: 'blob'}, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          })
    }
}

