<template>
  <page-content>
    <h1 class="page-header">{{ $t('supportCancellation') }}</h1>
    <b-alert :show="error != null" variant="danger">{{ error }}</b-alert>
    <b-card :title="$t('searchOrder')">
      <b-form @submit.prevent="search">
        <b-form-group
          id="search-id"
          :label="$t('id')"
          label-for="search-id-input">
          <b-form-input
            id="search-id-input"
            v-model="id"
            required>

          </b-form-input>
        </b-form-group>
        <b-button :disabled="loading" class="mt-3" type="submit">
          <b-spinner v-if="loading" small></b-spinner>
          {{ this.loading ? 'Loading...' : $t('search') }}
        </b-button>
      </b-form>
    </b-card>
    <cancel-order-form v-if="order" :cpy="this.cpy" :order="order" :show-details="true"></cancel-order-form>
  </page-content>
</template>

<script>
import PageContent from '../../../layout/PageContent.vue'
import { client } from '@/client/client'
import { bus } from '@/main'
import CancelOrderForm from '@/modules/support/components/CancelOrderForm.vue'

export default {
  name: 'SupportCancelOrderPage',
  components: {CancelOrderForm, PageContent},
  mounted() {
    this.cpy = this.$store.getters.currentCpy
    bus.$on('cpyChanged', (data) => {
      this.cpy = data
    })

    if (this.$route.query.orderNr) {
      this.id = this.$route.query.orderNr
      this.$nextTick(this.search)
    } else if (this.$route.query.deliveryNr) {
      this.id = this.$route.query.deliveryNr
      this.$nextTick(this.search)
    }
  },
  data() {
    return {
      id: null,
      cpy: this.$store.getters.currentCpy,
      order: null,
      loading: false,
      error: null
    }
  },
  methods: {
    async fetchOrder(cpy, id) {
      this.error = null
      this.loading = true
      const response = await client.change_address().order_get({
        cpy,
        id
      })

      if (response.data == null) {
        this.error = 'Order not found!'
      }

      this.order = response.data
      this.loading = false
    },
    async search() {
      this.order = null

      await this.fetchOrder(this.cpy, this.id)
    },
    // async onOrderUpdate(address, status) {
    // const oldAddress = { ...this.address }
    // const id = order.orderNr ? address.orderNr : address.deliveryNr
    // this.id = id
    // await this.fetchOrder(this.cpy, id)
    //
    // console.log(`${this.$store.getters.userId}: edit address, new address: ${this.address}, old address: ${oldAddress}, status: ${status}`)
    // }
  }
}
</script>
