import axios from "axios";
import {dashboardClientConfig} from "../config";

let apiRoot = dashboardClientConfig.apiRoot;

export function users() {
    const rootUrl = apiRoot + "/access/user";
    return {
        all: () => axios.get(rootUrl),
        create: (user) => axios.post(rootUrl, user)
    }
}

export function cpys() {
    const rootUrl = apiRoot + "/access/cpy";
    return {
        all: () => axios.get(rootUrl),
    }
}

export function user(userId) {
    const rootUrl = apiRoot + "/access/user/" + userId;
    return {
        get: () => axios.get(rootUrl),
        update: (user) => axios.put(rootUrl, user),
        delete: () => axios.delete(rootUrl)
    }
}
