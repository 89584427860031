<template>
  <div>
    <b-modal
      ref="changeAddressModal"
      v-model="modalChangeAddressVisible"
      :title="$t('changeAddress')"
      centered hide-footer hide-header
      size="lg">
      <ChangeAddressForm v-if="this.modalChangeAddress" :address="this.modalChangeAddress"
                         :hide-tracking="true"
                         @update-address="hideChangeAddressModal"
      ></ChangeAddressForm>
    </b-modal>
    <vue-good-table
      ref="labelErrros"
      :columns="columns"
      :isLoading="isLoading"
      :lineNumbers="false"
      :mode="'remote'"
      :paginationOptions="{
          enabled: true,
          position: 'bottom',
          perPage: 20,
          perPageDropdown: null,
          perPageDropdownEnabled: true,
          dropdownAllowAll: false,
          mode: 'records', // or pages
          infoFn: null,
          jumpFirstOrLast : true
        }"
      :responsive="true"
      :rows="data.data"
      :search-options="{ enabled: false, placeholder: 'Search' }"
      :selectOptions="{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				}"
      :sort-options="{
          enabled: true,
          initialSortBy: {field: 'delivery_created_at', type: 'desc'}
        }"
      :total-rows="data.total"
      @on-page-change="load"
      @on-per-page-change="load"
      @on-column-filter="filterChanged"
      @on-sort-change="onSortChange"
    >
      <template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'note'">
            <a :style="{ color: props.row.comment_on_order !== '' ? '#8B0000' : '' }" class="fa fa-book" href="#"
               type="button" @click="doNote(props.row)"></a>
          </span>
        <!--          <span v-if="props.column.field === 'delivery_id'">-->
        <!--&lt;!&ndash;            <router-link :to="{ path: '/support/address-change', query: { deliveryNr: props.row.delivery_id } }">{{ props.row.delivery_id }}</router-link>&ndash;&gt;-->
        <!--            <a href="#" @click.prevent="openChangeAddressModal(props.row.delivery_id)">{{ props.row.delivery_id }}</a>-->
        <!--          </span>-->
        <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
      </template>
    </vue-good-table>

    <b-modal id="modal-detail-comment" ref="modal_comment_on_order" :title="$t('commentOnOrder')"
             :visible="modalCommentVisible"
             centered hide-header no-close-on-backdrop ok-title="Save" size="lg" @cancel="cancelComment"
             @ok="saveComment">
      <panel id="comment" :title="$t('commentOnOrder')" resizeable="true">
        <OrderComment :gridData="data.data" :selectedRow="selectedRow"/>
      </panel>
    </b-modal>
  </div>
</template>

<script>
import { bus } from '@/main'
import OrderComment from '../../../modules/orders/components/OrderComment'
import ChangeAddressForm from '@/modules/support/components/ChangeAddressForm.vue'
import { client } from '@/client/client'

export default {
  name: 'label_errors',
  components: {ChangeAddressForm, OrderComment},
  props: ['data', 'isLoading'],

  methods: {
    cancelComment(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.modalCommentVisible = false
    },
    saveComment(bvModalEvent) {
      bvModalEvent.preventDefault()
      bus.$emit('saveCommentEvent')
      this.modalCommentVisible = false
    },
    doNote(row) {
      this.selectedRow = row
      this.modalCommentVisible = true
    },
    load: function (event) {
      this.$emit('reload', event)
    },
    onSortChange: function (event) {
      this.$emit('sort', event)
    },
    filterChanged: function (event) {
      this.$emit('filter', event)
    },
    filterButton(type) {
      this.$emit('filter-button', type)
    },
    reset() {
      this.$refs.skuRevenueData.reset()
    },
    async openChangeAddressModal(deliveryNr) {
      const address = await client.change_address().address_get({id: deliveryNr, cpy: this.$store.getters.currentCpy})

      this.modalChangeAddress = address.data
      this.$refs.changeAddressModal.show()
    },
    async hideChangeAddressModal() {
      this.$refs.changeAddressModal.hide()
      this.modalChangeAddress = null
      this.load()
    }
  },
  data() {
    return {
      selectedRow: {},
      modalCommentVisible: false,
      modalDetailVisible: false,
      modalChangeAddressVisible: false,
      modalChangeAddress: null,

      columns: [
        {
          label: '',
          field: 'note',
          filterable: false,
          sortable: false,
          width: '1%'
          //thClass: 'icon-magnifier'
        },
        {
          label: 'Order ID',
          field: 'order_id',
          width: '10%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'Order ID'
          }
        },
        {
          label: 'ID',
          field: 'delivery_id',
          width: '10%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'Delivery ID'
          }
        },
        {
          label: 'SHIPPER',
          field: 'shipper',
          width: '10%',
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SHIPPER'
          }
        }
        , {
          label: 'ERRORTEXT',
          field: 'label_error',
          width: '80%',
          tdClass: 'text-left text-wrap',
          thClass: 'text-left text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ERRORTEXT'
          }
        },
        {
          label: 'DELIVERY DATE',
          field: 'delivery_created_at',
          width: '10%',
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'DELIVERY DATE'
          }
        },
        {
          label: 'Note',
          field: 'note_on_delivery',
          width: '10%',
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'Note'
          }
        },
        {
          label: 'CUSTOMER COMMENTS',
          field: 'comment_on_order',
          width: '10%',
          tdClass: 'text-left text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'CUSTOMER COMMENTS'
          }
        }
      ]
    }
  },
}
</script>
