export default {
    methods: {
        hasPermission(val) {
            return this.$store.getters.userPermissions.filter(p => p === val).length > 0;
        },
        hasAnyPermission(val) {
            if (Array.isArray(val)) {
                return this.$store.getters.userPermissions.filter(p => val.filter(v => v === p).length > 0).length > 0;
            } else {
                return false;
            }
        },
        hasAllPermissions(val) {
            if (Array.isArray(val)) {
                return this.$store.getters.userPermissions.filter(p => val.filter(v => v === p).length > 0).length === val.length;
            } else {
                return false;
            }
        }
    }
}