<template>
  <page-content>
    <h1 class="page-header">{{ $t('reports-return.page.reportsServiceQualityReturns') }}</h1>
    <panel id="service-quality-returns" :title="$t('reports-return.page.serviceQualityReturns')">
      <div class="row p-b-15">
        <div class="col-md-9 p-b-15">
          <div class="row">
            <div class="col-md-2">
              <select v-model="selectedFilter" class="form-select" v-on:change="applyDateFilter">
                <option v-for="(filter, index) in predefinedFilters" v-bind:key="filter.name + index" :value="filter">
                  {{ filter.name }}
                </option>
              </select>
            </div>
            <div class="col-md-2 padding-left-0" style="position: relative;">
              <date-picker ref="dateFrom" v-model="dateFrom" :config="configs.start"
                           v-on:dp-change="onStartChange"></date-picker>
            </div>
            <div class="col-md-2 padding-left-0" style="position: relative;">
              <date-picker ref="dateTo" v-model="dateTo" :config="configs.end"
                           v-on:dp-change="onEndChange"></date-picker>
            </div>
            <div class="col-md-2 padding-left-0">
              <b-button class="button btn-success margin-right-5" @click="applyDateFilter()">
                {{ $t('filter') }}
              </b-button>
              <b-button class="button btn-warning" @click="resetFilters()">
                {{ $t('reset') }}
              </b-button>
            </div>
          </div>
        </div>
        <div v-if="dataLoaded" class="row">
          <div v-if="isLoading" class="vgt-loading vgt-center-align position-static">
            <slot name="loadingContent">
               <span class="vgt-loading__content">
                   Loading...
               </span>
            </slot>
          </div>
          <div class="col-md-10">
            <apexchart ref="realtimeChart" :height="lineChart.options.chart.height" :options="lineChart.options"
                       :series="lineChart.series" type="line"></apexchart>
          </div>

        </div>
      </div>
    </panel>
  </page-content>
</template>
<script>
import { client } from '@/client/client'
import PageContent from '../../../layout/PageContent'
import AppOptions from '../../../config/AppOptions.vue'
import _ from 'lodash'
import { bus } from '@/main'

export default {
  components: {PageContent},
  mounted() {
    this.load()
    this.$store.watch((state, getters) => getters.userId, this.load)
    this.request.cpy = this.$store.getters.currentCpy
    bus.$on('cpyChanged', (data) => {
      this.request.cpy = data
      this.load()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
  },
  data() {
    // const date_from = new Date();
    //date_from.setDate(date_from.getDate() - 7);
    return {
      activeRequests: 0,
      lineChart: {
        options: {
          chart: {
            type: 'line',
            height: 550,
            shadow: {
              enabled: true,
              color: 'rgba(' + AppOptions.color.componentColorRgb + ', .5)',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 1
            },
            toolbar: {
              show: true
            }
          },
          title: {
            text: this.$t('service-quality.page.returnsInHour'),
            align: 'center',
            style: {
              color: AppOptions.color.componentColor
            }
          },
          colors: [AppOptions.color.blue],
          dataLabels: {
            enabled: true,
            background: {
              borderWidth: 0
            }
          },
          stroke: {
            curve: 'smooth',
            width: 3
          },
          grid: {
            row: {colors: ['rgba(' + AppOptions.color.componentColorRgb + ', .05)', 'transparent'], opacity: 0.5}
          },
          xaxis: {
            categories: [],
          },
          legend: {
            show: true,
            position: 'top',
            offsetY: -10,
            horizontalAlign: 'right',
            floating: true,
            labels: {
              colors: AppOptions.color.componentColor
            }
          }
        },
        series: []
      },
      dataLoaded: false,
      REQUEST_DATE_FORMAT: 'YYYY',
      dateFrom: '2022',
      dateTo: '2023',
      configs: {
        start: {
          format: 'YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false
        },
        end: {
          format: 'YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false
        }
      },
      selectedFilter: {name: this.$t('monthly'), filter: 'monthly'},
      predefinedFilters: [
        {name: this.$t('monthly'), filter: 'monthly'},
        {name: this.$t('weekly'), filter: 'weekly'}
      ],
      returns_quality: {},
      isLoading: false,
      request: {
        //date_from: new Date(),
        //dateFrom: new Date(),
        filters: {},
        cpy: this.$store.getters.currentCpy,
        predefined: ''
      }
    }
  },
  watch: {
    activeRequests(newValue) {
      this.isLoading = newValue > 0
    }
  },
  methods: {
    getReportsReturn() {
      this.activeRequests++
      client.reports_returns().all(this.request)
        .then(response => {
          this.returns_quality = response.data
          let categories = []
          let seriesData = []
          this.lineChart.series = []
          for (let q of this.returns_quality.data) {
            categories.push(q.order_created_at)
            seriesData.push(q.avg_dispatched)

            //this.updateSeriesLine();
          }
          //seriesData = [3, 1, 1, 2, 3, 1, 2, 1, 2, 6, 6, 3];
          //categories = ['202201', '202202', '202203', '202204', '202205', '202206', '202207', '202208', '202209', '202210', '202211', '202212'];
          this.lineChart.options.xaxis.categories = categories
          // this.lineChart.series = [];
          //this.series = seriesData;
          this.lineChart.series.push({name: this.$t('service-quality.page.returnsInHour'), data: seriesData})
          window.dispatchEvent(new Event('resize'))
        }).finally(() => {
        this.activeRequests--
        this.dataLoaded = true
      })
    },
    load(event) {
      if (event) {
        //this.request.per_page = event.currentPerPage;
        this.request.page = event.currentPage
      }
      this.applyDateFilter()

    },
    filter(event) {
      if (event && event.columnFilters) {
        this.request.filters = _.cloneDeep(event.columnFilters)
      }
      this.applyDateFilter()
    },
    resetFilters() {
      this.dateFrom = '2022'
      this.dateTo = '2023'
      this.request.predefined = ''
      this.request.filters = {}
      this.request.date_from = null
      this.request.date_to = null
      this.selectedFilter = {name: 'Monthly', filter: 'monthly'}
      this.applyDateFilter()
    },
    applyDateFilter() {
      this.request.predefined = this.selectedFilter.filter
      this.request.date_from = this.dateFrom
      this.request.date_to = this.dateTo
      this.getReportsReturn()
    },
    onStartChange(e) {
      this.dateFrom = e.date.format(this.REQUEST_DATE_FORMAT)
    },
    onEndChange(e) {
      this.dateTo = e.date.format(this.REQUEST_DATE_FORMAT)
    }
  }
}
</script>

<style scoped>
</style>
