<template>
  <page-content v-if="hasPermission('CAN_READ_USER')">
    <h1 class="page-header">{{ $t('users.page.accessUsers') }}</h1>
    <panel
      id="users-panel"
      :resizeable="true"
      :title="$t('users.page.users')"
      @reload="load"
    >
      <vue-good-table
        ref="users"
        :columns="columns"
        :isLoading="isLoading"
        :lineNumbers="false"
        :mode="'local'"
        :paginationOptions="tablePaginationOptions"
        :responsive="true"
        :rows="users"
        :search-options="{ enabled: false, placeholder: 'Search' }"
        :selectOptions="tableSelectOptions"
        :sort-options="tableSortOptions"
        @on-page-change="load"
        @on-per-page-change="load">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'delete'">
            <icon-btn
              icon="fa-trash-alt color-red"
              tooltip="Delete"
              @clicked="onDeleted(props.row)"/>
          </span>
          <span v-else-if="props.column.field === 'edit'">
          <icon-btn
            icon="fa-pencil-alt color-green"
            tooltip="Edit"
            @clicked="onEdit(props.row)"/>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <div slot="table-actions">
          <div id="user-create-square-link">
            <icon-btn
              icon="fa-plus-square color-green"
              tooltip="Create"
              @clicked="onCreated"/>
          </div>
        </div>
      </vue-good-table>

      <b-modal
        :id="modalId"
        cancel-variant="default"
        no-close-on-backdrop
        size="xl"
      >
        <template slot="modal-header">
          <h4 class="modal-title">
            {{ user.id ? 'Edit User' : 'Create User' }}
          </h4>
          <a class="btn-close" @click="$bvModal.hide(modalId)"/>
        </template>
        <div v-if="isLoading" class="vgt-loading vgt-center-align position-static">
          <slot name="loadingContent">
            <span class="vgt-loading__content">Loading...</span>
          </slot>
        </div>
        <form v-else @submit.prevent="createUser(user)">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <fieldset>
                    <div class="mb-2">
                      <label class="form-label" for="loginInput">
                        {{ $t('users.page.login') }}
                      </label>
                      <input
                        id="loginInput"
                        v-model="user.login"
                        :placeholder="$t('users.page.enterUserLogin')"
                        autofocus
                        class="form-control"
                        required
                        type="text"/>
                    </div>
                    <div class="mb-2">
                      <label class="form-label" for="emailInput">
                        {{ $t('users.page.email') }}
                      </label>
                      <input
                        id="emailInput"
                        v-model="user.email"
                        :placeholder="$t('users.page.enterUserEmail')"
                        autofocus
                        class="form-control"
                        type="email"/>
                    </div>
                    <div class="mb-2">
                      <label class="form-label" for="firstNameInput">
                        {{ $t('users.page.firstName') }}
                      </label>
                      <input
                        id="firstNameInput"
                        v-model="user.firstName"
                        :placeholder="$t('users.page.enterUserFirstName')"
                        autofocus
                        class="form-control"
                        type="text"/>
                    </div>
                    <div class="mb-2">
                      <label class="form-label" for="lastNameInput">
                        {{ $t('users.page.lastName') }}
                      </label>
                      <input
                        id="lastNameInput"
                        v-model="user.lastName"
                        :placeholder="$t('users.page.enterUserLastName')"
                        autofocus
                        class="form-control"
                        type="text"/>
                    </div>
                    <div class="mb-2">
                      <label class="form-label" for="passwordInput">
                        {{ $t('users.page.password') }}
                      </label>
                      <input
                        id="passwordInput"
                        v-model="user.newPassword"
                        :placeholder="$t('users.page.enterUserPassword')"
                        autofocus
                        class="form-control"
                        type="text"/>
                    </div>
                  </fieldset>
                </div>
                <div v-if="roles" class="col-md-4">
                  <fieldset>
                    <legend class="mb-1 h5">{{ $t('users.page.roles') }}</legend>
                    <div>
                      <div v-for="role in roles" :key="role.name" class="form-check">
                        <input
                          :id="'role-' + role.name"
                          :checked="isRoleSelected(role.name)"
                          class="form-check-input"
                          type="checkbox"
                          @change="updateSelectedRole(role.name)"/>
                        <label :for="'role-' + role.name" class="form-check-label">
                          {{ role.name }}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div v-if="cpys" class="col-md-4">
                  <div class="d-flex flex-row align-items-center mb-4">
                    <input
                      id="allMandants"
                      :checked="allMandantsSelected"
                      class="form-check-input"
                      type="checkbox"
                      @change="updateSelectedAllMandants($event.target.checked)"/>
                    <label class="form-check-label" for="allMandants">
                      {{ allMandantsSelected ? 'Remove all' : 'Select all' }}
                    </label>
                    <div v-if="!isCpysSelected">
                      <p class="text-danger my-0 mx-2" style="font-size: 12px; font-weight: 500;">
                        {{ $t('users.page.atLeastOneMandantMustBeSelected') }}
                      </p>
                    </div>
                  </div>

                  <fieldset>
                    <legend class="mb-1 h5">{{ $t('users.page.mandants') }}</legend>
                    <div>
                      <div v-for="cpy in cpys" :key="cpy" class="form-check">
                        <input
                          :id="'cpy-' + cpy"
                          :checked="user.cpys?.includes(cpy)"
                          class="form-check-input"
                          type="checkbox"
                          @change="updateSelectedMandants(cpy)"/>
                        <label :for="'cpy-' + cpy" class="form-check-label">
                          {{ cpy }} - {{ getMandant(cpy) }}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          <b-button ref="submit" style="display: none;" type="submit">
            {{ $t('submit') }}
          </b-button>
        </form>
        <template v-slot:modal-footer>
          <b-button
            variant="default"
            @click="closeModal"
          >
            {{ $t('close') }}
          </b-button>
          <b-button
            :disabled="user.cpys && !user.cpys.length"
            variant="success"
            @click="submitForm"
          >
            {{ $t('save') }}
          </b-button>
        </template>
      </b-modal>
    </panel>
  </page-content>
</template>

<script>
import PageContent from '../../../layout/PageContent'
import IconBtn from '../../../components/buttons/IconButton'
import { client } from '@/client/client'
import confirmationBox from '@/components/confirmationBox'

export default {
  components: {PageContent, IconBtn},
  data() {
    return {
      cpyAndMandants: [],
      users: [],
      roles: [],
      cpys: [],
      user: {},
      modalId: 'users-modal',
      isLoading: true,
      activeRequests: 0,
      tablePaginationOptions: {
        enabled: false,
        position: 'bottom',
        perPage: 20,
        perPageDropdown: null,
        perPageDropdownEnabled: true,
        dropdownAllowAll: false,
        mode: 'records',
        infoFn: null,
        jumpFirstOrLast: true
      },
      tableSelectOptions: {
        enabled: false,
        selectOnCheckboxOnly: false,
        selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
      },
      tableSortOptions: {
        enabled: true,
        initialSortBy: {field: 'login', type: 'asc'}
      },
      columns: [
        {
          label: '',
          field: 'edit',
          width: '1%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: ''
          }
        },
        {
          label: 'LOGIN',
          field: 'login',
          width: '10%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'LOGIN'
          }
        },
        {
          label: 'FIRST NAME',
          field: 'firstName',
          width: '10%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'FIRST NAME'
          }
        },
        {
          label: 'LAST NAME',
          field: 'lastName',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'LAST NAME'
          }
        },
        {
          label: 'ROLES',
          field: 'roles',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ROLES'
          }
        },
        {
          label: 'MANDANTS',
          field: 'cpys',
          width: '10%',
          tdClass: 'text-center text-wrap',
          thClass: 'text-center text-wrap',
          filterOptions: {
            enabled: true,
            placeholder: 'MANDANTS'
          }
        },
        {
          label: '',
          field: 'delete',
          width: '1%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: ''
          }
        }
      ]
    }
  },
  async mounted() {
    await this.load()
    this.$store.watch((state, getters) => getters.userId, this.load)
  },
  watch: {
    activeRequests(newValue) {
      this.isLoading = newValue > 0
    }
  },
  computed: {
    allMandantsSelected() {
      return this.user && this.user.cpys ? this.user.cpys.length === this.cpys.length : false
    },
    isCpysSelected() {
      return this.user && this.user.cpys ? this.user.cpys.length > 0 : false
    }
  },
  methods: {
    getMandant(cpy) {
      return this.cpyAndMandants.find(obj => obj.cpy === cpy).name
    },
    async load() {
      try {
        this.activeRequests++

        const usersResponse = await client.users().all()
        this.users = usersResponse.data

        const rolesResponse = await client.roles().all()
        this.roles = rolesResponse.data

        const cpysResponse = await client.cpys().all()
        this.cpyAndMandants = cpysResponse.data
        this.cpys = this.cpyAndMandants.map(obj => obj.cpy)

        this.activeRequests--
      } catch (error) {
        console.error('Error:', error)
      }
    },
    onCreated() {
      this.user = {cpys: []}
      this.$bvModal.show(this.modalId)
    },
    onDeleted(user) {
      confirmationBox(this).open('Confirm Removal', 'Do you want to remove role \'' + user.login + '\'?',
        async () => {
          await client.user(user.id).delete()
          this.onSuccess()
        })
    },
    onEdit(user) {
      this.user = user
      this.$bvModal.show(this.modalId)
    },
    onSuccess() {
      this.load()
      this.closeModal()
    },
    submitForm() {
      this.$refs.submit.click()
    },
    closeModal() {
      this.$bvModal.hide(this.modalId)
    },
    async createUser(user) {
      try {
        this.activeRequests++
        if (user.cpys && user.cpys.length > 0) {
          if (user.id) {
            await client.user(user.id).update(user)
          } else {
            await client.users().create(user)
          }
          this.onSuccess()
        }
        this.activeRequests--
      } catch (error) {
        console.error('Error:', error)
      }
    },
    isRoleSelected(role) {
      if (this.user && this.user.roles) {
        return this.user.roles.includes(role)
      } else {
        return false
      }
    },
    updateSelectedRole(selectedRole) {
      if (!this.user.roles) {
        this.user.roles = []
      }

      if (this.user.roles.includes(selectedRole)) {
        this.user.roles = this.user.roles.filter(role => role !== selectedRole)
      } else {
        this.user.roles.push(selectedRole)
      }
    },
    updateSelectedAllMandants(selected) {
      if (selected) {
        this.user.cpys = this.cpys
      } else {
        this.user.cpys = []
      }
    },
    updateSelectedMandants(selectedCpy) {
      if (!this.user.cpys) {
        this.user.cpys = []
      }

      if (this.user.cpys.includes(selectedCpy)) {
        this.user.cpys = this.user.cpys.filter(cpy => cpy !== selectedCpy)
      } else {
        this.user.cpys.push(selectedCpy)
      }
    }
  }
}
</script>
