<template>
	<a href="javascript:void(0);" :disabled="disabled" :style="disabled ? 'cursor: not-allowed;' : ''"
     v-b-tooltip.hover.right="tooltip" @click="clicked">
    <i :class="'fa fa-lg ' + icon"></i>
	</a>
</template>
<script>
    export default {
        name: "IconBtn",
        props: ['tooltip', 'icon', 'disabled'],
        methods: {
            clicked: function() {
                if (!this.disabled) {
                    this.$emit("clicked");
                }
            }
        }
    }
</script>
