export default function confirmationBox(component) {
    return {
        open: (title, message, onConfirm, onCancel, btn) => {
            component.$confirm(
                {
                    title,
                    message,
                    button: btn ? btn : {
                        no: 'No',
                        yes: 'Yes'
                    },
                    callback: (confirm) => {
                        if (confirm && onConfirm) {
                            onConfirm()
                        } else if (!confirm && onCancel) {
                            onCancel();
                        }
                    }
                }
            )
        }
    }
}
