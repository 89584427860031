<template>
  <div>
    <vue-good-table
        ref="batch_overview"
        :columns="columns"
        :rows="data.data"
        :total-rows="data.total"
        :isLoading="isLoading"
        :responsive="true"
        :row-style-class="rowStyleClassFn"
        @on-page-change="load"
        @on-per-page-change="load"
        @on-column-filter="filterChanged"
        @on-sort-change="onSortChange"
        :mode="'remote'"
        :lineNumbers="false"
        :search-options="{ enabled: false, placeholder: 'Search' }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'sku', type: 'desc'}
        }"
        :selectOptions="{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				}"
        :paginationOptions="{
          enabled: true,
          position: 'bottom',
          perPage: 20,
          perPageDropdown: null,
          perPageDropdownEnabled: true,
          dropdownAllowAll: false,
          mode: 'records', // or pages
          infoFn: null,
          jumpFirstOrLast : true
        }"
    >
    <template slot="table-row" slot-scope="props" class="table-danger">
           <span v-if="props.column.field === 'expiration_date'">
            {{props.row.ship_date === '' ? '-' : formatDateShort(props.row.expiration_date)}}
          </span>   

          
      </template> 

    </vue-good-table>
  </div>
</template>

<script>

export default {
  name: 'BatchOverview',
  components: {},
  props: ['data', 'isLoading'],
  methods: {
    rowStyleClassFn(row) {
      return new Date(row.expiration_date).getTime() < new Date().getTime()  ? "trdanger" : "";
    },
    load: function(event) {
      this.$emit('reload', event);
    },
    onSortChange: function (event) {
      this.$emit('sort', event);
    },
    filterChanged: function (event) {
      this.$emit('filter', event);
    },
    filterButton(type) {
      this.$emit('filter-button', type);
    },
    reset() {
      this.$refs.batch_overview.reset();
    }
  },
  data() {
    return {
      columns: [
        {
          label: 'STOCK_FCY',
          field: 'stock_fcy',
          width: '20%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'STOCK_FCY'
          }
        }, {
        label: 'SKU',
        field: 'sku',
        width: '20%',
        thClass: 'text-center text-nowrap',
        tdClass: 'text-center text-nowrap',
        filterOptions: {
          enabled: true,
          placeholder: 'SKU'
        }
      }, {
          label: 'QTY',
          field: 'qty',
          width: '10%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: "QTY"
          }
        },
        // {
        //   label: 'EXPIRATION DATE',
        //   field: 'expiration_date',
        //   type: 'date',
        //   dateInputFormat: 'yyyyy-MM-dd HH:mm:ss.S',
        //   dateOutputFormat: 'dd.MM.yyyy',
        //   width: '20%',
        //   tdClass: 'text-center text-nowrap',
        //   thClass: 'text-center text-nowrap'
        // },
        {
          label: 'LOT',
          field: 'lot',
          width: '10%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: "LOT"
          }
        },{
          label: 'LOCTYPE',
          field: 'loctype',
          width: '10%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: "LOCTYPE"
          }
        },{
          label: 'LOC',
          field: 'loc',
          width: '10%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: "LOC"
          }
        },{
          label: 'EXPIRATION DATE',
          field: 'expiration_date',
          width: '10%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: "EXPIRATION DATE"
          }
        }

        
      ]
    }
  },
}
</script>

<style scoped>
</style>
