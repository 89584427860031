<template>
  <page-content>
    <h1 class="page-header">{{ $t('reports-revenue-detail.page.reportsRevenueDetail', {country: country}) }}</h1>


    <panel id="invoice-revenue-info-panel" :backButtonVisible=true
           :title="$t('reports-revenue-detail.page.invoiceRevenueDetail')" resizeable="true" @reload="load(null)">
      <reports-revenue-detail ref="bestsellers" :data="invoice_revenue.detail" :isLoading="isLoading" @reload="load"/>
    </panel>
  </page-content>
</template>
<script>
import { client } from '@/client/client'
import PageContent from '../../../layout/PageContent'
import ReportsRevenueDetail from '@/modules/reports-revenue/components/ReportsRevenueDetail'
import { bus } from '@/main'

export default {
  components: {PageContent, ReportsRevenueDetail},
  mounted() {
    this.country = this.$route.query.country
    this.selectedFilter = {
      filter: this.$store.getters.userDefaultDateFilter,
      name: this.$store.getters.userDefaultDateFilterName
    }
    this.load()
    this.$store.watch((state, getters) => getters.userId, this.load)
    this.request.cpy = this.$store.getters.currentCpy
    bus.$on('cpyChanged', (data) => {
      this.request.cpy = data
      this.load()
    })
  },
  created() {
    this.country = this.$route.query.country
    this.dateFrom = this.$route.query.dateFrom
    this.dateTo = this.$route.query.dateTo
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
  },

  /* watch: {
     '$route.query.country': {
       immediate: true,
       handler(country) {
         this.countryX = country || null;
       }
     }
   },*/
  data() {
    const date_from = new Date()
    date_from.setDate(date_from.getDate() - 7)
    this.country = this.$route.query.country
    this.dateFrom = this.$route.query.dateFrom
    this.dateTo = this.$route.query.dateTo
    return {

      dataLoaded: false,
      REQUEST_DATE_FORMAT: 'YYYY-MM-DD',
      dateFrom: null,
      dateTo: null,
      selectedFilter: {name: this.$t('lastWeek'), filter: 'last_30'},
      predefinedFilters: [
        {name: this.$t('today'), filter: 'today'},
        {name: this.$t('yesterday'), filter: 'yesterday'},
        {name: this.$t('lastWeek'), filter: 'last_7'},
        {name: this.$t('last30Days'), filter: 'last_30'},
        {name: this.$t('last6Months'), filter: 'last_6m'},
        {name: this.$t('lastYear'), filter: 'last_year'},
        {name: this.$t('custom'), filter: 'custom'}
      ],
      selectedDataFilter: {name: 'Shipped from MH to delivered scan - with sunday', filter: this.country},
      invoice_revenue: {},
      total_rows: 0,
      total_buying_amount: 0,
      total_buying_qty: 0,
      isLoading: false,
      request: {
        per_page: 20,
        page: 1,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        order_column: 'invoicenr',
        order_dir: 'DESC',
        filters: {'country': this.country},
        cpy: this.$store.getters.currentCpy,
      }
    }
  },
  methods: {
    getReportRevenueDetail() {
      this.isLoading = true
      client.reports_revenue_detail().all(this.request)
        .then(response => {
          this.isLoading = true
          this.invoice_revenue = response.data
        }).finally(() => {
        this.isLoading = false
        this.dataLoaded = true
      })
    },
    load(event) {
      if (event) {
        this.request.per_page = event.currentPerPage
        this.request.page = event.currentPage
      }
      this.applyDateFilter()
    },
    resetFilters() {
      this.dateFrom = null
      this.dateTo = null
      this.request.predefined = ''
      this.request.filters = {}
      this.request.date_from = null
      this.request.date_to = null
      this.selectedFilter = {name: this.$t('lastWeek'), filter: 'last_7'}
      this.selectedDataFilter = {name: 'Shipped from MH to delivered scan - with sunday', filter: this.country}
      this.request.order_column = 'day'
      this.request.order_dir = 'DESC'
      this.applyDateFilter()
    },
    applyDateFilter() {
      switch (this.selectedFilter.filter) {
        case 'today':
          this.request.date_from = new Date()
          this.request.date_to = new Date()
          break
        case 'yesterday':
          var yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          this.request.date_from = yesterday
          this.request.date_to = yesterday
          break
        case 'last_7':
          var last_7 = new Date()
          last_7.setDate(last_7.getDate() - 7)
          this.request.date_from = last_7
          this.request.date_to = new Date()
          break
        case 'last_30':
          var last_30 = new Date()
          last_30.setDate(last_30.getDate() - 30)
          this.request.date_from = last_30
          this.request.date_to = new Date()
          break
        case 'last_6m':
          var last_6m = new Date()
          last_6m.setMonth(last_6m.getMonth() - 6)
          this.request.date_from = last_6m
          this.request.date_to = new Date()
          break
        case 'last_year':
          var last_year = new Date()
          last_year.setFullYear(last_year.getFullYear() - 1)
          this.request.date_from = last_year
          this.request.date_to = new Date()
          break
        case 'custom':
          this.request.date_from = this.dateFrom.format(this.REQUEST_DATE_FORMAT)
          this.request.date_to = this.dateTo.format(this.REQUEST_DATE_FORMAT)
          break
      }
      this.request.predefined = this.selectedDataFilter.filter
      this.getReportRevenueDetail()
    }
  }
}
</script>

<style scoped>
</style>
