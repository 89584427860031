<template>
  <div>
    <b-btn
      :disabled="isLoading"
      class="mb-4 mx-2"
      @click="showHoldModal($props.data[0])"
    >
      <span>Hold/Release Order</span>
    </b-btn>

    <div v-if="isLoading" class="vgt-loading vgt-center-align position-static mb-5">
      <slot name="loadingContent">
        <span class="vgt-loading__content">Loading...</span>
      </slot>
    </div>

    <div v-else class="container px-2">
      <div class="row">
        <div v-for="item in data" :key="item.idc + '_1'" class="container">
          <div class="row">
            <div class="col-sm">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <b-list-group>
                      <b-list-group-item class="text-secondary">
                        Details:
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('orders.delivery-detail.deliveryId') }}</b>: {{ item.delivery_id }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('orders.delivery-detail.orderStatus') }}</b>: {{ item.order_status }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>Date Of Order</b>: {{ item.date_of_order }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>Order Created By</b>: {{ item.order_created_by_user }}
                        ({{ item.order_created_by_user_name }})
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="col">
                    <b-list-group>
                      <b-list-group-item class="text-secondary">
                        Contact details:
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('name') }}</b>: {{ item.contact_name }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>Company Name</b>: {{ item.company_name }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('email') }}</b>: {{ item.email }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('phone') }}</b>: {{ item.phone }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="col">
                    <b-list-group>
                      <b-list-group-item class="text-secondary">
                        Shipper & Tracking:
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>Shipper</b>: {{ item.shipper }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>Service Code</b>: {{ item.service_code }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>Tracking Nr</b>: {{ item.tracking_nr }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>Tracking Url</b>: <a :href="item.tracking_url" target="_blank">Link</a>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <b-list-group>
                      <b-list-group-item class="text-secondary">
                        Address:
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('country') }}</b>: {{ item.country }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('street') }}</b>: {{ item.street }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('city') }}</b>: {{ item.city }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('zipCode') }}</b>: {{ item.zip }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="col">
                    <b-list-group>
                      <b-list-group-item class="text-secondary">
                        Order details:
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>Order Type</b>: {{ item.order_type }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('orders.delivery-detail.orderAmountNet') }}</b>: {{ item.order_amount_net }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('orders.delivery-detail.orderAmountGros') }}</b>: {{ item.order_amount_brut }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('orders.delivery-detail.orderCreated') }}</b>:
                        {{ moment(item.order_created_at).format('YYYY-MM-DD') }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="col">
                    <b-list-group>
                      <b-list-group-item class="text-secondary">
                        Timestamps:
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>Packing Date</b>: {{ moment(item.packing_date).format('YYYY-MM-DD') }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('orders.delivery-detail.labelDate') }}</b>:
                        {{ moment(item.label_date).format('YYYY-MM-DD') }}
                      </b-list-group-item>
                      <b-list-group-item>
                        <b>{{ $t('orders.delivery-detail.shipDate') }}</b>:
                        {{ moment(item.ship_date).format('YYYY-MM-DD') }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </div>
              </div>
              <div v-if="!!item.shipping_error_message">
                {{ $t('orders.delivery-detail.labelMessage') }}
                <div class="alert alert-danger">{{ item.shipping_error_message }}</div>
              </div>
            </div>
          </div>
          <div v-if="!!item.note_on_order">
            <div class="alert alert-danger">
              {{ item.note_on_order }}
            </div>
          </div>
          <div v-if="!!item.note_on_delivery">
            <div class="alert alert-secondary" role="alert">
              {{ item.note_on_delivery }}
            </div>
          </div>
        </div>
      </div>

      <div class="row gx-5">
        <div v-for="item in data" :key="item.idc + '_2'" class="container p-3">
          <vue-good-table
            ref="orderDeliveryInfo"
            :columns="columns"
            :globalSearch="true"
            :isLoading="isLoading"
            :lineNumbers="false"
            :mode="'local'"
            :responsive="true"
            :rows="item.items"
            :search-options="{ enabled: false }"
            :selectOptions="tableSelectOptions"
            :sort-options="{ enabled: false }">
          </vue-good-table>
        </div>
      </div>

      <div class="row gx-5">
        <div v-for="item in data" :key="item.idc + '_3'" class="container p-3">
          <div v-for="tr in item.tracking" :key="tr.idc + '_4'" class="list-group">
            <a class="list-group-item" href="#">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-3">{{ tr.trackingnr }}</h5>
                <small>{{ tr.dt }}</small>
              </div>
              <p class="mb-1">{{ tr.msg }}</p>
              <small>{{ tr.statuscode }}</small>
            </a>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="holdModal"
      hide-footer
      hide-header-close
      title="Hold/Release Order"
      @hide="closeHoldModal"
    >
      <b-form>
        <b-form-group label="Notes">
          <b-form-textarea
            id="textarea"
            v-model="holdReleaseNote"
            max-rows="6"
            placeholder="Enter something..."
            rows="3"
          />
        </b-form-group>
        <div class="d-flex mt-2 justify-content-end gap-2">
          <b-btn
            variant="primary"
            @click="closeHoldModal"
          >
            Cancel
          </b-btn>
          <b-btn
            variant="danger"
            @click="submitHoldRelease(holdReleaseRow.is_blocked ? 'release' : 'hold', holdReleaseRow)"
          >
            Submit
          </b-btn>
        </div>
      </b-form>
    </b-modal>

    <notifications group="msg" position="bottom right"/>
  </div>
</template>

<script>
import moment from 'moment'
import { client } from '@/client/client'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {
  props: ['data', 'isLoading'],
  data() {
    return {
      holdReleaseNote: '',
      holdReleaseRow: null,
      columns: [
        {
          label: 'SKU',
          field: 'sku_without_prefix',
          width: '20%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap'
        },
        {
          label: 'DESCRIPTION',
          field: 'description',
          width: '40%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap'
        }
        ,
        {
          label: 'QUANTITY',
          field: 'quantity',
          width: '10%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap'
        },
        {
          label: 'BACKORDER REASON',
          field: 'item_zsperrsta_info',
          width: '30%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap'
        }
      ],
      tableSelectOptions: {
        enabled: false,
        selectOnCheckboxOnly: false,
        selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
      },
    }
  },
  computed: {
    moment() {
      return moment
    }
  },
  methods: {
    showHoldModal(data) {
      this.holdReleaseRow = data
      this.$refs.holdModal.show()
    },
    closeHoldModal() {
      this.holdReleaseRow = null
      this.holdReleaseNote = ''
      this.$refs.holdModal.hide()
    },
    submitHoldRelease(action, data) {
      console.log(data, 'TEST FUNCTION DATA')
      this.$bvModal.msgBoxConfirm('Are you sure?')
        .then(async () => {
          const obj = {
            'orderNr': data.order_id,
            'notes': this.holdReleaseNote,
            'cpy': this.$store.getters.currentCpy,
            'state': action === 'hold' ? '1' : '2'
          }
          try {
            const response = await client.orders_sage().holdRelease(obj)

            if (response.data.status === '1') {
              this.$notify({
                group: 'msg',
                type: 'success',
                text: 'Order holded/released successfully!',
                duration: 5000
              })
              this.holdReleaseNote = ''
              this.closeHoldModal()
            } else {
              this.$notify({
                group: 'msg',
                type: 'error',
                text: 'Failed to hold/release order',
                duration: 5000
              })
              this.holdReleaseNote = ''
              this.closeHoldModal()
            }
          } catch (error) {
            console.error('Error:', error)
            this.$notify({
              group: 'msg',
              type: 'error',
              text: 'Failed to hold/release order',
              duration: 5000
            })
          }
        })
    }
  },
}
</script>


