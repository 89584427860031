import axios from "axios";
import {dashboardClientConfig} from "../config";
import { requestManager } from '@/assets/requestManager.js';

let apiRoot = dashboardClientConfig.apiRoot;
let ordersRootUrl = apiRoot + "/orders";

export function orders() {
    return {
        all: (obj) => axios.post(ordersRootUrl, obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        download_excel: (obj) => axios.post(ordersRootUrl + "/exportToExcel", obj, { responseType: 'blob'}, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        total_orders: (obj) => axios.post(ordersRootUrl + "/total", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        shipped_orders: (obj) => axios.post(ordersRootUrl + "/shipped", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        total_backorders: (obj) => axios.post(ordersRootUrl + "/back", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        total_returns: (obj) => axios.post(ordersRootUrl + "/returns", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        orders_chart: (obj) => axios.post(ordersRootUrl + "/chart", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        comment_get: (obj) => axios.post(ordersRootUrl+ "/comment/get", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        comment_set: (obj) => axios.post(ordersRootUrl+ "/comment/set", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        holdRelease: (obj) => axios.post(ordersRootUrl+ "/comment/set", obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel });
            })
        }),
    }
}
