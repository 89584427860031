import Vue from 'vue';
import VueSelect from "vue-select";
import VueDatepicker from "vuejs-datepicker";
import VueMaskedInput from "vue-maskedinput";
import VueInputTag from "vue-input-tag";
import VueSlider from "vue-slider-component";
import VueCustomScrollbar from "vue-custom-scrollbar";
import VueApexCharts from "vue-apexcharts";
import VueCountdown from "@chenfengyuan/vue-countdown";

const ComponentRegistry = {
    components: {},
    registerComponent: function(componentName, component) {
        this.components[componentName] = component;
        Vue.component(componentName, component);
    },
    registerComponents: function(components) {
        for (let c in components) {
            if (Object.prototype.hasOwnProperty.call(components, c)) {
                this.registerComponent(c, components[c]);
            }
        }
    },
    component: function(componentName) {
        if (Object.prototype.hasOwnProperty.call(this.components, componentName)) {
            return this.components[componentName];
        }
    }
};

const commons = {
    'v-select': VueSelect,
    'datepicker': VueDatepicker,
    'masked-input': VueMaskedInput,
    'input-tag': VueInputTag,
    'vue-slider': VueSlider,
    'vue-custom-scrollbar': VueCustomScrollbar,
    'apexchart': VueApexCharts,
    'countdown': VueCountdown
};

ComponentRegistry.registerComponents(commons);

export default ComponentRegistry;
