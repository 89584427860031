import moment from 'moment'

import _ from 'lodash'

const LONG_DATE_FORMAT = 'MM/DD/YYYY HH:mm:ss';
const SHORT_DATE_FORMAT = 'DD.MM.YYYY';
const REQUEST_DATE_FORMAT = 'YYYY-MM-DD';

function timeFromNow(value) {
    if (value) {
        return moment(String(value)).fromNow()
    }
}

function timeFromNowOrDateTime(value) {
    if (value) {
        let date = moment(String(value));
        let now = moment();
        if (now.diff(date, 'days') <= 3) {
            return date.fromNow()
        } else {
            return date.format(LONG_DATE_FORMAT);
        }
    }
}

function formatDate(value) {
    if (value) {
        return moment(String(value)).format(LONG_DATE_FORMAT)
    }
}

function formatDateShort(value) {
    if (value) {
        return moment(String(value)).format(SHORT_DATE_FORMAT)
    }
}

function formatDateRequest(value) {
    if (value) {
        return moment(String(value)).format(REQUEST_DATE_FORMAT)
    }
}

function formatSentenceCase(str) {
    if (str) {
        return str.split(' ')
            .map(s => {
                s = s.toLowerCase();
                return s.charAt(0).toUpperCase() + s.slice(1);
            })
            .join(' ');
    }
}

function formatStartCase(str) {
    return _.startCase(str);
}

function replaceUnderscore(val) {
    if (val) {
        return val.replace("_", " ");
    }
}

function formatEventName(name) {
    if (name) {
        return _.startCase(name.replace("Event", ""));
    }
}

export default {
    filters: {
        timeFromNow,
        timeFromNowOrDateTime,
        formatDate,
        formatDateShort,
        formatSentenceCase,
        formatStartCase,
        formatEventName,
        replaceUnderscore
    },
    methods: {
        timeFromNow,
        formatDate,
        formatDateShort,
        formatSentenceCase,
        formatStartCase,
        formatEventName,
        formatDateRequest,

        formatNumber(num, isSi, unit, precision) {
            let base = isSi ? 1000 : 1024;
            if (Math.abs(num) < base) {
                return num + ' ' + unit;
            }
            let prefixes = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
            let u = -1;
            do {
                num /= base;
                ++u;
            } while (Math.abs(num) >= base && u < prefixes.length - 1);
            return num.toFixed(precision) + ' ' + prefixes[u] + (isSi ? '' : 'i') + unit;
        }
    },
}
