import axios from "axios";
import {dashboardClientConfig} from "../config";

let apiRoot = dashboardClientConfig.apiRoot;

export function auth() {
    const rootUrl = apiRoot + "/auth"
    return {
        login: (username, password) => axios.post(rootUrl + "/login", {username, password}),
        me: () => axios.get(rootUrl + "/me"),
    }
}
