<template>
  <page-content>
    <h1 class="page-header">
      {{ $t('orders.ordersOrderOverview') }}
    </h1>
    <panel
      id="orders-panel"
      :backButtonVisible="false"
      :exportButtonVisible="true"
      :reloadButtonVisible="true"
      :title="$t('orders.ordersTitle')"
      @exportContent="exportXls"
      @reload="load"
    >
      <orders
        :data="orders"
        :isLoading="isLoading"
        :selectedDateFilter="selectedDateFilter"
        @filter="filter"
        @reload="load"
        @sort="sort"
        @filter-button="filterButton"
        @date-filter="filterDate"
        @extend-filter="extendFilter"
      />
    </panel>
  </page-content>
</template>

<script>
import { client } from '@/client/client'
import PageContent from '../../../layout/PageContent'
import Orders from '../components/Orders'
import { debounce } from 'lodash'
import { bus } from '@/main'

export default {
  components: {PageContent, Orders},
  data() {
    return {
      activeRequests: 0,
      selectedDateFilter: '',
      orders: {},
      isLoading: false,
      request: {
        per_page: 20,
        page: 1,
        date_from: null,
        date_to: null,
        order_column: 'order_created_at',
        order_dir: 'DESC',
        filters: {
          cpy: this.$store.getters.currentCpy
        },
        predefined: ''
      },
    }
  },
  created() {
    if (this.$route.query && this.$route.query.filter) {
      this.filterButton(this.$route.query.filter)
    }
  },
  mounted() {
    this.request.filters.cpy = this.$store.getters.currentCpy
    bus.$on('cpyChanged', (data) => {
      this.request.cpy = data
      this.load()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
    const modal = this.$refs.modalDetail
    if (modal) {
      modal.$off('ok')
    }
  },
  watch: {
    activeRequests(newValue) {
      this.isLoading = newValue > 0
    }
  },
  methods: {
    async exportXls() {
      this.activeRequests++
      try {
        const response = await client.orders().download_excel({...this.request, per_page: 5000})

        const url = URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url

        const contentDisposition = response.headers['content-disposition']
        link.download = contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '')

        link.click()
        URL.revokeObjectURL(url)

      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    async getOrders() {
      this.activeRequests++
      this.request.cpy = this.$store.getters.currentCpy
      this.request.filters.cpy = this.$store.getters.currentCpy
      try {
        const response = await client.orders().all(this.request)
        this.orders = response.data
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    load(event) {
      if (event && event.currentPerPage && event.currentPage) {
        this.request.per_page = event.currentPerPage
        this.request.page = event.currentPage
      } else {
        this.request.per_page = 20
        this.request.page = 1
      }
      this.getOrders()
    },
    filter: debounce(function (event) {
      if (event && event.columnFilters) {
        this.request.filters = {...event.columnFilters}
      }
      this.getOrders()
    }, 500),
    sort(event) {
      if (event[0].type === 'none') {
        this.request.order_column = event[0].field
        this.request.order_dir = 'desc'
      } else {
        this.request.order_column = event[0].field
        this.request.order_dir = event[0].type
      }
      this.getOrders()
    },
    filterButton(type) {
      if (type) {
        this.request.predefined = type
        this.load()
      } else {
        this.request.predefined = ''
        this.load()
      }
    },
    filterDate(e) {
      this.request.date_from = e.date_from
      this.request.date_to = e.date_to
      this.getOrders()
    },
    extendFilter(e) {
      this.selectedDateFilter = e.selectedDateFilter
      this.applyDateFilter()
      this.getOrders()
    },
    applyDateFilter() {
      switch (this.selectedDateFilter) {
        case 'today':
          this.request.date_from = new Date()
          this.request.date_to = new Date()
          break
        case 'yesterday':
          var yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          this.request.date_from = yesterday
          this.request.date_to = yesterday
          break
        case 'last_7':
          var last_7 = new Date()
          last_7.setDate(last_7.getDate() - 7)
          this.request.date_from = last_7
          this.request.date_to = new Date()
          break
        case 'last_30':
          var last_30 = new Date()
          last_30.setDate(last_30.getDate() - 30)
          this.request.date_from = last_30
          this.request.date_to = new Date()
          break
        case 'last_6m':
          var last_6m = new Date()
          last_6m.setMonth(last_6m.getMonth() - 6)
          this.request.date_from = last_6m
          this.request.date_to = new Date()
          break
        case 'last_year':
          var last_year = new Date()
          last_year.setFullYear(last_year.getFullYear() - 1)
          this.request.date_from = last_year
          this.request.date_to = new Date()
          break
        case 'custom':
          this.request.date_from = this.dateFrom.format(this.REQUEST_DATE_FORMAT)
          this.request.date_to = this.dateTo.format(this.REQUEST_DATE_FORMAT)
          break
      }
    }
  }
}
</script>
