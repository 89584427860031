import ComponentRegistry from "../components/api";

export class Module {

    constructor(name) {
        this._name = name;
        this._components = {};
    }

    registerComponents(components) {
        if (components) {
            for (let c in components) {
                if (Object.prototype.hasOwnProperty.call(components, c)) {
                    this._components[c] = components[c];
                    ComponentRegistry.registerComponent(c, components[c]);
                }
            }
        }
        return this;
    }

    components() {
        return this._components;
    }

    name() {
        return this._name;
    }

}

export const ModuleRegistry = {

    _modules: {},
    _modulesAsArray: [],

    register: function(module) {
        if (module) {
            if (Array.isArray(module)) {
                for (let m in module) {
                    if (Object.prototype.hasOwnProperty.call(module, m)) {
                        _registerModule(this, module[m]);
                    }
                }
            } else {
                _registerModule(this, module)
            }
        }
        return module;
    },

    modules: function() {
        return this._modulesAsArray;
    }

};

function _registerModule(registry, module) {
    registry._modules[module.name()] = module;
    registry._modulesAsArray.push(module);
}


