<template>
  <page-content>
    <h1 class="page-header">{{ $t('profileCapital') }}</h1>
    <div v-if="loading" class="vgt-loading vgt-center-align position-static">
      <slot name="loadingContent">
        <span class="vgt-loading__content">Loading...</span>
      </slot>
    </div>
    <div v-else>
      <div v-if="$store.getters.user" class="row">
        <div class="col">
          <change-password-form/>
        </div>
        <div class="col">
          <change-date-filter-form :defaultFilter="defaultDateFilter"/>
          <change-mandant-form :defaultMandant="defaultMandant"/>
        </div>
        <div class="col">
          <div class="row">
            <change-language-form :language="language"/>
          </div>
          <div class="row mt-2">
            <change-view-form :preferredView="preferredView"/>
          </div>
        </div>
      </div>
    </div>
  </page-content>
</template>
<script>
import PageContent from '@/layout/PageContent.vue'
import ChangePasswordForm from '@/components/forms/ChangePasswordForm.vue'
import ChangeDateFilterForm from '@/components/forms/ChangeDateFilterForm.vue'
import ChangeMandantForm from '@/components/forms/ChangeMandantForm.vue'
import ChangeLanguageForm from '@/components/forms/ChangeLanguageForm.vue'
import ChangeViewForm from '@/components/forms/ChangeViewForm.vue'
import { client } from '@/client/client'

export default {
  name: 'ProfilePage',
  components: {
    ChangeViewForm,
    ChangeLanguageForm,
    ChangeMandantForm,
    ChangeDateFilterForm,
    ChangePasswordForm,
    PageContent
  },
  data() {
    return {
      defaultMandant: null,
      preferredView: '',
      defaultDateFilter: '',
      language: '',
      loading: false,
    }
  },
  async mounted() {
    await this.getDefaultData()
  },
  methods: {
    async getDefaultData() {
      try {
        this.loading = true

        const response = await client.auth().me()

        this.defaultMandant = response.data.defaultMandant.cpy
        this.preferredView = response.data.preferredView
        this.defaultDateFilter = response.data.defaultDateFilter
        this.language = response.data.language.toLowerCase()

      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
