var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{ref:"serviceCarrierInfo",attrs:{"columns":_vm.columns,"rows":_vm.data,"isLoading":_vm.isLoading,"responsive":true,"mode":'local',"lineNumbers":false,"search-options":{ enabled: false, placeholder: 'Search' },"sort-options":{
          enabled: false,
          initialSortBy: {field: 'shipper', type: 'ASC'}
        },"selectOptions":{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }