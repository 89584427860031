<template>
  <b-card :title="$t('changeDefaultView')">
    <b-form @submit.prevent="submitPreferredView">
      <b-form-group>
        <b-form-select
          v-model="selectedPreferredView"
          :options="views"
          class="form-select mt-2"/>
      </b-form-group>
      <b-button
        :disabled="loading"
        class="mt-2"
        type="submit"
        variant="primary"
      >
        <b-spinner v-if="loading" small/>
        {{ loading ? $t('loading') : $t('changeDefaultView') }}
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import appRoutes from '@/config/AppRoutes.vue'
import { client } from '@/client/client'

export default {
  name: 'ChangeViewForm',
  props: ['preferredView'],
  data() {
    const views = appRoutes.filter(route => route.title)
      .map(route => {
        return {
          value: route.path,
          text: route.title
        }
      })
    return {
      loading: false,
      views,
      selectedPreferredView: '',
    }
  },
  mounted() {
    this.selectedPreferredView = this.$props.preferredView
  },
  watch: {
    preferredView(newValue) {
      this.selectedPreferredView = newValue
    }
  },
  methods: {
    async submitPreferredView() {
      this.loading = true
      try {
        await client.profile().change_preferred_view({
          preferredView: this.selectedPreferredView,
          userId: this.$store.getters.user.id
        })

        this.$swal.fire({
          icon: 'success',
          title: 'Default view changed',
          text: 'Your default view has been changed successfully',
          showConfirmButton: false,
          timer: 1500
        })
      } catch (error) {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message,
          showConfirmButton: false,
          timer: 1500
        })
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
