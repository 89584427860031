<template>
  <b-card :title="$t('changeLanguage')">
    <b-form @submit.prevent="submitChangeLanguage">
      <b-form-group>
        <b-form-select
          v-model="selectedLanguage"
          :options="languages"
          class="form-select mt-2"/>
      </b-form-group>
      <b-button
        :disabled="loading"
        class="mt-2"
        type="submit"
        variant="primary"
      >
        <b-spinner v-if="loading" small/>
        {{ loading ? 'Loading...' : $t('changeLanguage') }}
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import { client } from '@/client/client'

export default {
  name: 'ChangeLanguageForm',
  props: ['language'],
  data() {
    return {
      loading: false,
      languages: {
        en: 'English',
        de: 'Deutsch'
      },
      selectedLanguage: '',
    }
  },
  mounted() {
    this.selectedLanguage = this.$props.language
  },
  watch: {
    language(newValue) {
      this.selectedLanguage = newValue
    }
  },
  methods: {
    async submitChangeLanguage() {
      this.loading = true
      try {
        await client.profile().change_language({
          language: this.selectedLanguage.toUpperCase(),
          userId: this.$store.getters.user.id
        })

        this.$swal.fire({
          icon: 'success',
          title: 'Language changed',
          text: 'Your default language has been changed successfully',
          showConfirmButton: false,
          timer: 1500
        })

        localStorage.setItem('last-locale', this.selectedLanguage)

        setTimeout(() => {
          window.location.reload()
        }, 1500)
      } catch (error) {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message,
          showConfirmButton: false,
          timer: 1500
        })
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
