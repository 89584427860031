<template>
  <div>
    <vue-good-table
      ref="bestsellers"
      :columns="columns"
      :isLoading="isLoading"
      :lineNumbers="false"
      :mode="'remote'"
      :paginationOptions="tablePaginationOptions"
      :responsive="true"
      :rows="data.data"
      :search-options="{ enabled: false, placeholder: 'Search' }"
      :selectOptions="tableSelectOptions"
      :sort-options="tableSortOptions"
      :total-rows="data.total"
      @on-page-change="load"
      @on-per-page-change="load"
      @on-column-filter="filterChanged"
      @on-sort-change="onSortChange"
    >
      <template slot="table-row" slot-scope="props" class="table-danger">
           <span v-if="props.column.field === 'date'">
            {{ props.row.date === '' ? '-' : formatDateShort(props.row.date) }}
          </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>

export default {
  name: 'Bestsellers',
  props: ['data', 'isLoading'],
  data() {
    return {
      columns: [
        {
          label: 'SKU',
          field: 'sku',
          width: '5%',
          thClass: 'text-center text-nowrap',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SKU'
          }
        }, {
          label: 'Facility Name',
          field: 'fcyname',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'Facility Name'
          }
        }, {
          label: 'DESCRIPTION',
          field: 'description',
          width: '30%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'DESCRIPTION'
          }
        }, {
          label: 'ABC CLASS',
          field: 'abc_class',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ABC CLASS'
          }
        }, {
          label: 'EAN',
          field: 'ean',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'EAN'
          }
        }, {
          label: 'ORDERED QUANTITY',
          field: 'qty_ordered',
          width: '5%',
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ORDERED QUANTITY'
          }
        }, {
          label: 'SHIPPED QUANTITY',
          field: 'qty_shipped',
          width: '5%',
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SHIPPED QUANTITY'
          }
        }
        ,
        {
          label: 'SCAN DATE',
          field: 'date',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SCAN DATE'
          }
        }
      ],
      tablePaginationOptions: {
        enabled: true,
        position: 'bottom',
        perPage: 20,
        perPageDropdown: null,
        perPageDropdownEnabled: true,
        dropdownAllowAll: false,
        mode: 'records',
        infoFn: null,
        jumpFirstOrLast: true
      },
      tableSelectOptions: {
        enabled: false,
        selectOnCheckboxOnly: false,
        selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
      },
      tableSortOptions: {
        enabled: true,
        initialSortBy: {field: 'sku', type: 'desc'}
      }
    }
  },
  methods: {
    load(event) {
      this.$emit('reload', event)
    },
    onSortChange(event) {
      this.$emit('sort', event)
    },
    filterChanged(event) {
      this.$emit('filter', event)
    },
    reset() {
      this.$refs.bestsellers.reset()
    }
  },
}
</script>

