<template>
  <div>
    <div v-if="$slots['top-menu']" class="specific-page-top-menu">
      <slot name="top-menu"></slot>
    </div>
    <div :style="computedStyles" class="page-content-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageContent',
  data() {
    return {
      height: window.innerHeight
    }
  },
  mounted() {
    this.handleWidgetDisplay()
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    computedStyles() {
      const styles = {
        height: `${this.height}px`,
        'max-height': `${this.height}px`,
      }
      if (this.$route.path === '/dashboard') {
        styles.margin = '-20px -30px'
      }
      return styles
    },
  },
  methods: {
    onResize() {
      this.height = window.innerHeight - (this.$slots['top-menu'] ? 120 : 80)
    },
    handleWidgetDisplay() {
      if (this.$route.path !== '/widget/return-widget') {
        const widget = document.querySelector('.widget-container')
        if (widget) {
          widget.style.display = 'none'
        }
      }
    },
  }
}
</script>

<style scoped>
.specific-page-top-menu {
  margin: -20px -15px 20px;
}

.page-content-wrapper {
  margin: -30px;
  padding: 20px;
  overflow-y: auto;
}
</style>
