<template>
  <b-card :title="$t('changeDefaultMandant')" class="mt-2">
    <b-form @submit.prevent="submitDefaultMandant">
      <b-form-group>
        <b-form-select
          v-model="selectedDefaultMandant"
          class="form-select mt-2"
        >
          <option
            v-for="(item) in mandantList"
            :key="item.cpy"
            :value="item.cpy"
          >
            {{ item.label }}
          </option>
        </b-form-select>
      </b-form-group>
      <b-button
        :disabled="loading"
        class="mt-2"
        type="submit"
        variant="primary"
      >
        <b-spinner v-if="loading" small/>
        {{ loading ? $t('loading') : $t('changeDefaultCpy') }}
      </b-button>
    </b-form>
  </b-card>
</template>


<script>
import { client } from '@/client/client'
import CryptoJS from 'crypto-js'

export default {
  name: 'ChangeMandantForm',
  props: ['defaultMandant'],
  data() {
    return {
      loading: false,
      selectedDefaultMandant: '',
    }
  },
  mounted() {
    this.selectedDefaultMandant = this.$props.defaultMandant
  },
  watch: {
    defaultMandant(newValue) {
      this.selectedDefaultMandant = newValue
    }
  },
  computed: {
    mandantList() {
      const user = this.$store.getters.user
      if (!user) return []

      return this.sortMandants(user.cpys.slice()).map(item => ({
        ...item,
        label: `${item.name} [${item.cpy}]`
      }))
    }
  },
  methods: {
    setEncryptedLocalStorage(key, value, secret) {
      const encryptedValue = this.encryptValue(value, secret)
      localStorage.setItem(key, encryptedValue)
    },
    encryptValue(value, secret) {
      return CryptoJS.AES.encrypt(value, secret).toString()
    },
    sortMandants(mandants) {
      return mandants.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    },
    async submitDefaultMandant() {
      this.loading = true
      try {
        await client.profile().change_default_mandant({
          defaultMandantId: this.selectedDefaultMandant,
          userId: this.$store.getters.user.id
        })

        this.$swal.fire({
          icon: 'success',
          title: 'Default mandant changed',
          text: 'Your default CPY has been changed successfully',
          showConfirmButton: false,
          timer: 1500
        })

        this.$store.commit('currentCpy', this.selectedDefaultMandant)
        this.setEncryptedLocalStorage('storedSelectedCpy', this.selectedDefaultMandant, 'secret')
        this.$router.go()
      } catch (err) {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: err.message,
          showConfirmButton: false,
          timer: 1500
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
