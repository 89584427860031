<template>
  <div>
    <vue-good-table
      ref="orders"
      :columns="columns"
      :isLoading="isLoading"
      :lineNumbers="false"
      :mode="'remote'"
      :paginationOptions="{
          enabled: true,
          position: 'bottom',
          perPage: 20,
          perPageDropdown: null,
          perPageDropdownEnabled: true,
          dropdownAllowAll: false,
          mode: 'records', // or pages
          infoFn: null,
          jumpFirstOrLast : true
        }"
      :responsive="true"
      :rows="data.info"
      :search-options="{ enabled: false, placeholder: 'Search' }"
      :selectOptions="{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				}"
      :sort-options="{
          enabled: true,
          initialSortBy: {field: 'accounting_date', type: 'desc'}
        }"
      :total-rows="data.total"
      @on-page-change="load"
      @on-per-page-change="load"
      @on-column-filter="filterChanged"
      @on-sort-change="onSortChange">
      <template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'tracking_id'">
              <!--a :href="props.row.tracking_url" target="_blank" class="fa fa-external-link-square ">{{props.formattedRow[props.column.field] }}</a-->
              <a :href="props.row.tracking_url" target="_blank">{{ props.formattedRow[props.column.field] }}</a>
          </span>
        <!--span v-else-if="props.column.field === 'ship_date'">
          {{props.row.ship_date === '' ? '-' : formatDateShort(props.row.ship_date)}}
        </span-->

        <span v-if="props.column.field === 'details'">
            <a class="fa fa-magnifying-glass" href="#" type="button" @click="doDetial(props.row)"></a>
          </span>
        <span v-if="props.column.field !== 'tracking_id'">
						{{ props.formattedRow[props.column.field] }}
					</span>
        <span v-if="props.column.field === 'note'">
            <a :style="{ color: props.row.comment_on_order !== '' ? '#8B0000' : '' }" class="fa fa-book" href="#"
               type="button" @click="doNote(props.row)"></a>
          </span>
      </template>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'download'">
          <a
            class="fa fa-download"
            type="button"
            @click="getInvoicePdf(props.row)"
          ></a>
        </span>
        <span v-else> {{ props.formattedRow[props.column.field] }} </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { bus } from '@/main'
import { client } from '@/client/client'

export default {
  name: 'invoice-fulfillment',
  components: {},
  props: ['data', 'isLoading'],

  created() {
    bus.$on('onSelectedRowChange', (data) => {
      this.selectedRow.comment_on_order = data


    })
  },
  methods: {

    async getInvoicePdf(row) {
      try {
        const params = `?cpy=${row.cpy}&invoiceid=${row.invoice_nr}`
        const response = await client.orders_sage().download_pdf(params)
        const pdfData = response.data

        const blob = new Blob([pdfData], {type: 'application/pdf'})

        const url = URL.createObjectURL(blob)
        window.open(url, '_blank')

      } catch (error) {
        console.error('Error:', error)
      }
    },

    load: function (event) {
      this.$emit('reload', event)

    },
    filterChanged: function (event) {
      this.$emit('filter', event)
    },
    onSortChange: function (event) {
      this.$emit('sort', event)
    },
    filterButton: function (type) {
      this.filter = type
      this.$emit('filter-button', type)
      if (type === 'reset') {
        this.$refs.orders.reset()
        this.dateFrom = null
        this.dateTo = null
      }
    },
    getFilterClass: function (type) {
      return this.filter === type ? 'selected-filter-button' : ''
    },
    applyDateFilter: function () {
      this.$emit('date-filter', {
        date_from: this.dateFrom.format(this.REQUEST_DATE_FORMAT),
        date_to: this.dateTo.format(this.REQUEST_DATE_FORMAT)
      })
    },
    onStartChange(e) {
      this.dateFrom = e.date
    },
    onEndChange(e) {
      this.dateTo = e.date
    },
    getStofc(data, searchCpy) {
      const stofcyArr = []
      const cpys = data.cpys
      for (let i = 0; i < cpys.length; i++) {
        if (searchCpy === cpys[i].cpy) {
          var stofcy = cpys[i].stofcy
          for (let x = 0; x < stofcy.length; x++) {
            stofcyArr.push(stofcy[x].fcy)
          }
        }
      }
      return stofcyArr
    },
  },
  mounted() {

    bus.$on('currentStofcy', (data) => {

      console.log(data)
      //this.stofcy = data;
      // console.log(JSON.stringify(this.$store.getters.currentStofcy));
      // var stofcs = this.getStofc(this.$store.getters.user, this.$store.getters.user.cpy);
      // this.$store.commit('currentStofcy', stofcs);
      //  console.log(JSON.stringify(this.$store.getters.currentStofcy));
      //stofcs = this.getStofc(this.$store.getters.user, cpy);
      // this.$store.mutations.currentStofcy = this.getStofc(this.$store.getters.user,this.$store.getters.user.cpy)
      //console.log(JSON.stringify(this.getStofc(this.$store.getters.user, cpy)));
    })
  },

  data() {


    return {
      selectedRow: {},
      modalCommentVisible: false,
      modalDetailVisible: false,
      orderId: null,
      currentCpy: null,
      deliveryId: null,


      REQUEST_DATE_FORMAT: 'YYYY-MM-DD',
      filter: '',
      dateFrom: null,
      dateTo: null,

      configs: {
        start: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false
        },
        end: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false
        }
      },
      columns: [
        {
          label: 'INVOICE NR',
          field: 'invoice_nr',
          width: '15%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'INVOICE NR'
          }
        }, {
          label: 'ACCOUNTING DATE',
          field: 'accounting_date',

          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ACCOUNTING DATE'
          }
        }, {
          label: 'CUSTOMER NAME',
          field: 'customer_name',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'CUSTOMER NAME'
          }
        }, {
          label: 'GROSS AMOUNT',
          field: 'inv_amount_brut',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            // filterDropdownItems: this.stofcy,
            placeholder: 'GROSS AMOUNT'
          }
        }, {
          label: 'NET AMOUNT',
          field: 'inv_amount_net',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            // filterDropdownItems: this.stofcy,
            placeholder: 'NET AMOUNT'
          }
        },
        {
          label: 'CURRENCY',
          field: 'currency',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'CURRENCY'
          }
        },
        {
          label: '',
          field: 'download',
          width: '5%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: ''
          }
        },
      ]
    }
  },
}
</script>

<style scoped>
.filter-button-group {
  margin-top: -5px;
  padding-bottom: 10px;
}

.btn-no-label-filter {
  background-color: rgb(199, 240, 210);
  color: rgb(0, 0, 0);
}

.btn-not-printed-filter {
  background-color: rgb(228, 240, 195);
  color: rgb(0, 0, 0);
}

.btn-not-shipped-yet-filter {
  background-color: rgb(245, 223, 189);
  color: rgb(0, 0, 0);
}

.btn-no-progress-in-tracking-filter {
  background-color: rgba(243, 217, 73, 0.4);
  color: rgb(0, 0, 0);
}

.btn-backorders-filter {
  background-color: rgba(255, 130, 73, 0.4);
  color: rgb(0, 0, 0);
}

.btn-clearstation-filter {
  background-color: rgba(255, 130, 73, 0.4);
  color: rgb(0, 0, 0);
}

.selected-filter-button {
  opacity: 1;
  border: 3px solid !important;
  font-weight: 1000;
}
</style>
