var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('vue-good-table',{ref:"serviceCarrierInfo",attrs:{"columns":_vm.columns,"globalSearch":true,"isLoading":_vm.isLoading,"lineNumbers":false,"mode":'local',"responsive":true,"rows":_vm.data,"search-options":{ enabled: false, placeholder: 'Search' },"selectOptions":{
        enabled: false,
        selectOnCheckboxOnly: false,
        selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
      },"sort-options":{
        enabled: false,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'details')?_c('span',[_c('a',{staticClass:"fa fa-magnifying-glass",attrs:{"type":"button"},on:{"click":function($event){return _vm.doDetail(props.row)}}})]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('div')])
}
var staticRenderFns = []

export { render, staticRenderFns }