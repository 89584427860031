var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{ref:"orders",attrs:{"columns":_vm.columns,"isLoading":_vm.isLoading,"lineNumbers":false,"mode":'remote',"paginationOptions":{
          enabled: true,
          position: 'bottom',
          perPage: 20,
          perPageDropdown: null,
          perPageDropdownEnabled: true,
          dropdownAllowAll: false,
          mode: 'records', // or pages
          infoFn: null,
          jumpFirstOrLast : true
        },"responsive":true,"rows":_vm.data.info,"search-options":{ enabled: false, placeholder: 'Search' },"selectOptions":{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				},"sort-options":{
          enabled: true,
          initialSortBy: {field: 'accounting_date', type: 'desc'}
        },"total-rows":_vm.data.total},on:{"on-page-change":_vm.load,"on-per-page-change":_vm.load,"on-column-filter":_vm.filterChanged,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'download')?_c('span',[_c('a',{staticClass:"fa fa-download",attrs:{"type":"button"},on:{"click":function($event){return _vm.getInvoicePdf(props.row)}}})]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }