<template>
  <div v-if="!remove"
       :class="[{ 'panel-expand': expand, 'panel-loading': reload }, panelTheme, panelClass]"
       class="panel"
       style="border-radius: 1rem;"
  >
    <div :class="[headerClass]"
         class="panel-heading"
         style="color: black; background: none;"
    >
      <slot name="header"/>
      <h4 v-if="!hasHeaderSlot"
          class="panel-title"
          style="font-size: 1.0rem;"
      >
        {{ title }}
      </h4>
      <div>
        <b-badge pill style="color: darkgray;" variant="dark">
          {{ lastUpdateFormatted }}
        </b-badge>
      </div>
      <div v-if="!noButton" class="panel-heading-btn">
        <a v-if="expandButtonVisible"
           :title="$t('expand')"
           class="btn btn-xs btn-icon btn-circle btn-default"
           @click="panelExpand"
        >
          <i v-b-popover.hover.top="{variant: 'info', content: $t('expand')}" class="fa fa-expand fa-2x"/>
        </a>
        <a v-if="reloadButtonVisible"
           :title="$t('reload')"
           class="btn btn-xs btn-icon btn-circle btn-success"
           @click="panelReload"
        >
          <i v-b-popover.hover.top="{variant: 'info', content: $t('reload')}" class="fa fa-redo fa-2x"/>
        </a>
        <a v-if="colapseButtonVisible"
           :title="$t('collapse')"
           class="btn btn-xs btn-icon btn-circle btn-warning"
           @click="panelCollapse"
        >
          <i v-b-popover.hover.top="{variant: 'info', content: $t('collapse')}" class="fa fa-minus fa-2x"/>
        </a>
        <a v-if="exportButtonVisible"
           class="btn btn-xs btn-icon btn-circle btn-warning"
           @click="exportContent"
        >
          <i v-b-popover.hover.top="{ variant: 'info', content: $t('download') }" class="fa fa-download fa-2x"/>
        </a>
        <a v-if="backButtonVisible"
           :title="$t('back')"
           class="btn btn-xs btn-icon btn-circle btn-danger"
           @click="$router.back()"
        >
          <i v-b-popover.hover.top="{ variant: 'info', content: $t('back') }" :style="{color: 'red'}"
             class="fa fa-arrow-left fa-2x"/>
        </a>
      </div>
    </div>
    <slot name="beforeBody"/>
    <div v-if="!noBody"
         v-show="!collapse"
         :class="bodyClass"
         class="panel-body"
    >
      <slot/>
      <div v-if="reload" class="panel-loader">
        <span class="spinner spinner-sm"></span>
      </div>
    </div>
    <slot v-else/>
    <slot name="outsideBody"/>
    <div v-if="hasFooterSlot" :class="footerClass" class="panel-footer">
      <slot name="footer"/>
    </div>
    <slot name="afterFooter"/>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  props: ['title', 'body', 'footer', 'variant', 'noBody', 'noButton', 'outsideBody', 'headerClass', 'bodyClass', 'footerClass', 'panelClass', 'backButtonVisible', 'isLoading', 'exportButtonVisible', 'expandButtonVisible', 'colapseButtonVisible', 'reloadButtonVisible', 'lastUpdate'],
  data() {
    return {
      expand: false,
      collapse: false,
      remove: false,
      reload: false,
      back: false,
      panelTheme: (this.variant) ? 'panel-' + this.variant : 'panel-inverse'
      ,
      json_fields: {
        'order_id': 'order_id',
        'delivery_id': 'delivery_id'
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
            escapeCsv: true,
            stringifyLongNum: true,
            type: 'csv'
          },
        ],
      ]
    }
  },
  computed: {
    lastUpdateFormatted() {
      if (!this.lastUpdate || !this.lastUpdate.length) return ''

      const firstData = this.lastUpdate[0]
      if (!firstData) return ''

      const lastUpdate = new Date(firstData.lastUpdate)
      const currentDate = new Date()

      const formattedDate = lastUpdate.toDateString() === currentDate.toDateString()
        ? lastUpdate.toLocaleTimeString()
        : lastUpdate.toLocaleString()

      return `${this.$t('statisticsDataFrom')} ${formattedDate}`
    },
    hasFooterSlot() {
      return !!this.$slots.footer
    },
    hasHeaderSlot() {
      return !!this.$slots.header
    }
  },
  methods: {
    exportContent() {
      this.$emit('exportContent')
    },
    panelExpand() {
      this.expand = !this.expand
    },
    panelCollapse() {
      this.collapse = !this.collapse
    },
    panelReload() {
      this.reload = true
      this.$emit('reload', this.reload)

      setTimeout(function () {
        this.resetReload()
      }.bind(this), 1000)
    },
    resetReload() {
      this.reload = false
    }
  }
}
</script>

