<template>
  <page-content>
    <h1 class="page-header">{{ $t('supportChangeAddress') }}</h1>
    <b-alert
      :show="error !== null"
      variant="danger"
    >
      {{ error }}
    </b-alert>
    <b-card>
      <b-form @submit.prevent="fetchAddress(cpy, id)">
        <b-form-group
          id="search-id"
          :label="$t('id')"
          label-for="search-id-input"
        >
          <b-form-input
            id="search-id-input"
            v-model="id"
            required
          />
        </b-form-group>
        <b-button :disabled="loading" class="mt-3" type="submit">
          <b-spinner v-if="loading" small/>
          <span>{{ loading ? 'Loading...' : $t('searchOrder') }}</span>
        </b-button>
      </b-form>
    </b-card>
    <change-address-form v-if="address" :address="address" @updateAddress="onAddressUpdate"/>
    <notifications group="msg" position="bottom right"/>
  </page-content>
</template>

<script>
import PageContent from '../../../layout/PageContent.vue'
import { client } from '@/client/client'
import ChangeAddressForm from '@/modules/support/components/ChangeAddressForm.vue'
import { bus } from '@/main'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {
  name: 'SupportAddressChangePage',
  components: {ChangeAddressForm, PageContent},
  data() {
    return {
      id: null,
      cpy: null,
      address: null,
      loading: false,
      error: null
    }
  },
  mounted() {
    this.cpy = this.$store.getters.currentCpy
    bus.$on('cpyChanged', (data) => {
      this.cpy = data
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
  },
  methods: {
    showError(message) {
      this.error = message
      setTimeout(() => {
        this.error = null
      }, 5000)
    },
    async fetchAddress(cpy, id) {
      this.address = null
      try {
        this.error = null
        this.loading = true

        const response = await client.change_address().address_get({id, cpy})

        if (!response.data) {
          this.showError('Address not found!')
        }

        this.address = response.data
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.loading = false
      }
    },
    async onAddressUpdate() {
      this.$notify({
        group: 'msg',
        type: 'success',
        text: 'Your order address updated successfully!',
        duration: 5000
      })
      this.address = null
      this.id = null
    }
  }
}
</script>
