class RequestManager {
  constructor() {
    this.activeRequests = [];
  }

  addRequest(request) {
    this.activeRequests.push(request); 
  }

  cancelAllRequests() {
    this.activeRequests.forEach(request => { 
      request.cancel(); // Zrušení požadavku
    });
    this.activeRequests = []; // Vyprázdnění seznamu požadavků
  }
}

export const requestManager = new RequestManager();