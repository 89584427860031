var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"changeAddressModal",attrs:{"title":_vm.$t('changeAddress'),"centered":"","hide-footer":"","hide-header":"","size":"lg"},model:{value:(_vm.modalChangeAddressVisible),callback:function ($$v) {_vm.modalChangeAddressVisible=$$v},expression:"modalChangeAddressVisible"}},[(this.modalChangeAddress)?_c('ChangeAddressForm',{attrs:{"address":this.modalChangeAddress,"hide-tracking":true},on:{"update-address":_vm.hideChangeAddressModal}}):_vm._e()],1),_c('vue-good-table',{ref:"labelErrros",attrs:{"columns":_vm.columns,"isLoading":_vm.isLoading,"lineNumbers":false,"mode":'remote',"paginationOptions":{
          enabled: true,
          position: 'bottom',
          perPage: 20,
          perPageDropdown: null,
          perPageDropdownEnabled: true,
          dropdownAllowAll: false,
          mode: 'records', // or pages
          infoFn: null,
          jumpFirstOrLast : true
        },"responsive":true,"rows":_vm.data.data,"search-options":{ enabled: false, placeholder: 'Search' },"selectOptions":{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				},"sort-options":{
          enabled: true,
          initialSortBy: {field: 'delivery_created_at', type: 'desc'}
        },"total-rows":_vm.data.total},on:{"on-page-change":_vm.load,"on-per-page-change":_vm.load,"on-column-filter":_vm.filterChanged,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'note')?_c('span',[_c('a',{staticClass:"fa fa-book",style:({ color: props.row.comment_on_order !== '' ? '#8B0000' : '' }),attrs:{"href":"#","type":"button"},on:{"click":function($event){return _vm.doNote(props.row)}}})]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('b-modal',{ref:"modal_comment_on_order",attrs:{"id":"modal-detail-comment","title":_vm.$t('commentOnOrder'),"visible":_vm.modalCommentVisible,"centered":"","hide-header":"","no-close-on-backdrop":"","ok-title":"Save","size":"lg"},on:{"cancel":_vm.cancelComment,"ok":_vm.saveComment}},[_c('panel',{attrs:{"id":"comment","title":_vm.$t('commentOnOrder'),"resizeable":"true"}},[_c('OrderComment',{attrs:{"gridData":_vm.data.data,"selectedRow":_vm.selectedRow}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }