import axios from "axios";
import {dashboardClientConfig} from "../config";
import { requestManager } from '@/assets/requestManager.js';

let apiRoot = dashboardClientConfig.apiRoot;
let returnsRootUrl = apiRoot + "/returns";

export function returns() {
    return {
        all: (obj) => axios.post(returnsRootUrl, obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        refunds: (obj) => axios.post(returnsRootUrl + "/refunds", obj, {
          cancelToken: new axios.CancelToken(function executor(cancel) {
            requestManager.addRequest({ cancel }); 
          })
        }),  
        download_excel: (obj) => axios.post(returnsRootUrl + "/exportToExcel", obj, { responseType: 'blob'}, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          })
    }
}
