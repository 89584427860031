import axios from "axios";
import {dashboardClientConfig} from "../config";
import { requestManager } from '@/assets/requestManager.js';

let apiRoot = dashboardClientConfig.apiRoot;
let ordersRootUrl = apiRoot + "/invoice-fulfillments";

export function invoice_fulfillments() {
    return {
        all: (obj) => axios.post(ordersRootUrl, obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
          download_excel: (obj) => axios.post(ordersRootUrl + "/exportToExcel", obj, { responseType: 'blob'}, {
              cancelToken: new axios.CancelToken(function executor(cancel) {
                requestManager.addRequest({ cancel }); 
              })
            })
    }
}
