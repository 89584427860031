var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{ref:"labelErrros",attrs:{"columns":_vm.columns,"rows":_vm.data.data,"total-rows":_vm.data.total,"isLoading":_vm.isLoading,"responsive":true,"mode":'remote',"lineNumbers":false,"search-options":{ enabled: false, placeholder: 'Search' },"sort-options":{
          enabled: true,
          initialSortBy: {field: 'entrytime', type: 'desc'}
        },"selectOptions":{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				},"paginationOptions":{
          enabled: true,
          position: 'bottom',
          perPage: 20,
          perPageDropdown: null,
          perPageDropdownEnabled: true,
          dropdownAllowAll: false,
          mode: 'records', // or pages
          infoFn: null,
          jumpFirstOrLast : true
        }},on:{"on-page-change":_vm.load,"on-per-page-change":_vm.load,"on-column-filter":_vm.filterChanged,"on-sort-change":_vm.onSortChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }